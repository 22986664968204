/* eslint-disable no-unused-vars */
/* eslint-disable multiline-comment-style */
// Packages
import _ from 'lodash';
import axios from 'axios';
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { useReactToPrint } from 'react-to-print';
import FileSaver from 'file-saver';
// Components
import FormUI from 'components/UI/FormUI';
import ConditionalRender from 'components/ConditionalRender';
import { ReactComponent as ProfileSVG } from 'assets/svg/circle-user.svg';
// CSS
import './index.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectFinancialStatementDate,
  getFinancialStatementDate,
  selectFinancialStatementData,
} from 'reducers/accountingReducer';
import { PrintContent } from './PrintContent';
import { toast } from 'react-toastify';
import { getFinancialStatement } from 'reducers/thunks/accountingThunks';

// Main Component
const AddFinancialStatement = () => {
  const dispatch = useDispatch();
  const date = useSelector(selectFinancialStatementDate);
  const records = useSelector(selectFinancialStatementData);

  console.log('filtered accounting record: ', records.length);

  const handleDateChange = (e) => {
    const dateVal = e.target.value;
    dispatch(getFinancialStatementDate(dateVal));
    dispatch(getFinancialStatement({ search: dateVal }));
  }; 

  const filterList = (type) => {
    return records?.filter((data) => data?.asset_type === type);
  };

  const calculateTotalCurrent = (type) => {
    const currrents = filterList(type)?.filter((data) => data?.category !== 'Non - Current Assets');
    const total = _.sum(_.map(currrents, (data) => data?.amount));
    return total === 0 ? 0 : total;
  };

  const calculateTotal = (type) => {
    const total = _.sum(_.map(filterList(type), (data) => data?.amount));
    return total === 0 ? 0 : total;
  };

  const downloadExcelFile = async () => {
    if (!records.length) {
      toast.warn('Unable to export due to no record saved on this date.');
      return;
    }

    const API_URL = process.env.REACT_APP_API_BASE_URL;
    try {
      const response = await axios.get(
        `${API_URL}/financial_statement/downloadExcel?search=${date}`,
        {
          responseType: 'blob',
          method: 'GET',
        },
      );

      const fileData = await response?.data;
      FileSaver.saveAs(fileData, `financial_statement_${date}.xlsx`);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });
  return (
    <>
      <div style={{ display: 'none' }}>
        <PrintContent
          ref={printRef}
          date={date}
          filterList={filterList}
          calculateTotalCurrent={calculateTotalCurrent}
          calculateTotal={calculateTotal}
        />
      </div>
      <div className='add-financial-statement'>
        <FormUI>
          <Form>
            <Row>
              <Col>
                <div className='financial-details'>
                  <Row className='mb-4'>
                    <Col sm={3}>
                      <Form.Group className='inline-label'>
                        <Form.Label className='px-1'>Date</Form.Label>
                        <Form.Control
                          type='date'
                          placeholder='Enter Here'
                          onChange={handleDateChange}
                        />
                      </Form.Group>
                    </Col>

                    {/*   <Col sm={3}>
                    <Form.Group className='inline-label'>
                      <Form.Label>Comparison</Form.Label>
                      <Form.Select value=''>
                        <option value=''>- Select -</option>
                      </Form.Select>
                    </Form.Group>
                  </Col> */}

                    <Col className='form-buttons'>
                      <Button className='print' onClick={handlePrint} disabled={!date}>
                        Print
                      </Button>
                      <Button className='export' onClick={downloadExcelFile} disabled={!date}>
                        Export
                      </Button>
                    </Col>
                  </Row>

                  {date !== '' && (
                    <>
                      <h5 className='mt-2 mb-4'>Assests List:</h5>

                      <Row className='mb-4'>
                        <Col>
                          {filterList('Asset')?.map((record) => {
                            return (
                              <Row className='mb-2' key={record?.id}>
                                <Col className='asset-name'>{record?.details}</Col>
                                <Col className='asset-amount'>{record?.amount}</Col>
                                <Col
                                  className={
                                    record.category === 'asset'
                                      ? 'current-asset'
                                      : 'non-current-asset'
                                  }
                                >
                                  {record?.category}
                                </Col>
                              </Row>
                            );
                          })}
                        </Col>

                        <Col className='total-container'>
                          <p>
                            Total Current Assets : <span>Php {calculateTotalCurrent('Asset')}</span>
                          </p>
                          <p>
                            Total Assets : <span>Php {calculateTotal('Asset')}</span>
                          </p>
                        </Col>
                      </Row>

                      <br />

                      <h5 className='mt-4 mb-4'>Liabilities List:</h5>

                      <Row className='mb-4'>
                        <Col>
                          {filterList('Liability')?.map((record) => {
                            return (
                              <Row className='mb-2' key={record?.id}>
                                <Col className='asset-name'>{record?.details}</Col>
                                <Col className='asset-amount'>{record?.amount}</Col>
                                <Col
                                  className={
                                    record.category === 'asset'
                                      ? 'current-asset'
                                      : 'non-current-asset'
                                  }
                                >
                                  {record?.category}
                                </Col>
                              </Row>
                            );
                          })}
                        </Col>

                        <Col className='total-container'>
                          <p>
                            Total Current Liabilities :{' '}
                            <span>Php {calculateTotalCurrent('Liability')}</span>
                          </p>
                          <p>
                            Total Liabilities : <span>Php {calculateTotal('Liability')}</span>
                          </p>
                        </Col>
                      </Row>

                      <br />

                      <h5 className='mt-2 mb-4'>Equity List</h5>

                      <Row className='mb-4'>
                        <Col>
                          {filterList('Equity')?.map((record) => {
                            return (
                              <Row className='mb-2' key={record?.id}>
                                <Col className='asset-name'>{record?.details}</Col>
                                <Col className='asset-amount'>{record?.amount}</Col>
                                <Col
                                  className={
                                    record.category === 'asset'
                                      ? 'current-asset'
                                      : 'non-current-asset'
                                  }
                                >
                                  {record?.category}
                                </Col>
                              </Row>
                            );
                          })}
                        </Col>

                        <Col className='total-container'>
                          <p>
                            Total Current Equity :{' '}
                            <span>Php {calculateTotalCurrent('Equity')}</span>
                          </p>
                          <p>
                            Total Equity : <span>Php {calculateTotal('Equity')}</span>
                          </p>
                        </Col>
                      </Row>
                    </>
                  )}

                  {/* <div className='bold' />

                <Row className='mt-4 mb-2'>
                  <Col className='total-cont'>
                    <p>
                      Total Liability + Equity : <span>Php 1,000,000.00</span>
                    </p>
                  </Col>
                </Row> */}
                </div>
              </Col>
            </Row>
          </Form>
        </FormUI>
      </div>
    </>
  );
};

export default AddFinancialStatement;
