/* eslint-disable */
import React from 'react';
import { Table } from 'react-bootstrap';

import logo from 'assets/images/strh.png';
import './PrintContent.scss';

export class PrintContent extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const { tableData, tableCol } = this.props;

        return (
            <div id='print_chart_of_accounts' ref={(el) => (this.printRef = el)}>
                <div className='header'>
                    <img src={logo} alt="print_logo" />
                    <h1>MANDALUYONG CITY MEDICAL CENTER</h1>
                    <h3>Boni Avenue cor. Sto. Rosario Street, Plainview, 8532-2563</h3>
                </div>
                <Table striped bordered>
                    <thead>
                        <tr>
                            {tableCol.map((col) => (
                                <th key={col.accessor}>{col.Header}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {tableData?.length 
                            ? tableData?.map((data) => (
                                <tr key={data?.id}>
                                    <td>{data?.id}</td>
                                    <td>{data?.name}</td>
                                    <td>{data?.account_type}</td>
                                    <td>{data?.detail_type}</td>
                                    <td>{data?.debit}</td>
                                    <td>{data?.credit}</td>
                                </tr>
                            )) : (
                                <tr>
                                    <td>No Record Found</td>
                                </tr>
                            )
                        }
                    </tbody>
                </Table>
            </div>
        );
    }
}