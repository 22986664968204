/* eslint-disable camelcase */
/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable multiline-comment-style */
/* eslint-disable newline-per-chained-call */
/* eslint-disable react/no-unescaped-entities */

// import core & vendor packages below
import axios from 'axios';
import Select from 'react-select';
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { Row, Col, Accordion, Form, Button } from 'react-bootstrap';

// import components below
import PatientTable from './Table';
import FormUI from 'components/UI/FormUI';
import AccordionUI from 'components/UI/AccordionUI';
import { objectCleaner } from 'helpers/objectCleaner';

// import assets below
import './AdvancedSearch.scss';

const selectStyles = {
  control: (styles) => ({
    ...styles,
    padding: '0.45em',
    color: '#404040',
    borderColor: '#c8c8c8',
    borderRadius: '10px',
  }),
  menu: (styles) => ({
    ...styles,
    zIndex: '100',
  }),
};

// main component
const AdvancedSearch = ({ apiKey, setShowProfile, users }) => {
  const [state, setState] = useState([]);
  // const [users, setUsers] = useState([]);
  const [doctor, setDoctor] = useState({});
  const [status, setStatus] = useState('');
  const [endDate, setEndDate] = useState('');
  const [startDate, setStartDate] = useState('');
  const [department, setDepartment] = useState('');
  const [dateDropdown, setDateDropdown] = useState('');
  const [appointmentDate, setAppointmentDate] = useState([]);

  const handleFormatDate = (e) => {
    setDateDropdown(e);

    const todayDate = new Date().toISOString().slice(0, 10); // 2-22-07-15

    const today = new Date();
    const last30Days = new Date(new Date().setDate(today.getDate() - 30))
      .toISOString()
      .slice(0, 10);

    const lastWeek = new Date(new Date().setDate(today.getDate() - 7)).toISOString().slice(0, 10);

    const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
    const firstDayCurrentMonth = new Date(firstDay.getTime() - firstDay.getTimezoneOffset() * 60000)
      .toISOString()
      .split('T')[0];

    const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    const lastDayCurrentMonth = new Date(lastDay.getTime() - lastDay.getTimezoneOffset() * 60000)
      .toISOString()
      .split('T')[0];

    const first = today.getDate() - today.getDay(); // First day is the day of the month - the day of the week
    const last = first + 6; // last day is the first day  const

    const firstday = new Date(today.setDate(first)).toISOString().slice(0, 10);
    const lastday = new Date(today.setDate(last)).toISOString().slice(0, 10);

    const lastWeekFirstDay = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 13);
    const lastWeekLastDay = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);

    const lastWeekStartDate = new Date(
      lastWeekFirstDay.getTime() - lastWeekFirstDay.getTimezoneOffset() * 60000,
    )
      .toISOString()
      .split('T')[0];
    const lastWeekEndDate = new Date(
      lastWeekLastDay.getTime() - lastWeekLastDay.getTimezoneOffset() * 60000,
    )
      .toISOString()
      .split('T')[0];

    switch (e) {
      case 'today':
        setAppointmentDate(todayDate);
        break;
      case 'this-week':
        setEndDate(lastday);
        setAppointmentDate('');
        setStartDate(firstday);
        break;
      case 'this-month':
        setAppointmentDate('');
        setEndDate(lastDayCurrentMonth);
        setStartDate(firstDayCurrentMonth);
        break;
      case 'last-week':
        setAppointmentDate('');
        setEndDate(lastWeekEndDate);
        setStartDate(lastWeekStartDate);
        break;
      case 'last-30-days':
        setEndDate(todayDate);
        setAppointmentDate('');
        setStartDate(last30Days);
        break;
    }
  };

  const resetFilter = () => {
    setStatus('');
    setDoctor({});
    setEndDate('');
    setStartDate('');
    setDateDropdown('');
    setAppointmentDate('');

    search(true);
  };

  const search = (reset = false) => {
    const unfilteredParams = {
      per_page: 100000,
      appointment_status: reset ? '' : status,
      name_of_doctor: reset ? '' : doctor?.value,
      appointment_date: reset ? '' : appointmentDate,
      end_date: reset ? '' : endDate,
      department: department === 'all' ? '' : department,
      start_date: reset ? '' : startDate,
    };

    const { parameters } = objectCleaner(unfilteredParams);

    axios
      .get('/appointment', {
        params: parameters,
      })
      .then((response) => {
        setState(response?.data?.data?.appointments);
      }, [])
      .catch((err) => {
        console.log('Error: ', err);
      });
  };

  const print = () => {
    toast.info('Printing Records.');

    axios
      .post(
        '/appointment/print',
        {
          // end_date: endDate,
          // start_date: startDate,
          department: department,
          // appointment_date: appointmentDate,
        },
        {
          responseType: 'blob',
        },
      )
      .then((response) => {
        const filename = response.headers['content-disposition']
          .split('filename=')[1]
          .split(';')[0];

        saveAs(response.data, filename);
        // console.log(response);
      }, [])
      .catch((err) => {
        console.log(err);
        toast.error('Error printing records.');
      });
  };

  // useEffect(() => {
  //   axios
  //     .get('/user')
  //     .then((response) => {
  //       const renderedData = response?.data?.data.map((data) => {
  //         return { value: data?.full_name, label: data?.full_name, data: data };
  //       });

  //       setUsers(renderedData);
  //     }, [])
  //     .catch((err) => {
  //       console.log('Error: ', err);
  //     });
  // }, []);

  useEffect(() => {
    setDepartment(apiKey);
  }, [apiKey]);

  useEffect(() => {
    if (department !== '') {
      search();
    }
  }, [department]);

  return (
    <>
      <div className='advancedSearch'>
        <AccordionUI>
          <Accordion defaultActiveKey='0'>
            <Accordion.Item eventKey='0'>
              <Accordion.Header>Advance Search</Accordion.Header>
              <Accordion.Body>
                <FormUI>
                  <Row>
                    <Col>
                      <Form.Group className='mb-4'>
                        <Form.Label>Show Patient's Form</Form.Label>
                        <Form.Select
                          value={dateDropdown}
                          onChange={(e) => handleFormatDate(e.target.value)}
                        >
                          <option disabled value=''>
                            -Select-
                          </option>
                          <option value='today'>Today</option>
                          <option value='this-week'>This Week</option>
                          <option value='this-month'>This Month</option>
                          <option value='last-week'>Last Week</option>
                          <option value='last-30-days'>Last 30 days</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>

                    <Col>
                      <Form.Group className='mb-4'>
                        <Form.Label>Appointment Date</Form.Label>
                        <Form.Control
                          type='date'
                          value={appointmentDate}
                          onChange={(event) => {
                            setAppointmentDate(event.target.value);
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className='mb-4' style={{ position: 'relative' }}>
                        <Form.Label>Doctor / Resource</Form.Label>
                        {users && users.length > 0 && (
                          <Select
                            value={doctor}
                            options={users}
                            styles={selectStyles}
                            placeholder='Enter here'
                            onChange={(e) => {
                              setDoctor(e);
                            }}
                          />
                        )}
                      </Form.Group>
                    </Col>
                    <Col>
                      <div className='buttonWrapper'>
                        <Button variant='search' onClick={() => search()}>
                          SEARCH
                        </Button>
                        <Button variant='clear' onClick={() => resetFilter()}>
                          CLEAR
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className='patient-header'>
                        <h4 className='mb-0 fw-bold'>Patient List</h4>
                      </div>
                    </Col>
                    <Col>
                      <div className='buttonWrapper'>
                        <Button variant='light-blue' onClick={() => print()}>
                          PRINT ALL RECORDS
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </FormUI>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </AccordionUI>
      </div>

      <PatientTable
        state={state}
        apiKey={apiKey}
        setState={setState}
        setShowProfile={setShowProfile}
      />
    </>
  );
};

export default AdvancedSearch;
