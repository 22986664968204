/* eslint-disable */
// import core & vendor packages below
import axios from 'axios';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ConfirmDeleteModal from './ConfirmationModal';

// import components below
import { roomStatus } from 'helpers';
import Table from 'components/ReactTable';
import { selectPatientItem, showPatient } from 'reducers/patientReducer';

// import assets below
import './Table.scss';

// main component
const PatientRoomTable = ({ state, setState, setShowProfile, showProfile }) => {

  const [openModal, setOpenModal] = useState(false);

  const confirmDelete = (item) => {
    setShowProfile((prevState) => {
      return {
        ...prevState,
        id: item?.id,
      };
    });
    setOpenModal(true);
  };

  const handleDelete = (id) => {
    axios
      .delete(`/room/${id}`)
      .then((response) => {
        toast.warning('Successfully deleted!');

        axios
          .get('/room')
          .then((response) => {
            setState(response.data.data.room_list);
          });
      })
      .catch((error) => {
        console.log(error);

      });
  };

  useEffect(() => {
    axios
      .get('/room')
      .then((response) => {
        setState(response.data.data.room_list);
      })
      .catch((error) => {
        console.log(error);
        toast.error('ERROR FETCHING ROOMS');
      });
  }, []);

  const tableColumns = React.useMemo(
    () => [
      { Header: 'Type', accessor: 'type' }, // accessor is the "key" in the data
      { Header: 'Floor', accessor: 'floor' },
      { Header: 'Room', accessor: 'room' },
      { Header: 'Bed', accessor: 'bed' },
      { Header: 'Price', accessor: 'price' },
      { Header: 'Status', accessor: 'status' },
      { Header: 'Reserved', accessor: 'reserved' },
    ],
    []
  )

  const tableData = React.useMemo(
    () => state?.map(dd => ({
      id: dd?.id,
      type: dd?.type,
      floor: dd?.floor,
      room: dd?.room,
      bed: dd?.bed,
      price: dd?.price,
      status: roomStatus(dd?.status),
      reserved: dd?.isReserved,
    })),
    [state]
  )

  const handleTableAction = (eventKey, id) => {
    const row = state.find(dd => dd.id === id);
    const action = {
      profile: () => { },
      view: () => { },
      edit: () => {
        setShowProfile((prevState) => {
          return {
            ...prevState,
            isDefault: false,
            createRoom: false,
            editRoom: true,
            editValues: row,
            id: row?.id,
          };
        });
      },
      delete: () => {
        // handleDelete(row?.id);
        confirmDelete(row);
      }
    }

    action[eventKey]()
  }

  return (
    <div className='room-bed-management-search-room-table'>
      <h2>Room and Beds List</h2>
      <Table
        data={tableData}
        columns={tableColumns}
        actions={{ hasEdit: true, hasDelete: true }}
        onAction={handleTableAction}
        pagination={['BOTTOM']}
      />

      {
        openModal && (
          <ConfirmDeleteModal
            showProfile={showProfile}
            setOpenModal={setOpenModal}
            setState={setState}
          />
        )
      }
    </div>
  );
};

PatientRoomTable.defaultProps = {
  state: [],
  setState: () => { },
  setShowProfile: () => { },
};

PatientRoomTable.propTypes = {
  state: PropTypes.array,
  setState: PropTypes.func,
  setShowProfile: PropTypes.func,
};

export default PatientRoomTable;
