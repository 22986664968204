/* eslint-disable multiline-comment-style */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable camelcase */
/* eslint-disable quotes */
/* eslint-disable no-unused-vars */
//  import core & vendor packages below
import axios from 'axios';
import { toast } from 'react-toastify';
import { useReactToPrint } from 'react-to-print';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useDispatch, useSelector } from 'react-redux';
import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Form, Button, FormControl } from 'react-bootstrap';

// import components below
import PrintContent from './PrintContent';
import PatientSelection from './PatientSelection';
import ConditionalRender from 'components/ConditionalRender';
import { selectActivePatients } from 'reducers/medicalRecordReducer';
import useCreateRecordResponse from './hooks/useCreateRecordResponse';
import PrintMedicalRecords from './PrintComponents/PrintMedicalRecords';
import { getPatients, selectPatientData } from 'reducers/patientReducer';
import { createMedicalRecord, getMedicalFormsbyID } from 'reducers/thunks/medicalRecordThunks';

// import assets below
import './MedicalRecords.scss';
import 'react-bootstrap-typeahead/css/Typeahead.css';

// main component
const MedicalRecords = ({ setShowScreen, setViewForm, viewForm, setIsEdit, isEdit }) => {
  const dispatch = useDispatch();

  const printRef = useRef(null);

  const logs = useSelector(selectActivePatients);
  const patientSelection = useSelector(selectPatientData);

  const [isAdd, setIsAdd] = useState(false);
  const [defaultData, setDefaultData] = useState();
  const [validated, setValidated] = useState(false);
  const [showPatientSelection, setShowPatientSelection] = useState(false);
  const [trigger, setTrigger] = useState({ trigger: false, patientID: false, recordID: false });
  const [formData, setFormData] = useState({
    name_of_hospital: '',
    address: '',
    lname: '',
    given_name: '',
    mname: '',
    age: '',
    sex: '',
    hrn: '',
    occupation: '',
    civil_status: '',
    citizenship: '',
    religion: '',
    patient_address: '',
    arrival_date: '',
    arrival_time: '',
    reffered_by: '',
    social_worker: '',
    case_type: '',
    NOI: '',
    TOI: '',
    DOI: '',
    POI: '',
    allergies: '',
    last_tetanus: '',
    current_rx: '',
    is_good_condition: '',
    is_coma: '',
    is_poor_condition: '',
    is_critical_condition: '',
    is_hemorrhage: '',
    is_shock: '',
    time: '',
    temperature: '',
    cardiac_rate: '',
    BP: '',
    refferend_by: '',
    resp_rate: '',
    physical_findings: '',
    diagnosis: '',
    treatment: '',
    disposition: '',
    disposition_time: '',
    condition_on_discharge: '',
    nurse_on_duty: '',
    attending_physician: '',
    patientId: Math.random(),
    type: 'medical_record',
  });

  const isAdding = isAdd === true;
  const isEditing = isEdit === true;
  const isNotEditing = isEdit === false;

  const snakeCaseString = (str) => {
    return (
      str &&
      str
        .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
        .map((s) => s.toLowerCase())
        .join('_')
    );
  };

  // For Add
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    if (!form.checkValidity() === false) {
      setTrigger({ ...trigger, trigger: true });
      setIsEdit(false);
      dispatch(createMedicalRecord({ ...formData, method: 'post' }));
      toast.success('Successfully created record.');
      setIsAdd(false);
      return;
    }
    toast.warning('Fill up the  fields');
    setValidated(true);
  };

  // For Edit
  const saveChanges = async () => {
    setTrigger({ ...trigger, trigger: true, recordID: true });

    const res = await dispatch(
      createMedicalRecord({
        ...formData,
        method: 'put',
        type: 'medical_record',
      }),
    );

    if (res.payload.status === 'Success') {
      setIsEdit(false);
      setDefaultData(formData);

      return;
    }

    toast.error('Record is not Created/Updated');
  };

  const handleChange = (key) => (e) => {
    setFormData({
      ...formData,
      [key]: e.target.value,
    });

    return;
  };

  const checkChange = (key) => (e) => {
    if (formData[key]) {
      setFormData({ ...formData, [key]: '' });
    }
    if (!formData[key]) {
      setFormData({ ...formData, [key]: e.target.value });
    }
  };

  const getType = (type) => {
    switch (type) {
      case 'age':
        return 'number';
      default:
        return 'text';
    }
  };

  const checkKey = (key) => {
    if (key === 'age') {
      return 'number';
    } else if (key === 'confined_start_date' || key === 'confined_end_date') {
      return 'date';
    } else {
      return 'text';
    }
  };

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    onBeforePrint: () => console.log('before printing'),
  });

  const handleShowPatientSelection = () => {
    setShowPatientSelection(true);
    return;
  };

  const handleClosePatientSelection = () => {
    setShowPatientSelection(false);
    return;
  };

  const options = patientSelection.map((row) => {
    return { full_name: row.full_name, patientId: row.id };
  });

  const textBox = (key) => {
    return (
      <FormControl
        type={`${checkKey(key)}`}
        disabled={!isEdit}
        style={{
          textAlign: !isEdit && 'center',
          color: !isEdit && '#2196F3',
          borderRadius: 0,
          /* width: `${isEdit ? '' : `${formData[key]?.length}rem`}` */
          boxShadow: 'none',
          fontSize: '14px',
        }}
        className={`ms-1 ${isEdit ? 'editable' : 'non-editable'} ${key === 'age' ? 'age' : ''}`}
        value={typeof formData !== 'undefined' ? formData[key] : ''}
        onChange={key !== 'full_name' && handleChange(key)}
        onClick={key === 'full_name' && handleShowPatientSelection}
        required
      />
    );
  };

  useEffect(() => {
    if (viewForm?.patientID) {
      setTrigger({ ...trigger, patientID: true });
      const patientid = viewForm?.patientID;
      dispatch(getMedicalFormsbyID(patientid));
    }
  }, []);

  useEffect(() => {
    dispatch(getPatients());
    if (Object.keys(viewForm.data).length === 0) {
      setIsAdd(true);
      setIsEdit(true);
      return;
    }

    // for refactor: externalize below axios code in to thunk
    const recordId = viewForm?.data?.id;
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/medical_records/form/${recordId}`)
      .then((response) => {
        // console.log('record info: ', response.data?.data);
        setFormData(response.data?.data?.mr_medical_record);
        setDefaultData(response.data?.data?.mr_medical_record);
      })
      .catch((error) => console.log('error fetching record info: ', error));
  }, []);

  useEffect(() => {
    const {
      isSuccess,
      isFailed,
      isFetchViewSuccess,
      isFetchRecordSuccess,
      patientID,
      medicalRecordID,
      successData,
    } = logs;

    useCreateRecordResponse({
      isSuccess,
      isFailed,
      isFetchViewSuccess,
      isFetchRecordSuccess,
      patientID,
      medicalRecordID,
      trigger,
      setTrigger,
      setIsEdit,
      setShowScreen,
      dispatch,
    });

    /* if (isFetchRecordSuccess && trigger.recordID) {
      const data = medicalRecordID.mr_medical_record;
      setFormData(data);
    } */
  }, [logs]);

  return (
    <>
      <div style={{ display: 'none' }}>
        <PrintContent ref={printRef} viewFormType={viewForm?.type}>
          <PrintMedicalRecords data={formData} viewFormType={viewForm?.type} />
        </PrintContent>
      </div>
      
      <Form noValidate validated={validated} onSubmit={handleSubmit} className='medical-record-form'>
        <Row className='mb-4'>
          <Col className='first-row'>
            <Form.Group className='input-group'>
              <FormControl
                placeholder='Enter Here'
                value={formData?.name_of_hospital}
                onChange={handleChange('name_of_hospital')}
                className='text-center'
                disabled={!isEdit}
              />
              <Form.Label>Name of Healthcare Facility</Form.Label>
            </Form.Group>

            <Form.Group className='input-group'>
              <FormControl
                placeholder='Enter Here'
                value={formData?.address}
                className='text-center'
                onChange={handleChange('address')}
                disabled={!isEdit}
              />
              <Form.Label>Address</Form.Label>
            </Form.Group>
          </Col>
        </Row>

        <Row className='mb-4'>
          <Col className='second-row'>
            <Form.Group className='input-group'>
              <Form.Label>Certificate No.</Form.Label>
              <FormControl
                placeholder='Enter Here'
                value={formData?.cert_no}
                onChange={handleChange('cert_no')}
                disabled={!isEdit}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className='mb-4'>
          <Col className='third-row'>
            <h2>{viewForm?.type}</h2>
          </Col>
        </Row>

        <Row className='mb-2'>
          <Col className='bordered-container information'>
            <div className='left-cont'>
              <h5>
                NAME <i>( Last, Given, Middle )</i>
              </h5>

              <Form.Group className='input-group'>
                <Form.Label>Last Name</Form.Label>
                <FormControl
                  placeholder='Enter Here'
                  value={formData?.lname}
                  onChange={handleChange('lname')}
                  onClick={handleShowPatientSelection}
                  disabled={!isEdit}
                  required
                />
              </Form.Group>

              <Form.Group className='input-group'>
                <Form.Label>Given Name</Form.Label>
                <FormControl
                  placeholder='Enter Here'
                  value={formData?.given_name}
                  onChange={handleChange('given_name')}
                  onClick={handleShowPatientSelection}
                  disabled={!isEdit}
                  required
                />
              </Form.Group>

              <Form.Group className='input-group'>
                <Form.Label>Middle Name</Form.Label>
                <FormControl
                  placeholder='Enter Here'
                  value={formData?.mname}
                  onChange={handleChange('mname')}
                  onClick={handleShowPatientSelection}
                  disabled={!isEdit}
                  required
                />
              </Form.Group>
            </div>

            <div className='right-cont'>
              <div className='row-group'>
                <Form.Group className='input-group small'>
                  <Form.Label>Age</Form.Label>
                  <FormControl
                    placeholder='Enter Here'
                    value={formData?.age}
                    onChange={handleChange('age')}
                    disabled={!isEdit}
                    required
                  />
                </Form.Group>

                <Form.Group className='input-group small'>
                  <Form.Label>Sex</Form.Label>
                  <FormControl
                    placeholder='Enter Here'
                    value={formData?.sex}
                    onChange={handleChange('sex')}
                    disabled={!isEdit}
                    required
                  />
                </Form.Group>

                <Form.Group className='input-group small'>
                  <Form.Label>HRN</Form.Label>
                  <FormControl
                    placeholder='Enter Here'
                    value={formData?.hrn}
                    onChange={handleChange('hrn')}
                    disabled={!isEdit}
                    required
                  />
                </Form.Group>

                <Form.Group className='input-group big'>
                  <Form.Label>Occupation</Form.Label>
                  <FormControl
                    placeholder='Enter Here'
                    value={formData?.occupation}
                    onChange={handleChange('occupation')}
                    disabled={!isEdit}
                  />
                </Form.Group>
              </div>

              <div className='row-group'>
                <Form.Group className='input-group small'>
                  <Form.Label>Civil Status</Form.Label>
                  <FormControl
                    placeholder='Enter Here'
                    value={formData?.civil_status}
                    onChange={handleChange('civil_status')}
                    disabled={!isEdit}
                    required
                  />
                </Form.Group>

                <Form.Group className='input-group small'>
                  <Form.Label>Citizenship</Form.Label>
                  <FormControl
                    placeholder='Enter Here'
                    value={formData?.citizenship}
                    onChange={handleChange('citizenship')}
                    disabled={!isEdit}
                    required
                  />
                </Form.Group>

                <Form.Group className='input-group religion'>
                  <Form.Label>Religion</Form.Label>
                  <FormControl
                    placeholder='Enter Here'
                    value={formData?.religion}
                    onChange={handleChange('religion')}
                    disabled={!isEdit}
                    required
                  />
                </Form.Group>
              </div>

              <div className='row-group'>
                <Form.Group className='input-group big'>
                  <Form.Label>Address</Form.Label>
                  <FormControl
                    placeholder='Enter Here'
                    value={formData?.patient_address}
                    onChange={handleChange('patient_address')}
                    disabled={!isEdit}
                    required
                  />
                </Form.Group>
              </div>
            </div>
          </Col>
        </Row>

        <h2 className='mt-4 mb-5 emergency-header'>EMERGENCY RECORD ROOM</h2>

        <Row>
          <Form.Group className='date-group mb-2'>
            <Form.Label>Date</Form.Label>
            <FormControl
              type='date'
              value={formData?.arrival_date}
              onChange={handleChange('arrival_date')}
              disabled={!isEdit}
              required
            />
          </Form.Group>

          <Col className='bordered-container emergency-record-room'>
            <div className='info one'>
              <h5>ARRIVAL TIME</h5>

              <Form.Group className='input-group'>
                <FormControl
                  placeholder='Enter Here'
                  type='time'
                  value={formData?.arrival_time}
                  onChange={handleChange('arrival_time')}
                  disabled={!isEdit}
                  required
                />
              </Form.Group>
            </div>
            <div className='info two'>
              <h5>REFFERED BY</h5>

              <Form.Group className='checkbox'>
                <div className='selections'>
                  <Form.Check
                    type='checkbox'
                    value='SELF'
                    label='SELF'
                    checked={formData?.reffered_by === 'SELF' ? true : false}
                    onChange={handleChange('reffered_by')}
                    disabled={!isEdit}
                  />
                  <Form.Check
                    type='checkbox'
                    value='PRIVATE MD'
                    label='PRIVATE MD'
                    checked={formData?.reffered_by === 'PRIVATE MD' ? true : false}
                    onChange={handleChange('reffered_by')}
                    disabled={!isEdit}
                  />
                </div>
              </Form.Group>

              <Form.Group className='checkbox'>
                <div className='selections'>
                  <Form.Check
                    type='checkbox'
                    value='OPD'
                    label='OPD'
                    checked={formData?.reffered_by === 'OPD' ? true : false}
                    onChange={handleChange('reffered_by')}
                    disabled={!isEdit}
                  />
                  <Form.Check
                    type='checkbox'
                    value='OTHERS'
                    label='OTHERS'
                    checked={formData?.reffered_by === 'OTHERS' ? true : false}
                    onChange={handleChange('reffered_by')}
                    disabled={!isEdit}
                  />
                </div>
              </Form.Group>
            </div>
            <div className='info three'>
              <h5>SOCIAL WORKER</h5>

              <Form.Group className='checkbox'>
                <div className='selections'>
                  <Form.Check
                    type='checkbox'
                    value='A'
                    label='A'
                    checked={formData?.social_worker === 'A' ? true : false}
                    onChange={handleChange('social_worker')}
                    disabled={!isEdit}
                  />
                  <Form.Check
                    type='checkbox'
                    value='B'
                    label='B'
                    checked={formData?.social_worker === 'B' ? true : false}
                    onChange={handleChange('social_worker')}
                    disabled={!isEdit}
                  />
                  <Form.Check
                    type='checkbox'
                    value='C'
                    label='C'
                    checked={formData?.social_worker === 'C' ? true : false}
                    onChange={handleChange('social_worker')}
                    disabled={!isEdit}
                  />
                  <Form.Check
                    type='checkbox'
                    value='D'
                    label='D'
                    checked={formData?.social_worker === 'D' ? true : false}
                    onChange={handleChange('social_worker')}
                    disabled={!isEdit}
                  />
                </div>
              </Form.Group>

              <Form.Group className='checkbox'>
                <div className='selections'>
                  <Form.Check
                    type='checkbox'
                    value='Senior Citizens'
                    label='Senior Citizens'
                    checked={formData?.social_worker === 'Senior Citizens' ? true : false}
                    onChange={handleChange('social_worker')}
                    disabled={!isEdit}
                  />
                  <Form.Check
                    type='checkbox'
                    value='EMS'
                    label='EMS'
                    checked={formData?.social_worker === 'EMS' ? true : false}
                    onChange={handleChange('social_worker')}
                    disabled={!isEdit}
                  />
                </div>
              </Form.Group>
            </div>
            <div className='info four'>
              <h5>CASE TYPE</h5>
              <Form.Group className='checkbox'>
                <div className='selections'>
                  <Form.Check
                    type='checkbox'
                    value='Emergency Cases'
                    label='Emergency Cases'
                    checked={formData?.case_type === 'Emergency Cases' ? true : false}
                    onChange={handleChange('case_type')}
                    disabled={!isEdit}
                  />
                </div>
              </Form.Group>

              <Form.Group className='checkbox'>
                <div className='selections'>
                  <Form.Check
                    type='checkbox'
                    value='Non ER Cases'
                    label='Non ER Cases'
                    checked={formData?.case_type === 'Non ER Cases' ? true : false}
                    onChange={handleChange('case_type')}
                    disabled={!isEdit}
                  />
                </div>
              </Form.Group>
            </div>
          </Col>
        </Row>

        <Row>
          <Col className='brief-history mt-3 mb-3'>
            <h5>
              BRIEF HISTORY: ( if accident, state where, when and how, injured. If illness describe,
              note case prior to admission )
            </h5>

            <Form.Group className='input-group mb-3'>
              <Form.Label>NOI:</Form.Label>
              <FormControl
                placeholder='Enter Here'
                value={formData?.NOI}
                onChange={handleChange('NOI')}
                disabled={!isEdit}
              />
            </Form.Group>

            <Form.Group className='input-group mb-3'>
              <Form.Label>TOI:</Form.Label>
              <FormControl
                placeholder='Enter Here'
                value={formData?.TOI}
                onChange={handleChange('TOI')}
                disabled={!isEdit}
              />
            </Form.Group>

            <Form.Group className='input-group mb-3'>
              <Form.Label>DOI:</Form.Label>
              <FormControl
                placeholder='Enter Here'
                value={formData?.DOI}
                onChange={handleChange('DOI')}
                disabled={!isEdit}
              />
            </Form.Group>

            <Form.Group className='input-group mb-4'>
              <Form.Label>POI:</Form.Label>
              <FormControl
                placeholder='Enter Here'
                value={formData?.POI}
                onChange={handleChange('POI')}
                disabled={!isEdit}
              />
            </Form.Group>

            <div className='group-cont'>
              <Form.Group className='input-group'>
                <Form.Label>Allergies</Form.Label>
                <FormControl
                  placeholder='Enter Here'
                  value={formData?.allergies}
                  onChange={handleChange('allergies')}
                  disabled={!isEdit}
                />
              </Form.Group>

              <Form.Group className='input-group'>
                <Form.Label>Last Tetanus</Form.Label>
                <FormControl
                  placeholder='Enter Here'
                  value={formData?.last_tetanus}
                  onChange={handleChange('last_tetanus')}
                  disabled={!isEdit}
                />
              </Form.Group>

              <Form.Group className='input-group'>
                <Form.Label>Current Rx</Form.Label>
                <FormControl
                  placeholder='Enter Here'
                  value={formData?.current_rx}
                  onChange={handleChange('current_rx')}
                  disabled={!isEdit}
                />
              </Form.Group>
            </div>
          </Col>
        </Row>

        <Row>
          <Col className='bordered-container condition-on-arrival mb-2'>
            <div className='info one'>
              <h5>CONDITION ON ARRIVAL</h5>

              <Form.Group className='checkbox'>
                <div className='selections'>
                  <Form.Check
                    type='checkbox'
                    value='GOOD'
                    label='GOOD'
                    onChange={checkChange('is_good_condition')}
                    checked={formData?.is_good_condition === 'GOOD' ? true : false}
                    disabled={!isEdit}
                  />
                  <Form.Check
                    type='checkbox'
                    value='HEMORRHAGE'
                    label='HEMORRHAGE'
                    onChange={checkChange('is_hemorrhage')}
                    checked={formData?.is_hemorrhage === 'HEMORRHAGE' ? true : false}
                    disabled={!isEdit}
                  />
                </div>
              </Form.Group>

              <Form.Group className='checkbox'>
                <div className='selections'>
                  <Form.Check
                    type='checkbox'
                    value={'SHOCK'}
                    label='SHOCK'
                    onChange={checkChange('is_shock')}
                    checked={formData?.is_shock === 'SHOCK' ? true : false}
                    disabled={!isEdit}
                  />
                  <Form.Check
                    type='checkbox'
                    value='COMA'
                    label='COMA'
                    onChange={checkChange('is_coma')}
                    checked={formData?.is_coma === 'COMA' ? true : false}
                    disabled={!isEdit}
                  />
                </div>
              </Form.Group>

              <Form.Group className='checkbox'>
                <div className='selections'>
                  <Form.Check
                    type='checkbox'
                    value='POOR'
                    label='POOR'
                    onChange={checkChange('is_poor_condition')}
                    checked={formData?.is_poor_condition === 'POOR' ? true : false}
                    disabled={!isEdit}
                  />
                </div>
              </Form.Group>

              <Form.Group className='checkbox'>
                <div className='selections'>
                  <Form.Check
                    type='checkbox'
                    value='CRITICAL'
                    label='CRITICAL'
                    onChange={checkChange('is_critical_condition')}
                    checked={formData?.is_critical_condition === 'CRITICAL' ? true : false}
                    disabled={!isEdit}
                  />
                </div>
              </Form.Group>
            </div>
            <div className='info two'>
              <h5>TIME</h5>
              <Form.Group className=''>
                <FormControl
                  placeholder='Enter Here'
                  onChange={handleChange('time')}
                  type='time'
                  value={formData?.time}
                  disabled={!isEdit}
                />
              </Form.Group>
            </div>
            <div className='info two'>
              <h5>TEMPERATURE</h5>
              <Form.Group className='input-group'>
                <FormControl
                  placeholder='Enter Here'
                  onChange={handleChange('temperature')}
                  value={formData?.temperature}
                  disabled={!isEdit}
                />
              </Form.Group>
            </div>
            <div className='info two'>
              <h5>CARDIAC RATE</h5>

              <Form.Group className='input-group'>
                <FormControl
                  placeholder='Enter Here'
                  onChange={handleChange('cardiac_rate')}
                  value={formData?.cardiac_rate}
                  disabled={!isEdit}
                />
              </Form.Group>
            </div>
            <div className='info two'>
              <h5>BP</h5>

              <Form.Group className='input-group'>
                <FormControl
                  placeholder='Enter Here'
                  onChange={handleChange('BP')}
                  value={formData?.BP}
                  disabled={!isEdit}
                />
              </Form.Group>
            </div>
            <div className='info two last'>
              <h5>RESP RATE</h5>

              <Form.Group className='input-group'>
                <FormControl
                  placeholder='Enter Here'
                  onChange={handleChange('resp_rate')}
                  value={formData?.resp_rate}
                  disabled={!isEdit}
                />
              </Form.Group>
            </div>
          </Col>
        </Row>

        <Row>
          <Col className='brief-history mt-3 mb-3'>
            <Form.Group className='input-group mb-3'>
              <Form.Label>PHYSICAL FINDINGS</Form.Label>
              <FormControl
                placeholder='Enter Here'
                value={formData?.physical_findings}
                onChange={handleChange('physical_findings')}
                disabled={!isEdit}
              />
            </Form.Group>

            <Form.Group className='input-group mb-3'>
              <Form.Label>DIAGNOSIS</Form.Label>
              <FormControl
                placeholder='Enter Here'
                value={formData?.diagnosis}
                onChange={handleChange('diagnosis')}
                disabled={!isEdit}
              />
            </Form.Group>

            <Form.Group className='input-group mb-3'>
              <Form.Label>TREATMENT</Form.Label>
              <FormControl
                placeholder='Enter Here'
                value={formData?.treatment}
                onChange={handleChange('treatment')}
                disabled={!isEdit}
              />
            </Form.Group>

            <Form.Group className='input-group mb-4'>
              <Form.Label>DISPOSITION</Form.Label>
              <Form.Group className='checkbox'>
                <div className='selections'>
                  <Form.Check
                    type='checkbox'
                    value='TREATED & DISCHARGED'
                    label='TREATED & DISCHARGED'
                    onChange={handleChange('disposition')}
                    checked={formData?.disposition === 'TREATED & DISCHARGED' ? true : false}
                    disabled={!isEdit}
                  />
                  <Form.Check
                    type='checkbox'
                    value='ADMITTED'
                    label='ADMITTED'
                    onChange={handleChange('disposition')}
                    checked={formData?.disposition === 'ADMITTED' ? true : false}
                    disabled={!isEdit}
                  />
                  <Form.Check
                    type='checkbox'
                    value='TRANSFERED TO'
                    label='TRANSFERED TO'
                    onChange={handleChange('disposition')}
                    checked={formData?.disposition === 'TRANSFERED TO' ? true : false}
                    disabled={!isEdit}
                  />

                  <Form.Group className='input-group'>
                    <Form.Label>Time</Form.Label>
                    <FormControl
                      type='time'
                      placeholder='Enter Here'
                      onChange={handleChange('disposition_time')}
                      value={formData?.disposition_time}
                      disabled={!isEdit}
                    />
                  </Form.Group>
                </div>
              </Form.Group>
            </Form.Group>

            <Form.Group className='input-group mb-4'>
              <Form.Label>CONDITION UPON DISCHARGE</Form.Label>

              <Form.Group className='checkbox'>
                <div className='selections'>
                  <Form.Check
                    type='checkbox'
                    value='GOOD'
                    label='GOOD'
                    onChange={handleChange('condition_on_discharge')}
                    checked={formData?.condition_on_discharge === 'GOOD' ? true : false}
                    disabled={!isEdit}
                  />
                  <Form.Check
                    type='checkbox'
                    value='SATISFACTORY'
                    label='SATISFACTORY'
                    onChange={handleChange('condition_on_discharge')}
                    checked={formData?.condition_on_discharge === 'SATISFACTORY' ? true : false}
                    disabled={!isEdit}
                  />
                  <Form.Check
                    type='checkbox'
                    value='SERIOUS'
                    label='SERIOUS'
                    onChange={handleChange('condition_on_discharge')}
                    checked={formData?.condition_on_discharge === 'SERIOUS' ? true : false}
                    disabled={!isEdit}
                  />
                  <Form.Check
                    type='checkbox'
                    value='CRITICAL'
                    label='CRITICAL'
                    onChange={handleChange('condition_on_discharge')}
                    checked={formData?.condition_on_discharge === 'CRITICAL' ? true : false}
                    disabled={!isEdit}
                  />
                  <Form.Check
                    type='checkbox'
                    value='EXPIRED'
                    label='EXPIRED'
                    onChange={handleChange('condition_on_discharge')}
                    checked={formData?.condition_on_discharge === 'EXPIRED' ? true : false}
                    disabled={!isEdit}
                  />
                </div>
              </Form.Group>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col className='signatures mb-4'>
            <Form.Group className='input-group'>
              <FormControl
                className='text-center'
                value={formData?.nurse_on_duty}
                onChange={handleChange('nurse_on_duty')}
                disabled={!isEdit}
              />
              <Form.Label>SIGNATURE OF NURSE ON DUTY</Form.Label>
            </Form.Group>

            <Form.Group className='input-group'>
              <FormControl
                className='text-center'
                value={formData?.attending_physician}
                onChange={handleChange('attending_physician')}
                disabled={!isEdit}
              />
              <Form.Label>NAME / SIGNATURE OF ATTENDING PYSICIAN</Form.Label>
            </Form.Group>
          </Col>
        </Row>

        <Row className=''>
          <Col className='form-buttons'>
            {/* Buttons to show for Add */}
            <ConditionalRender
              condition={isAdding}
              renderIf={
                <>
                  <Button type='submit' className='save' disabled={trigger.trigger}>
                    CREATE RECORD
                  </Button>
                  <Button
                    onClick={() => {
                      setIsEdit(false);
                      setShowScreen(0);
                    }}
                    className='cancel'
                  >
                    CANCEL
                  </Button>
                </>
              }
            />

            {/* Buttons to show for Edit/View */}
            <ConditionalRender
              condition={!isAdding}
              renderIf={
                <>
                  <ConditionalRender
                    condition={isNotEditing}
                    renderIf={
                      <>
                        <Button className='print' onClick={handlePrint}>
                          PRINT CERTIFICATE
                        </Button>
                        <Button
                          onClick={() => {
                            setIsEdit(true);
                          }}
                          className='edit'
                        >
                          EDIT CERTIFICATE
                        </Button>
                      </>
                    }
                  />

                  <ConditionalRender
                    condition={isEditing}
                    renderIf={
                      <>
                        <Button
                          onClick={() => {
                            saveChanges();
                          }}
                          className='save'
                          disabled={trigger.trigger}
                        >
                          SAVE CHANGES
                        </Button>
                        <Button
                          onClick={() => {
                            setIsEdit(false);
                            setFormData(defaultData);
                          }}
                          className='cancel'
                        >
                          CANCEL
                        </Button>
                      </>
                    }
                  />
                </>
              }
            />
          </Col>
        </Row>
      </Form>
      <PatientSelection
        showPatientSelection={showPatientSelection}
        handleClosePatientSelection={handleClosePatientSelection}
        patientSelection={patientSelection}
        fields={formData}
        setFields={setFormData}
      />
    </>
  );
};

export default MedicalRecords;
