import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import './index.scss';

const PrintContent = React.forwardRef(({ data, viewFormType }, ref) => {
    const [printData, setPrintData] = useState({});
    useEffect(() => {
        if (data) {
            setPrintData(data);
        }
    }, [data]);
    return (
        <div ref={ref}>
            <Row>
                <Col>
                    <p>OCRG Form No. 101</p>
                    <p>( Revised August 2021 )</p>
                </Col>
                <Col>
                    <p>Republic of the Philippines</p>
                    <p>OFFICE OF THE CIVIL REGISTRAT GENERAL</p>
                    <h2>{viewFormType}</h2>
                </Col>
                <Col>
                    <p>(To be accomplished in triplicate using black ink )</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Row>
                        <Col>
                            <p>Province</p>
                        </Col>
                        <Col>
                            <p>{printData?.province}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>City/ <br />Municipality</p>
                        </Col>
                        <Col>
                            <p>{printData?.city}</p>
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <p>Register No.</p>
                    <p>{printData?.register_no}</p>
                </Col>
            </Row>
            <div className='verticalLabelOnRightFlex'>
                <div>
                    <h3>FETUS</h3>
                </div>
                <div>
                    <Row>
                        <Col>
                            <h4>1. NAME</h4>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>( First )</p>
                            <p>{printData?.fname}</p>
                        </Col>
                        <Col>
                            <p>( Middle )</p>
                            <p>{printData?.mname}</p>
                        </Col>
                        <Col>
                            <p>( Last )</p>
                            <p>{printData?.lname}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h4>2. SEX</h4>
                            <p>{printData?.sex}</p>
                        </Col>
                        <Col>
                            <h4>3. DATE OF DELIVERY</h4>
                            <Row>
                                <Col>
                                    <p>( Day )</p>
                                    <p>{printData?.day_of_delivery}</p>
                                </Col>
                                <Col>
                                    <p>( Month )</p>
                                    <p>{printData?.month_of_delivery}</p>
                                </Col>
                                <Col>
                                    <p>( Year )</p>
                                    <p>{printData?.year_of_delivery}</p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <h4>4. PLACE OF DELIVERY</h4>
                        <Col>
                            <p>( Name of Hospital / Clinic / Institution )</p>
                            <p>{printData?.place_of_delivery_hospital}</p>
                        </Col>
                        <Col>
                            <p>( House No., St., Barangay )</p>
                            <p>{printData?.place_of_delivery_house_no_st_brgy}</p>
                        </Col>
                        <Col>
                            <p>( City / Municipality )</p>
                            <p>{printData?.place_of_delivery_city}</p>
                        </Col>
                        <Col>
                            <p>( Province )</p>
                            <p>{printData?.place_of_delivery_province}</p>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className='verticalLabelOnRightFlex'>
                <div>
                    <h3>MOTHER</h3>
                </div>
                <div>
                    <Row>
                        <Col>
                            <h4>6. MAIDEN NAME</h4>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>( First )</p>
                            <p>{printData?.mother_fname}</p>
                        </Col>
                        <Col>
                            <p>( Middle )</p>
                            <p>{printData?.mother_mname}</p>
                        </Col>
                        <Col>
                            <p>( Last )</p>
                            <p>{printData?.mother_lname}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>7. CITIZENSHIP</p>
                            <p>{printData?.mother_citizenship}</p>
                        </Col>
                        <Col>
                            <p>8. RELIGION/RELIGIOUS SECT</p>
                            <p>{printData?.mother_religion}</p>
                        </Col>
                        <Col>
                            <p>9. OCCUPATION</p>
                            <p>{printData?.mother_occupation}</p>
                        </Col>
                        <Col>
                            <p>10. AGE at the time of this delivery ( completed years )</p>
                            <p>{printData?.mother_age_at_time_of_delivery}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>11a. Total Number of children born alive</p>
                            <p>{printData?.total_no_of_children_born_alive}</p>
                        </Col>
                        <Col>
                            <p>11b. No. of children still living</p>
                            <p>{printData?.no_of_children_still_living}</p>
                        </Col>
                        <Col>
                            <p>11c. No. of children born alive but are now dead</p>
                            <p>{printData?.no_of_children_born_alive_but_now_dead}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h4>12. RESIDENCE</h4>
                            <Row>
                                <Col>
                                    <p>( House No., St., Barangay )</p>
                                    <p>{printData?.mother_house_no_st_brgy}</p>
                                </Col>
                                <Col>
                                    <p>( City / Municipality )</p>
                                    <p>{printData?.mother_city}</p>
                                </Col>
                                <Col>
                                    <p>( Province )</p>
                                    <p>{printData?.mother_province}</p>
                                </Col>
                                <Col>
                                    <p>( Country )</p>
                                    <p>{printData?.mother_country}</p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className='verticalLabelOnRightFlex'>
                <div>
                    <h3>FATHER</h3>
                </div>
                <div>
                    <Row>
                        <Col>
                            <h4>13. NAME</h4>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>( First )</p>
                            <p>{printData?.father_fname}</p>
                        </Col>
                        <Col>
                            <p>( Middle )</p>
                            <p>{printData?.father_mname}</p>
                        </Col>
                        <Col>
                            <p>( Last )</p>
                            <p>{printData?.father_lname}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>7. CITIZENSHIP</p>
                            <p>{printData?.father_citizenship}</p>
                        </Col>
                        <Col>
                            <p>8. RELIGION/RELIGIOUS SECT</p>
                            <p>{printData?.father_religion}</p>
                        </Col>
                        <Col>
                            <p>9. OCCUPATION</p>
                            <p>{printData?.father_occupation}</p>
                        </Col>
                        <Col>
                            <p>10. AGE at the time of this delivery ( completed years )</p>
                            <p>{printData?.father_age_at_time_of_delivery}</p>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className='verticalLabelOnRightFlex'>
                <div>
                    <h3>MARRIAGE OF PARENTS</h3>
                </div>
                <div>
                    <Row>
                        <Col>
                            <h4>18a. DATE</h4>
                            <Row>
                                <Col>
                                    <p>( Month )</p>
                                    <p>{printData?.marriage_month}</p>
                                </Col>
                                <Col>
                                    <p>( Day )</p>
                                    <p>{printData?.marriage_day}</p>
                                </Col>
                                <Col>
                                    <p>( Year )</p>
                                    <p>{printData?.marriage_year}</p>
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <h4>18b. PLACE</h4>
                            <Row>
                                <Col>
                                    <p>( City / Municipality )</p>
                                    <p>{printData?.marriage_place_city}</p>
                                </Col>
                                <Col>
                                    <p>( Province )</p>
                                    <p>{printData?.marriage_place_province}</p>
                                </Col>
                                <Col>
                                    <p>( Country )</p>
                                    <p>{printData?.marriage_place_country}</p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </div>
            <Row>
                <Col>
                    <h2>MEDICAL CERTIFICATE</h2>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h4>19. CAUSES OF FETAL DEATH</h4>
                    <Row>
                        <Col>
                            <p>a. Main disease / condition of fetus</p>
                        </Col>
                        <Col>
                            <p>{printData?.main_disease}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>b. Other diseases / conditions of the fetus</p>
                        </Col>
                        <Col>
                            <p>{printData?.other_diseases}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>c. Main maternal diseases / condition affecting fetus</p>
                        </Col>
                        <Col>
                            <p>{printData?.main_maternal_diseases}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>d. Other maternal disease / condition affecting fetus</p>
                        </Col>
                        <Col>
                            <p>{printData?.other_maternal_diseases}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>e. Other relevant circumtances</p>
                        </Col>
                        <Col>
                            <p>{printData?.other_relevant_circumstances}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>II. Other significant conditions contributing to death:</p>
                        </Col>
                        <Col>
                            <p>{printData?.significant_conditions_contributing_to_death}</p>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col>
                    <p>20. FETUS DIED:</p>
                </Col>
                <Col>
                    <p>{printData?.fetus_died}</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <p>21. LENGHT OF PREGNANCY ( in completed weeks )</p>
                    <p>{printData?.length_of_pregnancy_in_weeks}</p>
                </Col>
                <Col>
                    <p>{`22a. ATTENDANT { Physician, Nurse, Midwife, Hilot or Traditional Birth Attendant, None, others ( specify )}`}</p>
                    <p>{printData?.attendant}</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <p>22b. CERTIFICATE OF DEATH</p>
                    <p>I hereby certify that the forgoing particulars are correct as near as same can be ascertained and I further certify that I {printData?.cert_of_death_have_attended} the deceased and that death occurred at am/pm on the date of death of specified above.</p>
                    <Row>
                        <Col>
                            <Row>
                                <Col>
                                    <p>Signature</p>
                                </Col>
                                <Col>
                                    <p>{printData?.cert_of_death_signature}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p>Name in Print</p>
                                </Col>
                                <Col>
                                    <p>{printData?.cert_of_death_name_in_print}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p>Title or Position</p>
                                </Col>
                                <Col>
                                    <p>{printData?.cert_of_death_title_or_position}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p>Address</p>
                                </Col>
                                <Col>
                                    <p>{printData?.cert_of_death_address}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p>Date</p>
                                </Col>
                                <Col>
                                    <p>{printData?.cert_of_death_date}</p>
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <h4>REVIEWED BY:</h4>
                            <p>{printData?.cert_of_death_reviewed_by}</p>
                            <p>Signature Over Printed Name of Health Officer</p>
                            <p>{printData?.cert_of_death_reviewed_date}</p>
                            <p>Date</p>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col>
                    <p>23. CORPSE DISPOSAL( Burial, Cremation, if others, specify )</p>
                    <p>{printData?.corpse_disposal}</p>
                </Col>
                <Col>
                    <p>24. BURIAL / CREMATION PERMIT</p>
                    <Row>
                        <Col>
                            <p>Number</p>
                        </Col>
                        <Col>
                            <p>{printData?.burial_cremation_permit_no}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>Date Issued</p>
                        </Col>
                        <Col>
                            <p>{printData?.burial_cremation_permit_date_issued}</p>
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <p>25. AUTOPSY</p>
                    <p>{printData?.isAutopsy}</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <p>26. NAME AND ADDRESS OF CEMETERY OR CREMATORY</p>
                    <p>{printData?.name_and_address_of_cemetery_or_crematory}</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h4>27. CERTIFICATION OF INFORMAT</h4>
                    <p>I hereby certify that all information supplied are true and correct to my own knowledge and belief.</p>
                    <Row>
                        <Col>
                            <p>Signature</p>
                        </Col>
                        <Col>
                            <p>{printData?.cert_of_informat_signature}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>Name in Print</p>
                        </Col>
                        <Col>
                            <p>{printData?.cert_of_informat_name_in_print}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>Title or Position</p>
                        </Col>
                        <Col>
                            <p>{printData?.cert_of_informat_title_or_position}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>Address</p>
                        </Col>
                        <Col>
                            <p>{printData?.cert_of_informat_address}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>Date</p>
                        </Col>
                        <Col>
                            <p>{printData?.cert_of_informat_date}</p>
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <h4>28. PREPARED BY</h4>
                    <Row>
                        <Col>
                            <p>Signature</p>
                        </Col>
                        <Col>
                            <p>{printData?.prepared_by_signature}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>Name in Print</p>
                        </Col>
                        <Col>
                            <p>{printData?.prepared_by_name_in_print}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>Title or Position</p>
                        </Col>
                        <Col>
                            <p>{printData?.prepared_by_title_or_position}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>Address</p>
                        </Col>
                        <Col>
                            <p>{printData?.prepared_by_address}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>Date</p>
                        </Col>
                        <Col>
                            <p>{printData?.prepared_by_date}</p>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h4>28. RECEIVED BY</h4>
                    <Row>
                        <Col>
                            <p>Signature</p>
                        </Col>
                        <Col>
                            <p>{printData?.recieved_by_signature}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>Name in Print</p>
                        </Col>
                        <Col>
                            <p>{printData?.recieved_by_name_in_print}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>Title or Position</p>
                        </Col>
                        <Col>
                            <p>{printData?.recieved_by_title_or_position}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>Address</p>
                        </Col>
                        <Col>
                            <p>{printData?.recieved_by_address}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>Date</p>
                        </Col>
                        <Col>
                            <p>{printData?.recieved_by_date}</p>
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <h4>29. REGISTERED BY THE CIVIL REGISTRAR</h4>
                    <Row>
                        <Col>
                            <p>Signature</p>
                        </Col>
                        <Col>
                            <p>{printData?.civil_registrar_signature}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>Name in Print</p>
                        </Col>
                        <Col>
                            <p>{printData?.civil_registrar_name_in_print}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>Title or Position</p>
                        </Col>
                        <Col>
                            <p>{printData?.civil_registrar_title_or_position}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>Address</p>
                        </Col>
                        <Col>
                            <p>{printData?.civil_registrar_address}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>Date</p>
                        </Col>
                        <Col>
                            <p>{printData?.civil_registrar_date}</p>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h2>POSTMORTEM CERTIFICATION OF DEATH</h2>
                    <h4>I HEREBY VERTIFY that I have performed an autopsy upon the body of the deceased and that cause of death was</h4>
                    <p>{printData?.cause_of_death}</p>
                    <Row>
                        <Col>
                            <Row>
                                <Col>
                                    <p>Signature</p>
                                </Col>
                                <Col>
                                    <p>{printData?.postmortem_signature}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p>Name in Print</p>
                                </Col>
                                <Col>
                                    <p>{printData?.postmortem_name_in_print}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p>Date</p>
                                </Col>
                                <Col>
                                    <p>{printData?.postmortem_date}</p>
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <Row>
                                <Col>
                                    <p>Title or Position</p>
                                </Col>
                                <Col>
                                    <p>{printData?.postmortem_title_or_position}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p>Address</p>
                                </Col>
                                <Col>
                                    <p>{printData?.postmortem_address}</p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h2>CERTIFICATION OF EMBALMER</h2>
                    <h4>I HEREBY VERTIFY that I have embalmed {printData?.name} following all the regulations prescribed by the Department of Health.</h4>
                    <Row>
                        <Col>
                            <Row>
                                <Col>
                                    <p>Signature</p>
                                </Col>
                                <Col>
                                    <p>{printData?.embalmer_signature}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p>Name in Print</p>
                                </Col>
                                <Col>
                                    <p>{printData?.embalmer_name_in_print}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p>Date</p>
                                </Col>
                                <Col>
                                    <p>{printData?.embalmer_title_date}</p>
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <Row>
                                <Col>
                                    <p>Title/Designation</p>
                                </Col>
                                <Col>
                                    <p>{printData?.embalmer_title_designation}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p>License No.</p>
                                </Col>
                                <Col>
                                    <p>{printData?.embalmer_license_no}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Row>
                                        <Col>
                                            <p>Issued on</p>
                                        </Col>
                                        <Col>
                                            <p>{printData?.embalmer_license_issued_on}</p>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row>
                                        <Col>
                                            <p>at</p>
                                        </Col>
                                        <Col>
                                            <p>{printData?.embalmer_license_issued_at}</p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p>Expiry Date</p>
                                </Col>
                                <Col>
                                    <p>{printData?.embalmer_license_expiry_date}</p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h2>AFFIDAVIT FOR DELAYED REGISTRATION OF DEATH</h2>
                    <p>I, {printData?.affidavit_name}, of legal age {printData?.affidavit_civil_status} , with residence and postal address {printData?.affidavit_address}, after being duly sworn in accordance with law, do hereby depose and say;</p>
                    <p>1. That {printData?.affidavit_deceased_name} died on {printData?.affidavit_died_on} in {printData?.affidavit_died_in} and was burried / cremated in {printData?.affidavit_burried_or_cremated_in} on {printData?.affidavit_burried_or_cremated_on}</p>
                    <p>2. That the deceased at the time of his / her death: {printData?.enable_attended} {printData?.affidavit_was_attended}</p>
                    <p>3. That the cause of death of the deceased was {printData?.affidavit_cause_of_death}</p>
                    <p>4. That the reason for the delay in registering this death was due to {printData?.affidavit_reason_for_late_registration_of_death}</p>
                    <p>5. That I am executing this affidavit to attest to the truthfulness of the foregoing statements for all legal intents and purposes.</p>
                    <p>In truth whereof, I have affixed my signature below this {printData?.affidavit_day} day of {printData?.affidavit_month}, {printData?.affidavit_year} at {printData?.affidavit_address_1}, Philippines.</p>
                    <p>{printData?.affidavit_signature_over_printed_name_of_affiant}</p>
                    <p>( Signature Over Printed Name of Affiant )</p>
                    <p><b>SUBSCRIBED AND SWORN</b> to be me this {printData?.affidavit_subscribed_and_sworn_day} day of {printData?.affidavit_subscribed_and_sworn_month}, {printData?.affidavit_subscribed_and_sworn_year} at {printData?.addraffidavit_subscribed_and_sworn_addressess}, Philippines, affiant who exhibited to me his Community Tax Certificate {printData?.affidavit_tax_certificate} issued on {printData?.affidavit_tax_certificate_issued_on} at {printData?.affidavit_tax_certificate_issued_at}</p>
                    <Row>
                        <Col>
                            <Row>
                                <Col>
                                    <Row>
                                        <Col>
                                            <p>{printData?.affidavit_signature_of_administering_officer}</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <p>( Signature of the Administering Officer )</p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Row>
                                        <Col>
                                            <p>{printData?.affidavit_name_in_print}</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <p>Name in Print</p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <Row>
                                <Col>
                                    <Row>
                                        <Col>
                                            <p>{printData?.affidavit_position}</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <p>Position / Title / Designation</p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Row>
                                        <Col>
                                            <p>{printData?.affidavit_address_2}</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <p>Address</p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
});

PrintContent.displayName = 'PrintContent';

export default PrintContent;