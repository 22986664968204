/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
//  import core & vendor packages below
import { useState } from 'react';
import { Offcanvas, InputGroup, Form, ListGroup, Pagination, Button } from 'react-bootstrap';

// import assets below
import './PatientSelection.scss';

// main component
const PatientSelection = ({
  showPatientSelection,
  handleClosePatientSelection,
  patientSelection,
  fields,
  setFields,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [query, setQuery] = useState('');

  const searchPatient = (patients, firstDataIndex, lastDataIndex) =>
    patients
      .filter((patient) => patient?.full_name.toLowerCase().includes(query.toLowerCase()))
      .slice(firstDataIndex, lastDataIndex);

  const handleClose = () => {
    handleClosePatientSelection();
    setQuery('');
  };
  // added fields to be fetched by the AdmissionAndDischarge
  const handlePatientSelect = (patient) => {
    switch (fields?.type) {
      case 'medical_record':
        setFields({
          ...fields,
          patientId: patient?.id,
          given_name: patient?.first_name,
          mname: patient?.middle_name,
          lname: patient?.last_name,
          sex: patient?.sex.charAt(0).toUpperCase() + patient?.sex.slice(1),
          age: patient?.age,
          citizenship: patient?.nationality,
        });
        break;
      case 'admission_and_discharge':
        setFields({
          ...fields,
          patientId: patient?.id,
          patient_fname: patient?.first_name,
          patient_mname: patient?.middle_name,
          patient_lname: patient?.last_name,
          date_of_birth: patient?.date_of_birth,
          sex: patient?.sex.charAt(0).toUpperCase() + patient?.sex.slice(1),
          age: patient?.age,
          nationality: patient?.nationality,
        });
        break;
      case 'discharge_summary':
        setFields({
          ...fields,
          patientId: patient?.id,
          given_name: patient?.first_name,
          mname: patient?.middle_name,
          lname: patient?.last_name,
        });
        break;
      case 'cert_of_dismembered_body_part':
        setFields({
          ...fields,
          patientId: patient?.id,
          amputee_fname: patient?.first_name,
          amputee_mname: patient?.middle_name,
          amputee_lname: patient?.last_name,
          amputee_age: patient?.age,
          amputee_sex: patient?.sex.charAt(0).toUpperCase() + patient?.sex.slice(1),
          amputee_citizenship: patient?.nationality,
        });
        break;
      case 'medico_legal_cert':
      case 'certificate_of_confinement':
      case 'medical_cert':
        setFields({
          ...fields,
          patientId: patient?.id,
          full_name: patient?.full_name,
          age: patient?.age,
        });
        break;
      case 'subpoena_duces_tecum':
        setFields({
          ...fields,
          patientId: patient?.id,
          vs: patient?.full_name,
        });
        break;
      case 'emergency_treatment':
        setFields({
          ...fields,
          age: patient?.age,
          sex: patient?.sex,
          patientId: patient?.id,
          patient_lname: patient?.last_name,
          patient_fname: patient?.first_name,
          patient_mname: patient?.middle_name,
          date_of_birth: patient?.date_of_birth,
        });
        break;
      case 'history_and_physical_examination':
        setFields({
          ...fields,
          age: patient?.age,
          gender: patient?.sex,
          patientId: patient?.id,
          lname: patient?.last_name,
          given_name: patient?.first_name,
          mname: patient?.middle_name,
          date_of_birth: patient?.date_of_birth,
        });
        break;
      case 'cert_of_death':
        setFields({
          ...fields,
          patientId: patient?.id,
          lname: patient?.last_name,
          fname: patient?.first_name,
          mname: patient?.middle_name,
          // date_of_birth: patient?.date_of_birth,
        });
        break;
      case 'subpoena_ad_testificandum':
        setFields({
          ...fields,
          patientId: patient?.id,
          patient_full_name: patient?.full_name,
        });
        break;
      case 'outpatient_record':
        setFields({
          ...fields,
          patientId: patient?.id,
          lname: patient?.last_name,
          fname: patient?.first_name,
          mname: patient?.middle_name,
          age: patient?.age,
          sex: patient?.sex,
        });
        break;
      case 'cert_of_fetal_death':
        setFields({
          ...fields,
          patientId: patient?.id,
          mother_lname: patient?.last_name,
          mother_fname: patient?.first_name,
          mother_mname: patient?.middle_name,
        });
        break;
      default:
        console.log('patient', patient);
        break;
    }
    handleClose();
  };

  const dataPerPage = 20;
  const lastDataIndex = currentPage * dataPerPage;
  const firstDataIndex = lastDataIndex - dataPerPage;

  const currentDisplayedData = query
    ? searchPatient(patientSelection)
    : patientSelection?.slice(firstDataIndex, lastDataIndex);

  return (
    <Offcanvas show={showPatientSelection} onHide={handleClose} className='patient_selection'>
      <Offcanvas.Header>
        <InputGroup className='input_group'>
          <Form.Control
            id='search_patient'
            onChange={(event) => setQuery(event.target.value)}
            placeholder='Search Patient'
          />
        </InputGroup>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <ListGroup id='patient_list'>
          {currentDisplayedData?.map((patient) => (
            <ListGroup.Item key={patient?.id} action onClick={() => handlePatientSelect(patient)}>
              {patient?.full_name}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Offcanvas.Body>
      <Offcanvas.Header className='offcanvas_footer'>
        <Pagination className='list_pagination'>
          <Pagination.Item
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Prev
          </Pagination.Item>
          <Pagination.Item
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === Math.ceil(patientSelection.length / dataPerPage)}
          >
            Next
          </Pagination.Item>
        </Pagination>
        <Button variant='outline-dark' onClick={handleClosePatientSelection}>
          Close
        </Button>
      </Offcanvas.Header>
    </Offcanvas>
  );
};

export default PatientSelection;
