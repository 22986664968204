/* eslint-disable */
//  import core & vendor packages below
import React from 'react';
import moment from 'moment';
import { Row, Form, Col, Button, Image, Table } from 'react-bootstrap';

// import assets below
import './PrintContent.scss';
// import logo from 'assets/images/new-mcmc-logo.png';
import logo from 'assets/images/strh.png';

const { REACT_APP_LOGO, REACT_APP_HOSPITAL_NAME, REACT_APP_HOSPITAL_STREET_ADDRESS } =
  process.env;

// main component
export class PrintContent extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const { data } = this.props;

    return (
      <div id='print_discharge_profile' ref={(el) => (this.printRef = el)}>
        <div className='header'>
          <img src={REACT_APP_LOGO} alt='print_logo' />
          <h1>{REACT_APP_HOSPITAL_NAME}</h1>
          <h3>{REACT_APP_HOSPITAL_STREET_ADDRESS}</h3>
        </div>
        <div className='profile_content'>
          <div className='field'>
            <p>PATIENT ID: </p>
            <span>{data?.id}</span>
          </div>
          <div className='field'>
            <p>FULL NAME: </p>
            <span>{data?.full_name}</span>
          </div>
          <div className='field'>
            <p>AGE / GENDER: </p>
            <span>
              {data?.age} / {data?.sex}
            </span>
          </div>

          <Row className='mb-5 patient-banner'>
            <Col lg='3' className='right'>
              <div className='p-3 mt-2'>
                <h6>Allergies:</h6>
                <div className='allergies d-flex flex-column'>
                  {data?.patient_allergies?.length > 0 ? (
                    data?.patient_allergies?.map((item, key) => {
                      return (
                        <span key={key} className='fw-bold'>
                          {' '}
                          {item?.allergy}
                        </span>
                      );
                    })
                  ) : (
                    <span className='fw-bold'>N/A</span>
                  )}
                </div>
              </div>
            </Col>
            <Col lg='6'>
              <div className='d-flex flex-column align-items-between h-100 p-3'>
                <Row>
                  <Col>
                    <div className='mt-2'>
                      <span>Location: </span>
                      <span className='fw-bold'>
                        {data?.assigned_room ? data?.assigned_room?.room?.room : '-'}
                      </span>
                    </div>
                  </Col>
                  <Col>
                    <div className='mt-2'>
                      <span>Admission Date: </span>{' '}
                      <span>
                        {data?.admission_date
                          ? moment(data?.admission_date).format('MMMM-DD-YYYY')
                          : '-'}
                      </span>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className='mt-2'>
                      <span>Visit Number: </span>
                      <span className='fw-bold'>
                        {data?.active_visit_number ? data?.active_visit_number : '-'}
                      </span>
                    </div>
                  </Col>
                  <Col>
                    <div className='mt-2'>
                      <span>Length of Stay: </span>{' '}
                      <span className='fw-bold'>{data?.lengthOfStay}</span>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col lg='3' className='left'>
              <div className='p-3 mt-2'>
                <h6>Doctors:</h6>
                <div className='d-flex flex-column'>
                  {data?.assigned_doctors?.length > 0 ? (
                    data?.assigned_doctors?.map((item) => {
                      return (
                        <span key='doctor' className='fw-bold'>
                          Dr. {item?.user?.full_name} {item?.user?.first_name}{' '}
                          {item?.user?.middle_name}
                        </span>
                      );
                    })
                  ) : (
                    <span className='fw-bold'>N/A</span>
                  )}
                </div>
              </div>
            </Col>
          </Row>

          <Row className='mb-4'>
            <Col className='discharge-table'>
              <h5>Medications</h5>

              <Table responsive>
                <thead>
                  <tr>
                    <th>Medication Description</th>
                    <th>Doze</th>
                    <th>Route of Administration</th>
                    <th>Times of Administration</th>
                    <th>Unit of measure</th>
                    <th>Unit price</th>
                    <th>QTY</th>
                    <th>Total Amount</th>

                    {/* <th>Item Code</th>
                                    <th>Medication Description</th>
                                    <th>Priority</th>
                                    <th>Ordering Physician</th>
                                    <th>Clinical Indication</th>
                                    <th>QTY</th>
                                    <th>Notes</th>
                                    <th></th> */}
                  </tr>
                </thead>
                <tbody>
                  {data &&
                    data?.medications?.map((data) => {
                      return (
                        <tr key={data.id}>
                          <td>{data.medication}</td>
                          <td>{data.doze}</td>
                          <td>{data.route_of_administration}</td>
                          <td>{data.times_of_administration}</td>
                          <td>{data.uom}</td>
                          <td>{data.unit_price}</td>
                          <td>{data.quantity}</td>
                          <td>{data.total_amount}</td>
                          {/* <td>{data.code}</td>
                                        <td>
                                            <p>
                                            {data.medication} <span>{data.sub_description}</span>
                                            </p>
                                        </td>
                                        <td>{data.priority}</td>
                                        <td>{data.ordering_physician}</td>
                                        <td>{data.clinical_indication}</td>
                                        <td>{data.qty}</td>
                                        <td>{data.notes}</td>
                                        <td className={data.status}>
                                            <span>{data.status}</span>
                                        </td> */}
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </Col>
          </Row>

          <Row className='mb-4'>
            <Col className='discharge-table'>
              <h5>Supplies</h5>

              <Table responsive>
                <thead>
                  <tr>
                    <th>Supply Description</th>
                    <th>Quantity</th>
                    <th>Priority</th>
                    <th>Notes</th>

                    {/* <th>Item Code</th>
                                    <th>Medication Description</th>
                                    <th>Priority</th>
                                    <th>Ordering Physician</th>
                                    <th>Clinical Indication</th>
                                    <th>QTY</th>
                                    <th>Notes</th>
                                    <th></th> */}
                  </tr>
                </thead>
                <tbody>
                  {data?.supplies &&
                    data?.supplies.map((data) => {
                      return (
                        <tr key={data.id}>
                          <td>{data.supply}</td>
                          <td>{data.quantity}</td>
                          <td>{data.priority}</td>
                          <td>{data.notes}</td>
                          {/* <td>{data.code}</td>
                                        <td>
                                            <p>
                                            {data.description} <span>{data.sub_description}</span>
                                            </p>
                                        </td>
                                        <td>{data.priority}</td>
                                        <td>{data.ordering_physician}</td>
                                        <td>{data.clinical_indication}</td>
                                        <td>{data.qty}</td>
                                        <td>{data.notes}</td>
                                        <td className={data.status}>
                                            <span>{data.status}</span>
                                        </td> */}
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </Col>
          </Row>

          <Row className='mb-4'>
            <Col className='discharge-table'>
              <h5>Procedures</h5>

              <Table responsive>
                <thead>
                  <tr>
                    <th>Surgery Code</th>
                    <th>Description</th>
                    <th>Remarks</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {data?.request_procedures &&
                    data?.request_procedures.map((data) => {
                      return (
                        <tr key={data.id}>
                          <td>{data.ctp_code}</td>
                          <td>{data.procedure}</td>
                          <td>{data.remarks}</td>
                          <td className={data.status}>
                            <span>{data.status}</span>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </Col>
          </Row>

          <Row className='mb-4'>
            <Col className='discharge-table'>
              <h5>Tests</h5>

              <Table responsive>
                <thead>
                  <tr>
                    <th>Test</th>
                    <th>Ordering Physician</th>
                    <th>Notes</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {data?.laboratory_requests &&
                    data?.laboratory_requests.map((data) => {
                      return (
                        <tr key={data.id}>
                          <td>{data.lab_type}</td>
                          <td>{data.user.full_name}</td>
                          <td>{data.remarks}</td>
                          <td className={data.status}>
                            <span>{data.status}</span>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
