/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import { Row, Col, Button } from 'react-bootstrap';

// import assets below
import moment from 'moment';
import './PatientProfileTab.scss';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { selectPatientItem } from 'reducers/patientReducer';
import { toast } from 'react-toastify';
import { showPatient } from 'reducers/patientReducer';

// main component
const PatientProfileTab = () => {
  const patientProfile = useSelector(selectPatientItem);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log('patientProfile', patientProfile);
  }, [patientProfile]);

  const deleteFile = (id) => {
    axios.delete(
      `${process.env.REACT_APP_API_BASE_URL}/patient/file_upload/${id}`,
    ).then((response) => {
      toast.warning('Successully deleted');
      dispatch(showPatient(patientProfile.id));
    });
  };

  return (
    <div className='patientProfileTab'>
      <div className='patientProfile__header'>
        <h4>Patient Demographics</h4>
        <Row>
          <Col>
            <div className='patientProfile__content'>
              <Row>
                <Col lg='5'>
                  <div className='wrapper'>
                    <span>Nationality</span>
                    <span>:</span>
                  </div>
                </Col>
                <Col lg='auto'>
                  <span className='value'>{patientProfile?.nationality}</span>
                </Col>
              </Row>
              <Row>
                <Col lg='5'>
                  <div className='wrapper'>
                    <span>Civil Status</span>
                    <span>:</span>
                  </div>
                </Col>
                <Col lg='auto'>
                  <span className='value'>{patientProfile?.civil_status}</span>
                </Col>
              </Row>
              <Row>
                <Col lg='5'>
                  <div className='wrapper'>
                    <span>Religion</span>
                    <span>:</span>
                  </div>
                </Col>
                <Col lg='auto'>
                  <span className='value'>{patientProfile?.religion}</span>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>

      <Row>
        <Col>
          <div className='patientProfile__header'>
            <h4>Patient Families</h4>
            {patientProfile?.patient_mother && (
              <Row>
                <Col>
                  <Row>
                    <Col lg='4'>
                      <span>
                        <b>Mother Full Name</b>
                      </span>
                    </Col>
                    <Col>
                      <span>
                        <b>Patient ID</b>
                      </span>
                    </Col>
                  </Row>
                  <div className='patientProfile__content'>
                    <Row>
                      <Col lg='4'>
                        <span className='value'>
                          {patientProfile?.patient_mother?.mother_full_name}
                        </span>
                      </Col>
                      <Col lg='auto'>
                        <span className='value'>{patientProfile?.patient_mother?.mother_id}</span>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            )}
            {patientProfile?.patient_children?.length > 0 && (
              <Row>
                <Col>
                  <Row>
                    <Col lg='4'>
                      <span>
                        <b>Child Full Name</b>
                      </span>
                    </Col>
                    <Col>
                      <span>
                        <b>Patient ID</b>
                      </span>
                    </Col>
                  </Row>
                  {patientProfile?.patient_children?.length > 0 ? (
                    patientProfile?.patient_children?.map((item, key) => {
                      return (
                        <div className='patientProfile__content'>
                          <Row>
                            <Col lg='4'>
                              <span className='value'>{item?.child_full_name}</span>
                            </Col>
                            <Col lg='auto'>
                              <span className='value'>{item?.child_id}</span>
                            </Col>
                          </Row>
                        </div>
                      );
                    })
                  ) : (
                    <div className='patientProfile__content'>
                      <span>N/A</span>
                    </div>
                  )}
                </Col>
              </Row>
            )}
          </div>
        </Col>
      </Row>

      <div className='patientProfile__header'>
        <h4>Patient Address Details</h4>
        <Row>
          <Col>
            <div className='patientProfile__content'>
              <h5>Present Address</h5>
              <Row>
                <Col lg='5'>
                  <div className='wrapper'>
                    <span>Country</span>
                    <span>:</span>
                  </div>
                </Col>
                <Col lg='auto'>
                  <span className='value'>
                    {patientProfile?.patient_present_address?.present_country}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col lg='5'>
                  <div className='wrapper'>
                    <span>State / Province / Religion</span>
                    <span>:</span>
                  </div>
                </Col>
                <Col lg='auto'>
                  <span className='value'>
                    {patientProfile?.patient_present_address?.present_region}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col lg='5'>
                  <div className='wrapper'>
                    <span>City</span>
                    <span>:</span>
                  </div>
                </Col>
                <Col lg='auto'>
                  <span className='value'>
                    {patientProfile?.patient_present_address?.present_city}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col lg='5'>
                  <div className='wrapper'>
                    <span>Zip Code</span>
                    <span>:</span>
                  </div>
                </Col>
                <Col lg='auto'>
                  <span className='value'>
                    {patientProfile?.patient_present_address?.present_zip_code}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col lg='5'>
                  <div className='wrapper'>
                    <span>Address Line 1</span>
                    <span>:</span>
                  </div>
                </Col>
                <Col lg='auto'>
                  <span className='value'>
                    {patientProfile?.patient_present_address?.present_address1}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col lg='5'>
                  <div className='wrapper'>
                    <span>Subdivision/Village</span>
                    <span>:</span>
                  </div>
                </Col>
                <Col lg='auto'>
                  <span className='value'>
                    {patientProfile?.patient_present_address?.present_address2}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col lg='5'>
                  <div className='wrapper'>
                    <span>Barangay</span>
                    <span>:</span>
                  </div>
                </Col>
                <Col lg='auto'>
                  <span className='value'>
                    {patientProfile?.patient_present_address?.present_brgy}
                  </span>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
      <Row>
        <Col>
          <div className='patientProfile__header'>
            <h4>Patient Contact Details</h4>
            <Row>
              <Col>
                {patientProfile?.patient_contact_details?.length > 0 ? (
                  patientProfile?.patient_contact_details.map((item, key) => {
                    return (
                      <div className='patientProfile__content'>
                        <Row>
                          <Col lg='5'>
                            <div className='wrapper'>
                              {<span>Contact Type</span>}
                              <span>:</span>
                            </div>
                          </Col>
                          <Col lg='auto'>
                            <span className='value'>{item.type}</span>
                          </Col>
                        </Row>

                        <Row>
                          <Col lg='5'>
                            <div className='wrapper'>
                              {<span>Contact Details</span>}
                              <span>:</span>
                            </div>
                          </Col>
                          <Col lg='auto'>
                            <span className='value'>{item.details}</span>
                          </Col>
                        </Row>
                      </div>
                    );
                  })
                ) : (
                  <div className='patientProfile__content'>
                    <Row>
                      <Col lg='5'>
                        <div className='wrapper'>
                          <span>Contact Type</span>
                          <span>:</span>
                        </div>
                      </Col>
                      <Col lg='auto'>
                        <span className='value'>N/A</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg='5'>
                        <div className='wrapper'>
                          <span>Contact Details</span>
                          <span>:</span>
                        </div>
                      </Col>
                      <Col lg='auto'>
                        <span className='value'>N/A</span>
                      </Col>
                    </Row>
                  </div>
                )}
              </Col>
            </Row>
          </div>
        </Col>
        <Col>
          <div className='patientProfile__header'>
            <h4>Patient&#39;s Other Details</h4>
            <Row>
              <Col>
                {patientProfile?.patient_other_details?.length > 0 ? (
                  patientProfile?.patient_other_details.map((item, key) => {
                    return (
                      <div className='patientProfile__content'>
                        <Row>
                          <Col lg='5'>
                            <div className='wrapper'>
                              {<span>Patient Endorsement</span>}
                              <span>:</span>
                            </div>
                          </Col>
                          <Col lg='auto'>
                            <span className='value'>{item.endorsement}</span>
                          </Col>
                        </Row>
                      </div>
                    );
                  })
                ) : (
                  <div className='patientProfile__content'>
                    <Row>
                      <Col lg='5'>
                        <div className='wrapper'>
                          <span>Patient Endorsement</span>
                          <span>:</span>
                        </div>
                      </Col>
                      <Col lg='auto'>
                        <span className='value'>N/A</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg='5'>
                        <div className='wrapper'>
                          <span>Details</span>
                          <span>:</span>
                        </div>
                      </Col>
                      <Col lg='auto'>
                        <span className='value'>N/A</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg='5'>
                        <div className='wrapper'>
                          <span>Patient Other Details Status</span>
                          <span>:</span>
                        </div>
                      </Col>
                      <Col lg='auto'>
                        <span className='value'>N/A</span>
                      </Col>
                    </Row>
                  </div>
                )}
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <div className='patientProfile__header'>
            <h4>Patient Files</h4>
            <Row>
              <Col>
                <Row>
                  <Col lg='4'>
                    <span>
                      <b>Upload Timestamp</b>
                    </span>
                  </Col>
                  <Col lg='6'>
                    <span>
                      <b>Filename</b>
                    </span>
                  </Col>
                  <Col lg='2'>
                    <span>
                      <b>Action</b>
                    </span>
                  </Col>
                </Row>
                {patientProfile?.file_uploads?.length > 0 ? (
                  patientProfile?.file_uploads?.map((item, key) => {
                    return (
                      <div className='patientProfile__content'>
                        <Row>
                          <Col lg='4'>
                            <span>{moment(item?.createdAt).format('MMMM-DD-YYYY hh:mm A')}</span>
                          </Col>
                          <Col lg='6'>
                            <span className='value'>
                              <a
                                href={`${process.env.REACT_APP_API_BASE_URL}/files/${item?.filename}`}
                                target='_blank'
                              >
                                {item?.originalname}
                              </a>
                            </span>
                          </Col>
                          <Col lg='2'>
                            <Button
                              onClick={() => {
                                deleteFile(item.id);
                              }}
                              className='remove-access'
                            >
                              DELETE
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    );
                  })
                ) : (
                  <div className='patientProfile__content'>
                    <span>N/A</span>
                  </div>
                )}
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default PatientProfileTab;
