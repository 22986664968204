const Links = [
  { label: 'Dashboard', path: '/dashboard' },
  { label: 'Admission', path: '/dashboard/admissions' },
  { label: 'Doctors', path: '/dashboard/doctors' },
  { label: 'Nursing', path: '/dashboard/nursing' },
  { label: 'Room Bed Management', path: '/dashboard/room-bed-management' },
  { label: 'Emergency Department', path: '/dashboard/emergency-department' },
  { label: 'Outpatient Department', path: '/dashboard/outpatient-department' },
  { label: 'Laboratory', path: '/dashboard/laboratory' },
  { label: 'Inventory', path: '/dashboard/inventory' },
  { label: 'Billing', path: '/dashboard/billing' },
  { label: 'Philhealth', path: '/dashboard/philhealth' },
  { label: 'Pharmacy', path: '/dashboard/pharmacy' },
  { label: 'Reports', path: '/dashboard/reports' },
  { label: 'Audit Logs', path: '/dashboard/audit-logs' },
  { label: 'Employees', path: '/dashboard/employees/list' },
  { label: 'Social Services', path: '/dashboard/social-services' },
  { label: 'Accounting', path: '/dashboard/accounting' },
  { label: 'Medical Records', path: '/dashboard/medical-records' },
  { label: 'ACL', path: '/dashboard/acl' },
];

export default Links;

