/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-target-blank */
// import core & vendor packages below

import moment from 'moment';
import { Row, Col } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { toast } from 'react-toastify';
import Table from 'components/ReactTable';
import TableUI from 'components/UI/TableUI';
import ConditionalRender from 'components/ConditionalRender';
import { selectPatientItem, showPatient } from 'reducers/patientReducer';
import { getOrdersList, selectnurse, resetOrderListState } from 'reducers/nurseReducer';

import './OrdersTable.scss';
import { getItems } from 'reducers/departmentInventoryReducer';
import axios from 'axios';

const OrdersTable = ({ showProfile, setShowModal }) => {
  const dispatch = useDispatch();
  const orderState = useSelector(selectnurse);
  const { orderItems } = orderState;
  const patientProfile = useSelector(selectPatientItem);
  const [filteredOrdersData, setFilteredOrdersData] = useState([]);
  const [filteredOrderItemsData, setFilteredOrderItemsData] = useState([]);
  const [filteredItemsData, setFilteredItemsData] = useState([]);

  // const [state, setState] = useState([[], [], []]);

  const tableColumns = React.useMemo(
    () => [
      { Header: 'Patient Name', accessor: 'name' },
      { Header: 'Item Ordered', accessor: 'item_ordered' },
      { Header: 'Ordered By', accessor: 'ordered_by' },
      { Header: 'Ordered Date', accessor: 'ordered_date' },
      { Header: 'Priority Level', accessor: 'priority_level' },
      { Header: 'Total Amount', accessor: 'total_amount' },
    ],
    [],
  );

  const tableData = React.useMemo(
    () =>
    filteredOrdersData.map((dd) => {

      const getOrderItems = filteredOrderItemsData.find((data) => data?.id === dd?.relationships?.order_items?.data[0]?.id);
      const getItems = filteredItemsData.find((data) => data?.id === getOrderItems?.relationships?.item?.data[0]?.id);

        return {
          data: dd,
          id: dd?.id,
          name: dd?.attributes?.patient_name,
          ordered_by: dd?.attributes?.orderer_name,
          total_amount: dd?.attributes?.total_amount,
          priority_level: dd?.attributes?.priority_level,
          item_ordered: <ul className='table-list'><li>{getItems.attributes.name}</li></ul>,
          ordered_date: moment(dd?.attributes?.created_at).format('MMM-DD-YYYY hh:mm A'),
        };
      }),
    [filteredOrdersData],
  );

  triggerAPI(showProfile, dispatch, patientProfile);

  getDataFromStore(orderState, dispatch, patientProfile, setFilteredOrdersData, setFilteredOrderItemsData, setFilteredItemsData);
/*
  useEffect(() => {
    console.log('Patient Profile', patientProfile);
  }, [patientProfile]);
  
  useEffect(() => {
    console.log('State', state);
  }, [state]);

   useEffect(() => { 
       axios.get(`${process.env.REACT_APP_API_BASE_INVENTORY}/api/orders`).then((res) => { console.log(res); })
   .catch((err) => { console.log(err); });
      }); */

  return (
    <div className='patientProfile__header'>
      <h4>Orders</h4>
      <Row>
        <Col>
          <TableUI>
            <Table
              data={tableData}
              columns={tableColumns}
              pagination={['BOTTOM']}
              logs={orderState?.orderLogs}
              customAction={(id) => {
                const row = tableData.find((dd) => dd.id === id);
                const canDelete = true;

                const buttons = [
                  {
                    text: 'Delete',
                    color: 'red',
                    action: () => {
                      setShowModal((prevState) => {
                        return {
                          ...prevState,
                          data: row,
                          deleteModal: true,
                        };
                      });
                    },
                    condition: canDelete === true,
                  },
                ];

                return (
                  <div className='action-buttons'>
                    {buttons.map((buttonData) => {
                      return (
                        <ConditionalRender
                          condition={buttonData?.condition}
                          renderIf={
                            <span
                              className={`badge ${buttonData?.color}`}
                              style={{ cursor: 'pointer' }}
                              onClick={buttonData?.action}
                            >
                              {buttonData?.text}
                            </span>
                          }
                        />
                      );
                    })}
                  </div>
                );
              }}
            />
          </TableUI>
        </Col>
      </Row>
    </div>
  );
};

const triggerAPI = (showProfile, dispatch, patientProfile) => {
  useEffect(() => {
    if (showProfile?.profileId && patientProfile) {
      const params = {
        per_page: 100000,
        patient_id: showProfile?.profileId,
        patient_visit_number: patientProfile?.active_visit_number,
      };

      dispatch(getOrdersList(params));
    }
  }, [showProfile, patientProfile]);
};

const getDataFromStore = (orderState, dispatch, patientProfile, setFilteredOrdersData, setFilteredOrderItemsData, setFilteredItemsData) => {
  const { orderLogs, orderData, orderItems } = orderState;

  useEffect(() => {
    const { isSuccess, isFailed } = orderLogs;

    if (isSuccess) {
      /*
      const filteredData = orderData.map((data) => {
        return {
          data: data,
          id: data?.id,
          name: data?.attributes?.patient_name,
          patient_id: data?.attributes?.patient_id,
          ordered_by: data?.attributes?.orderer_name,
          ordered_id: data?.attributes?.orderer_id,
          ordered_date: data?.attributes?.created_at,
          priority_level: data?.attributes?.priority_level,
          total_amount: data?.attributes?.total_amount,
        };
      });
      console.log('filtered data', filteredData);
      setState(filteredData);
      */
      axios.get(`${process.env.REACT_APP_API_BASE_INVENTORY}/api/orders`).then((res) => { 
        const filteredOrders = res.data.data.filter((data) => data.attributes.patient_id === patientProfile.id.toString());
        setFilteredOrdersData(filteredOrders);
        const filteredOrdersIds = filteredOrders.map(({ id }) => id);
        const filteredOrderItems = res.data.included.filter((included) => filteredOrdersIds.includes(included.id) && included.type === 'order_items');
        setFilteredOrderItemsData(filteredOrderItems);
        const filteredOrderItemsIds = [];
        filteredOrderItems.map((item) => filteredOrderItemsIds.includes(item.attributes.item_id) ? filteredOrderItemsIds : filteredOrderItemsIds.push(item.attributes.item_id));
        const filteredItems = res.data.included.filter((included) => filteredOrderItemsIds.includes(parseInt(included.id)) && included.type === 'items');
        setFilteredItemsData(filteredItems);
      })
      .catch((err) => { console.log(err); });
    }

    if (isFailed) {
      const { error } = orderLogs;

      toast.error(error[0]?.detail);

      dispatch(resetOrderListState());
    }
  }, [orderLogs]);
};

export default OrdersTable;
