/* eslint-disable no-case-declarations */
/* eslint-disable multiline-comment-style */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
// import core & vendor packages below
import { Row, Col, Accordion, Form, Button } from 'react-bootstrap';

// import components below
import AccordionUI from 'components/UI/AccordionUI';
import FormUI from 'components/UI/FormUI';

// import assets below
import './AdvancedSearch.scss';

import { useSelector } from 'react-redux';
import { selectPatientData } from 'reducers/patientReducer';
import { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

function reverseDateFormat(date) {
  const parts = date.split('-');

  const day = parseInt(parts[1], 10);
  const month = parseInt(parts[2], 10);
  const year = parseInt(parts[0], 10);

  return month + '/' + day + '/' + year;
}
// main component
const AdvancedSearch = ({ setState }) => {

  useEffect(() => {
    axios
      .get('/patient')
      .then((response) => {
        setListofPatients(response.data.data.filter((data) => data.patient_status === 'new' || data.patient_status === 'OPD'));
      }, [])
      .catch((err) => {
        console.log('AXIOS ERROR: ', err);
      });
  }, []);

  const data = useSelector(selectPatientData);

  function changeStatus(e) {
    setSex(e.target.value);
  }

  const [listOfPatients, setListofPatients] = useState([]);
  const [fname, setFname] = useState('');
  const [mname, setMname] = useState('');
  const [lname, setLname] = useState('');
  const [mrn, setMRN] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [sex, setSex] = useState('');
  const [suffix, setSuffix] = useState('');
  const er = useRef();
  const admitted = useRef();

  const resetFilter = () => {
    setFname('');
    setMname('');
    setLname('');
    setMRN('');
    setDateOfBirth(reverseDateFormat(''));
    setSex('');
    setSuffix('');
    setState(listOfPatients);
    // er.current.checked = false;
    // admitted.current.checked = false;
  };

  const dischargeAll = () => {
    axios
      .post('/appointment/discharge_all/')
      .then((response) => {
        toast.success('Successfully dischaged all patients have been discharged');
        axios
          .get('/patient', {
            params: {
              patient_status: ['new', 'for_discharge'],
            },
          })
          .then((response) => {
            setState(response.data.data);
            // setState(response.data.data.filter((data) => data.patient_origin !== 'emergency'));
          }, [])
          .catch((err) => {
            console.log('AXIOS ERROR: ', err);
          });
      }, [])
      .catch((err) => {
        console.log('AXIOS ERROR: ', err);
      });
  };

  const search = () => {
    axios
      .get('/patient/', {
        params: {
          MRN: mrn,
          first_name: fname,
          middle_name: mname,
          last_name: lname,
          suffix: suffix,
          date_of_birth: dateOfBirth,
          sex: sex,
        },
      })
      .then((response) => {
        setState(response.data.data);
      }, [])
      .catch((err) => {
        console.log('AXIOS ERROR: ', err);
      });
  };

  const onChangeHandler = (key) => (e) => {
    let newFilter = [];

    switch (key) {
      case 'fname':
        setFname(e.target.value);
        newFilter = listOfPatients.filter((value) => {
          return value.first_name.toLowerCase().startsWith(e.target.value.toLowerCase());
        });
        break;

      case 'mname':
        setMname(e.target.value);
        newFilter = listOfPatients.filter((value) => {
          return value.middle_name.toLowerCase().startsWith(e.target.value.toLowerCase());
        });
        break;

      case 'lname':
        setLname(e.target.value);
        newFilter = listOfPatients.filter((value) => {
          return value.last_name.toLowerCase().startsWith(e.target.value.toLowerCase());
        });
        break;

      case 'suffix':
        setSuffix(e.target.value);
        newFilter = listOfPatients.filter((value) => {
          return value.suffix.toLowerCase().startsWith(e.target.value.toLowerCase());
        });
        break;

      case 'mrn':
        setMRN(e.target.value);
        newFilter = listOfPatients.filter((value) => {
          return value.MRN.startsWith(e.target.value);
        });
        break;

      case 'bday':
        setDateOfBirth(e.target.value);
        newFilter = listOfPatients.filter((value) => {
          return value.date_of_birth.startsWith(e.target.value);
        });
        break;

      case 'sex':
        setSex(e.target.value);
        newFilter = listOfPatients.filter((value) => {
          return value.sex.startsWith(e.target.value);
        });
        break;
    }

    if (e.target.value.length > 0) {
      setState(newFilter);
    } else {
      setState(listOfPatients);
    }
  };

  return (
    <div className='advancedSearch'>
      <AccordionUI>
        <Accordion defaultActiveKey='0'>
          <Accordion.Item eventKey='0'>
            <Accordion.Header>Advance Search</Accordion.Header>
            <Accordion.Body>
              <FormUI>
                <Row>
                  <Col>
                    <Form.Group className='mb-3'>
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Enter First Name'
                        value={fname}
                        // onChange={(event) => {
                        //   setFname(event.target.value);
                        // }}
                        onChange={onChangeHandler('fname')}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className='mb-3'>
                      <Form.Label>Middle Name</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Enter Middle Name'
                        value={mname}
                        // onChange={(event) => {
                        //   setMname(event.target.value);
                        // }}
                        onChange={onChangeHandler('mname')}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className='mb-3'>
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Enter Last Name'
                        value={lname}
                        // onChange={(event) => {
                        //   setLname(event.target.value);
                        // }}
                        onChange={onChangeHandler('lname')}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className='mb-3'>
                      <Form.Label>Suffix</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Enter Suffix'
                        value={suffix}
                        // onChange={(event) => {
                        //   setSuffix(event.target.value);
                        // }}
                        onChange={onChangeHandler('suffix')}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className='mb-3'>
                      <Form.Label>MRN / Pin</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Enter Here'
                        value={mrn}
                        // onChange={(event) => {
                        //   setMRN(event.target.value);
                        // }}
                        onChange={onChangeHandler('mrn')}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className='mb-3'>
                      <Form.Label>Date of Birth</Form.Label>
                      <Form.Control
                        type='date'
                        value={dateOfBirth}
                        // onChange={(event) => {
                        //   setDateOfBirth(event.target.value);
                        // }}
                        onChange={onChangeHandler('bday')}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className='mb-3'>
                      <Form.Label>Sex</Form.Label>
                      <Form.Select value={sex} onChange={onChangeHandler('sex')}>
                        <option hidden>-Select-</option>
                        <option value='male'>Male</option>
                        <option value='female'>Female</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  {/* <Col>
                    <div className='formCheckWrapper'>
                      <Form.Check
                        className='mb-2'
                        type='checkbox'
                        label='Currently Admitted'
                        ref={admitted}
                      />
                      <Form.Check
                        className='mb-3'
                        type='checkbox'
                        label='Currently an ER Patient'
                        ref={er}
                      />
                    </div>
                  </Col> */}
                </Row>
                <Row>
                  <div className='buttonWrapper'>
                    {/* <Button variant='primary' onClick={dischargeAll}>
                      Discharge All Patient
                    </Button> */}
                    {/* <Button variant='search' onClick={search}>
                      Search
                    </Button> */}
                    <Button variant='clear' onClick={() => resetFilter()}>
                      Clear
                    </Button>
                  </div>
                </Row>
              </FormUI>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </AccordionUI>
    </div>
  );
};

export default AdvancedSearch;
