/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import { Row, Col, Accordion, Form, Button } from 'react-bootstrap';

// import components below
import FormUI from 'components/UI/FormUI';
import AccordionUI from 'components/UI/AccordionUI';
import RoomeTable from './components/RoomTable';

// import assets below
import './style.scss';

import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import RoomTable from './components/RoomTable';
import { redirectBackToPreviousPage } from '../../helpers';
import { useSelector } from 'react-redux';
import { selectPatientItem } from 'reducers/patientReducer';
import Modal from './components/Modal';
import { toast } from 'react-toastify';
import moment from 'moment';

// main component
const FormCreateRoomRequest = () => {

  const [openModal, setOpenModal] = useState(false);

  const patientProfile = useSelector(selectPatientItem);

  const navigate = useNavigate();

  const [roomList, setRoomList] = useState([]);

  const [selectedRoom, setSelectedRoom] = useState({
    selected_room: '',
    floor_number: '',
    floor_price_scheme: '',
    price: '',
    roomId: '1',
  });

  const [state, setState] = useState({
    request_type: '',
    preferred_room_type: '',
    case: '',
    payor_type: '',
    HMO_name: '',
    HMO_id_number: '',
    admission_transfer_date_time: '',
    requesting_unit: '',
    remarks: '',
    patientId: patientProfile.id,
  });

  const [filterState, setFilterState] = useState({
    type: '',
    status: '',
    isReserved: '',
  });

  const resetFilterState = async () => {
    setFilterState({
      type: '',
      status: '',
      isReserved: '',
    });
    setRoomList([]);
  };

  const handleDropdownValue = (event, field) => {
    switch (field) {
      case 'request_type':
        setState({ ...state, request_type: event.target.value });
        break;
      case 'preferred_room_type':
        setState({ ...state, preferred_room_type: event.target.value });
        break;
      case 'payor_type':
        setState({ ...state, payor_type: event.target.value });
        break;
      case 'type':
        setFilterState({ ...filterState, type: event.target.value });
        break;
      case 'status':
        setFilterState({ ...filterState, status: event.target.value });
        break;
      case 'isReserved':
        setFilterState({ ...filterState, isReserved: event.target.value });
        break;
      case 'hmo_name':
        setState({ ...state, HMO_name: event.target.value });
        break;
    }
  };

  const filter = async () => {
    axios.get('/room', {
      params: filterState,
    }).then((response) => {
      setRoomList(response.data.data.room_list);
    }, [])
      .catch((err) => {
        console.log('AXIOS ERROR: ', err);
      });
  };

  const createRoomRequest = async () => {
    const params = { ...state, ...selectedRoom };

    await axios
      .post('/room_request', params)
      .then((response) => {
        if (response.data.error) {
          alert(response.data.error.message);
        } else {
          // setOpenModal(true);
          toast.success('Successfully Created inpatient visit!');
          setTimeout(() => {
            navigate('/dashboard/outpatient-department');
          }, 2000);
        }
      })
      .catch((err) => {
        console.log('AXIOS ERROR: ', err);
      });

  };

  return (
    <div className='formCreateRoomRequest'>
      <FormUI>
        <div className='formCreateRoomRequest__header'>
          <h4>Create Room Request</h4>
          {/* <div>
            <Button className='me-3' variant='save' onClick={createRoomRequest}>
              Create Request
            </Button>
            <Button className='' variant='clear' onClick={redirectBackToPreviousPage}>
              CANCEL
            </Button>
          </div> */}
        </div>
        <div className='formCreateRoomRequest__banner'>
          <div>
            <h2 className='text-uppercase'>
              <span className='patient__lastName fw-bold'>{patientProfile?.last_name}</span>, {patientProfile?.first_name}
            </h2>
            <h5 className='patient__number mt-1'>{patientProfile?.MRN}</h5>
          </div>
          <div className='patient__info mt-1'>
            <span>{patientProfile?.age} / {patientProfile?.sex}</span>
            <span>{moment(patientProfile?.date_of_birth).format('MMMM-DD-YYYY')}</span>
          </div>
        </div>
        <AccordionUI>
          <Accordion defaultActiveKey='0'>
            <Accordion.Item eventKey='0'>
              <Accordion.Header>Room Request Information</Accordion.Header>
              <Accordion.Body>
                <FormUI>
                  <Row>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          Request Type<span className='asterisk'>*</span>
                        </Form.Label>
                        <Form.Select onChange={(event) => handleDropdownValue(event, 'request_type')}>
                          <option value=''>--Select--</option>
                          <option value='Type 1'>Type 1</option>
                          <option value='Type 2'>Type 2</option>
                          <option value='Type 3'>Type 3</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          Preferred Room Type<span className='asterisk'>*</span>
                        </Form.Label>
                        <Form.Select onChange={(event) => handleDropdownValue(event, 'preferred_room_type')}>
                          <option value=''>--Select--</option>
                          <option value='Room Type 1'>Room Type 1</option>
                          <option value='Room Type 2'>Room Type 2</option>
                          <option value='Room Type 3'>Room Type 3</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          Case<span className='asterisk'>*</span>
                        </Form.Label>
                        <Form.Control type='text'
                          placeholder='Enter Here'
                          onChange={(event) => { setState({ ...state, case: event.target.value }); }} />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          Payor Type<span className='asterisk'>*</span>
                        </Form.Label>
                        <Form.Select onChange={(event) => handleDropdownValue(event, 'payor_type')}>
                          <option>-Select-</option>
                          <option value='Self-pay'>Self-pay</option>
                          <option value='Charity'>Charity</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          HMO Name<span className='asterisk'>*</span>
                        </Form.Label>
                        <Form.Select onChange={(event) => handleDropdownValue(event, 'hmo_name')}>
                          <option>-Select-</option>
                          <option value='Philhealth'>Philhealth</option>
                          <option value='Mandaluyong Health Card'>Mandaluyong Health Card</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          HMO ID Number<span className='asterisk'>*</span>
                        </Form.Label>
                        <Form.Control type='text'
                          placeholder='Enter Here'
                          onChange={(event) => { setState({ ...state, HMO_id_number: event.target.value }); }} />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          Admission / Transfer Date and Time<span className='asterisk'>*</span>
                        </Form.Label>
                        <Form.Control type='datetime-local' onChange={(event) => { setState({ ...state, admission_transfer_date_time: event.target.value }); }} />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          Requesting Unit<span className='asterisk'>*</span>
                        </Form.Label>
                        <Form.Control type='text' placeholder='Search' onChange={(event) => { setState({ ...state, requesting_unit: event.target.value }); }} />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          Remarks<span className='asterisk'>*</span>
                        </Form.Label>
                        <Form.Control type='text' placeholder='Enter Here' onChange={(event) => { setState({ ...state, remarks: event.target.value }); }} />
                      </Form.Group>
                    </Col>
                  </Row>
                </FormUI>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Accordion defaultActiveKey='0'>
            <Accordion.Item eventKey='0'>
              <Accordion.Header>Room/Bed Selection</Accordion.Header>
              <Accordion.Body>
                <FormUI>
                  <Row className='d-flex align-items-center'>
                    <Col>
                      <p className='mb-3'>
                        Select a room or bed to reserve for the patient.{' '}
                        <span className='text-danger'>
                          NOTE: If no room or bed has been selected, the patient will be added to
                          the Waiting List.
                        </span>
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>Room Type</Form.Label>
                        <Form.Select value={filterState.type} onChange={(event) => handleDropdownValue(event, 'type')}>
                          <option value=''>-Select-</option>
                          <option value='Special Care'>Special Care</option>
                          <option value='Suite'>Suite</option>
                          <option value='Private'>Private</option>
                          <option value='Semi-Private'>Semi-Private</option>
                          <option value='Ward'>Ward</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>Room Type</Form.Label>
                        <Form.Control type='text' placeholder='Search' />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>Status</Form.Label>
                        <Form.Select value={filterState.status} onChange={(event) => handleDropdownValue(event, 'status')}>
                          <option value=''>-Select-</option>
                          <option value='occupied'>Occupied</option>
                          <option value='available'>Available</option>
                          <option value='cleaning'>Cleaning</option>
                          <option value='unavailable'>Unavailable</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>Reserved</Form.Label>
                        <Form.Select value={filterState.isReserved} onChange={(event) => handleDropdownValue(event, 'isReserved')}>
                          <option value=''>-Select-</option>
                          <option value='yes'>Yes</option>
                          <option value='no'>No</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col>
                      <div className='d-flex align-items-center w-100 h-100 mt-2'>
                        <Button className='me-3' variant='go' onClick={filter}>
                          Go
                        </Button>
                        <Button variant='reset' onClick={resetFilterState}>Reset</Button>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <h5 className='my-3'>Select a Room or Bed</h5>
                    <RoomTable roomList={roomList} setSelectedRoom={setSelectedRoom} />
                  </Row>
                </FormUI>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </AccordionUI>
        <div className='d-flex justify-content-end mt-5'>
          <Button className='me-3' variant='save' onClick={createRoomRequest}>
            Create Request
          </Button>
          <Button className='' variant='clear' onClick={redirectBackToPreviousPage}>
            CANCEL
          </Button>
        </div>
      </FormUI>
      {openModal && <Modal />}
    </div>
  );
};

export default FormCreateRoomRequest;
