/* eslint-disable camelcase */
/* eslint-disable multiline-comment-style */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import { Modal, Button } from 'react-bootstrap';

// import components below
import ModalUI from 'components/UI/ModalUI';

// import assets below
import './ConfirmationModal.scss';
import { ReactComponent as CloseSVG } from 'assets/svg/circle-xmark.svg';
// eslint-disable-next-line no-unused-vars
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { toast } from 'react-toastify';

// main componet
// eslint-disable-next-line no-unused-vars
const ConfirmDeleteModal = ({ showProfile, setOpenModal, setState }) => {

    const [modal, setModal] = useState(false);
    const navigate = useNavigate();

    const closeModal = () => {
        setOpenModal(false);
    };

    const handleDeleteRoom = async () => {
        axios
            .delete(`/room/${showProfile?.id}`)
            .then((response) => {
                toast.warning('Room has been deleted!');
                setOpenModal(false);
                axios
                    .get('/room')
                    .then((response) => {
                        setState(response.data.data.room_list);
                    });
            })
            .catch((error) => {
                console.log(error);

            });
    };

    return (
        <div className='loginModal'>
            <ModalUI>
                <Modal.Dialog>
                    <Modal.Header id='confirmation-modal-header'>
                        <h6>CONFIRMATION</h6>
                        <Button onClick={closeModal}>
                            <CloseSVG />
                        </Button>
                    </Modal.Header>
                    <Modal.Body>

                        <p>
                            A room will be deleted
                        </p>
                        <p>
                            Are you sure you want to continue?
                        </p>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button className='loginModal__button' onClick={handleDeleteRoom}>
                            CONFIRM DELETE
                        </Button>
                    </Modal.Footer>
                </Modal.Dialog>
            </ModalUI>
        </div>
    );
};

export default ConfirmDeleteModal;
