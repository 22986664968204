/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
// import core & vendor packages below
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { Row, Col, Form, Button, FormControl, InputGroup } from 'react-bootstrap';

// import components below
import FormUI from 'components/UI/FormUI';
import { getAppointment } from 'reducers/appointmentReducer';
import { ReactComponent as MagnifyingGlassSVG } from 'assets/svg/magnifying_glass.svg';

// import assets below
import './Filter.scss';

// main component
const AppointmentListFilter = () => {
  const dispatch = useDispatch();

  const [departments, setDepartments] = useState([]);
  const [filter, setFilter] = useState({
    full_name: '',
    department: '',
    services_type: '',
    name_of_doctor: '',
    appointment_date: '',
  });

  const resetFilter = () => {
    setFilter({
      full_name: '',
      department: '',
      services_type: '',
      name_of_doctor: '',
      appointment_date: '',
    });

    refresh();
  };

  const refresh = () => {
    const params = {
      full_name: '',
      department: '',
      services_type: '',
      name_of_doctor: '',
      appointment_date: '',
    };

    dispatch(getAppointment(params));
  };

  const search = () => {
    const params = {
      full_name: filter?.full_name,
      department: filter?.department,
      services_type: filter?.services_type,
      name_of_doctor: filter?.name_of_doctor,
      appointment_date: filter?.appointment_date,
    };

    dispatch(getAppointment(params));
  };

  useEffect(() => {
    axios
      .get('/public/department')
      .then((response) => {
        setDepartments(response.data);
      }, [])
      .catch((err) => {
        console.log('AXIOS ERROR: ', err);
      });

    search();
  }, []);

  return (
    <div className='appointmentListFilter'>
      <FormUI>
        <Row className='d-flex align-items-end'>
          <Col md={4}>
            <Form.Group className='mb-3'>
              <Form.Label>Appointment Date</Form.Label>
              <Form.Control
                type='date'
                value={filter?.appointment_date}
                onChange={(event) => {
                  setFilter((prev) => ({ ...prev, appointment_date: event.target.value }));
                }}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className='mb-3'>
              <Form.Label>Doctor / Resource</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter Here'
                value={filter?.name_of_doctor}
                onChange={(event) => {
                  setFilter((prev) => ({ ...prev, name_of_doctor: event.target.value }));
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className='mb-3'>
              <Form.Label>Select Department</Form.Label>
              <Form.Select
                value={filter?.department}
                onChange={(event) => {
                  setFilter((prev) => ({ ...prev, department: event.target.value }));
                }}
              >
                <option disabled value=''>
                  -Select-
                </option>
                {departments &&
                  departments.length > 0 &&
                  departments.map((item, key) => {
                    return <option value={item.name}>{item.name}</option>;
                  })}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className='mb-3'>
              <Form.Label>Services</Form.Label>
              <Form.Select
                value={filter?.services_type}
                onChange={(event) => {
                  setFilter((prev) => ({ ...prev, services_type: event.target.value }));
                }}
              >
                <option value=''>- Select -</option>
                <option value={'Clinic Consultation'}>Clinic Consultation</option>
                <option value={'Clinic Packages'}>Clinic Packages</option>
                <option value={'Clinic Teleconsultation'}>Clinic Teleconsultation</option>
              </Form.Select>
            </Form.Group>
          </Col>

          <Col>
            <Form.Group className='mb-3'>
              <Form.Label>Search Patient</Form.Label>
              <InputGroup>
                <InputGroup.Text className='bg-white'>
                  <MagnifyingGlassSVG />
                </InputGroup.Text>
                <FormControl
                  type='search'
                  className='bg-white'
                  value={filter?.full_name}
                  style={{ maxWidth: 'unset' }}
                  placeholder='Search Patient Name'
                  onChange={(event) => {
                    setFilter((prev) => ({ ...prev, full_name: event.target.value }));
                  }}
                />
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <div className='buttonWrapper'>
            <Button variant='search' onClick={() => search()}>
              SEARCH
            </Button>
            <br />
            <Button variant='clear' onClick={resetFilter}>
              RESET
            </Button>
          </div>
        </Row>
      </FormUI>
    </div>
  );
};

export default AppointmentListFilter;
