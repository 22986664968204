/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// Packages
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';

// components below
import AddDoctorTable from './Table';
import FormUI from 'components/UI/FormUI';
import AddDoctorProfile from './ViewStatus';
import AddDoctorStatus from './AddDoctorStatus';
import AddDoctorToPatient from './AddDoctorToPatient';
import ConditionalRender from 'components/ConditionalRender';
import { checkStringPermission } from 'helpers/filteredPermissions';

// CSS
import './style.scss';
import { ReactComponent as MagnifyingGlassSVG } from 'assets/svg/magnifying_glass.svg';

// main component
const AddDoctor = () => {
  const [state, setState] = useState([]);
  const [showModal, setShowModal] = useState({
    isStatusShow: false,
    isAddDoctor: false,
    profileData: null,
    profileId: null,
    doctorId: null,
  });
  const [userData, setUserData] = useState({
    search_physician: '',
    specialty_subspecialty: '',
    status: '',
  });

  const [doctors, setDoctors] = useState([]);
  const [searchedWord, setSearchedWord] = useState('');
  const [status, setStatus] = useState('');

  /** filter by doctor's name */
  const onChangeHandler = (event) => {
    setSearchedWord(event.target.value);
    const searchedWord = event.target.value;
    const newFilter = doctors.filter((value) => {
      return value.attributes?.full_name.toLowerCase().includes(searchedWord.toLowerCase());
    });

    if (searchedWord.length > 0) {
      setState(newFilter);
    } else {
      setState(doctors);
    }
  };

  const onChangeStatusHandler = (event) => {
    const searchedWord = event.target.value;
    setStatus(searchedWord);
    const newFilter = doctors.filter((value) => {
      return value.attributes?.employment?.employment_status
        .toLowerCase()
        .includes(searchedWord.toLowerCase());
    });

    if (searchedWord.length > 0) {
      setState(newFilter);
    } else {
      setState(doctors);
    }
  };

  const handleChange = (key) => (e) => {
    setUserData({
      ...userData,
      [key]: e.target.value,
    });
  };

  const resetFilter = () => {
    setState(doctors);
    setSearchedWord('');
    setStatus('');
    setUserData({
      search_physician: '',
      specialty_subspecialty: '',
      status: '',
    });
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_ACL}/api/users?roles[]=Doctor`)
      .then((response) => {
        setDoctors(response.data.data);
      }, [])
      .catch((err) => {
        console.log('AXIOS ERROR: ', err);
      });
  }, []);

  return (
    <>
      <FormUI>
        {!showModal.isStatusShow ? (
          <div className='add-room-cont mt-4'>
            <Form>
              <Row className='mb-4'>
                <Col span={3}>
                  <Form.Group className='basic-search'>
                    <Form.Label>Search Physician</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='Enter Here'
                      onChange={onChangeHandler}
                      value={searchedWord}
                    />
                  </Form.Group>
                </Col>
                {/*                    <Col>
                      <Form.Group className=''>
                        <Form.Label>Specialty / Subspecialty</Form.Label>
                        <Form.Select
                          aria-label='Doctor'
                          value={userData.specialty_subspecialty}
                          onChange={handleChange('specialty_subspecialty')}
                        >
                          <option value='' hidden>
                            - Select -
                          </option>
                          <option value='1'>One</option>
                          <option value='2'>Two</option>
                          <option value='3'>Three</option>
                        </Form.Select>
                      </Form.Group>
                    </Col> */}
                {/* TODO: hide for now , error when null */}
                {/* <Col>
                      <Form.Group className=''>
                        <Form.Label>Status</Form.Label>
                        <Form.Select
                          aria-label='Location'
                          value={status}
                          onChange={onChangeStatusHandler}
                        >
                          <option value='' hidden>
                            - Select -
                          </option>
                          <option value='active'>Active</option>
                          <option value='inActive'>Inactive</option>
                        </Form.Select>
                      </Form.Group>
                    </Col> */}
                <Col>
                  <Form.Group className='category-buttons'>
                    {/* <Button
                          onClick={() => {
                            // Update Data on Table?
                          }}
                          className='add-button'
                          variant='primary'
                          type='submit'
                        >
                          Go
                        </Button> */}
                    <Button onClick={resetFilter} className='reset-button' variant='secondary'>
                      Reset
                    </Button>
                  </Form.Group>
                </Col>
              </Row>
            </Form>

            <div>
              <h2 className='mb-3'>Doctor List</h2>

              {/* Table */}
              <AddDoctorTable setShowModal={setShowModal} state={state} setState={setState} />
            </div>
          </div>
        ) : (
          <AddDoctorStatus />
        )}
      </FormUI>

      <AddDoctorToPatient showModal={showModal} setShowModal={setShowModal} />
    </>
  );
};

export default AddDoctor;
