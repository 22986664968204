// import core & vendor packages
import { useState } from 'react';
import PropTypes from 'prop-types';

// main components below
import EditRoom from './components/EditRoom';
import CreateRoom from './components/CreateRoom';
import PatientRoomTable from './components/Table';
import SearchRoomFilter from './components/Filter';
import FilterCardList from './components/CardList';
import ConditionalRender from 'components/ConditionalRender';

// main component
const SearchRoom = ({ handleTabOnChange }) => {
  const [state, setState] = useState([]);
  const [showProfile, setShowProfile] = useState({
    isDefault: true,
    createRoom: false,
    editRoom: false,
    editValues: null,
    id: null,
  });

  const isDefault = showProfile.isDefault === true;
  const isCreateRoom = showProfile.createRoom === true;
  const isEditRoom = showProfile.editRoom === true;

  return (
    <div>
      <ConditionalRender
        condition={isDefault}
        renderIf={
          <div className='searchRoom'>
            <FilterCardList state={state} setState={setState} />

            <SearchRoomFilter
              setState={setState}
              setShowProfile={setShowProfile}
              handleTabOnChange={handleTabOnChange}
            />

            <PatientRoomTable state={state} setState={setState} setShowProfile={setShowProfile} showProfile={showProfile} />
          </div>
        }
      />

      <ConditionalRender
        condition={isCreateRoom}
        renderIf={
          <CreateRoom
            setState={setState}
            showProfile={showProfile}
            setShowProfile={setShowProfile}
          />
        }
      />

      <ConditionalRender
        condition={isEditRoom}
        renderIf={
          <EditRoom setState={setState} showProfile={showProfile} setShowProfile={setShowProfile} />
        }
      />
    </div>
  );
};

SearchRoom.defaultProps = {
  handleTabOnChange: () => { },
};

SearchRoom.propTypes = {
  handleTabOnChange: PropTypes.func,
};

export default SearchRoom;
