/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// Packages
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import React, { useState, useRef } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { useReactToPrint } from 'react-to-print';
import FileSaver from 'file-saver';

// Components
import FormUI from 'components/UI/FormUI';

import NetIncreaseList from './components/NetIncreaseList';
import ActivitiesList from './components/ActivitiesList';
import { PrintContent } from './components/PrintContent';

// CSS
import './index.scss';

import { searchCashFlow, selectReportCashFlow } from 'reducers/cashFlowReducer';
import { toast } from 'react-toastify';

// Main Component
const CashFlowStatement = () => {
  const printRef = useRef();
  const dispatch = useDispatch();
  const [isDate, setIsDate] = useState('');
  const [searchData, setSearchData] = useState({
    search: '',
  });

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const reportCashFlow = useSelector(selectReportCashFlow);

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setIsDate(value);

    setSearchData((prev) => {
      return {
        ...prev,
        search: value,
      };
    });

    const params = {
      search: value,
    };

    dispatch(searchCashFlow(params));
    console.log('cashflow records: ', reportCashFlow);
  };

  const downloadExcelFile = async () => {
    if (!reportCashFlow[0]?.length && !reportCashFlow[1]?.length) {
      toast.warn('Unable to export due to no record saved on this date.');
      return;
    }

    const API_URL = process.env.REACT_APP_API_BASE_URL;
    try {
      const response = await axios({
        url: `${API_URL}/cash_flow/downloadExcel?search=${isDate}`,
        responseType: 'blob',
        method: 'GET',
      });

      FileSaver.saveAs(response?.data, `cash_flow_${isDate}.xlsx`);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  return (
    <>
      <div style={{ display: 'none' }}>
        <PrintContent ref={printRef} reportCashFlow={reportCashFlow} date={isDate} />
      </div>

      <div className='cash-flow-statement'>
        <FormUI>
          <Form>
            <div className='cash-flow-details'>
              <Row className='mb-4'>
                <Col sm={3}>
                  <Form.Group className='inline-label'>
                    <Form.Label className='px-1'>Date</Form.Label>
                    <Form.Control
                      value={searchData?.search}
                      type='date'
                      onChange={handleSearchChange}
                      placeholder='Enter Here'
                    />
                  </Form.Group>
                </Col>

                <Col className='form-buttons'>
                  <Button className='print' onClick={handlePrint} disabled={!isDate}>
                    Print
                  </Button>
                  <Button className='export' onClick={downloadExcelFile} disabled={!isDate}>Export</Button>
                </Col>
              </Row>

              <h5 className='mt-2 mb-4'>Assets List</h5>
              {isDate && (
                <Row className='mb-4'>
                  <Col>
                    {reportCashFlow.length > 0 && (
                      <>
                        <NetIncreaseList data={reportCashFlow[0]} />
                        <ActivitiesList data={reportCashFlow[1]} />
                      </>
                    )}
                  </Col>

                  <Col></Col>
                </Row>
              )}

              <hr />

              {/* <Row className='mt-4 mb-2'>
              <Col className='total-cont'>
                <p>
                  Net Profit: <span>Php 1,000,000.00</span>
                </p>
              </Col>
            </Row> */}
            </div>
          </Form>
        </FormUI>
      </div>
    </>
  );
};

export default CashFlowStatement;
