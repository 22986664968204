//  import core & vendor packages below
import React, { forwardRef, useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';

// import assets below
import './index.scss';
import logo from 'assets/images/strh.png';

// main component
// eslint-disable-next-line react/display-name
export const PrintSubpoenaDucesTecum = forwardRef(({ data }, ref) => {
  const { REACT_APP_HOSPITAL_NAME, REACT_APP_HOSPITAL_STREET_ADDRESS } = process.env;
  const [formData, setFormData] = useState({});
  useEffect(() => {
    if (data) {
      setFormData(data);
    }
  }, [data]);
  return (
    <div id='print_subpoena-duces-tecum' ref={ref}>
      <div className='header'>
        <img src={logo} alt='print_logo' />
        <h1>{REACT_APP_HOSPITAL_NAME}</h1>
        <h3>{REACT_APP_HOSPITAL_STREET_ADDRESS}</h3>
      </div>
      <div>
        <div className='titleFlex'>
          <div className='titleFlex'>
            <Form.Label>{formData?.name_of_hospital}</Form.Label>
            <Form.Label>Name of Healthcare Facility</Form.Label>
          </div>
          <div className='titleFlex'>
            <Form.Label>{formData?.address}</Form.Label>
            <Form.Label>Address</Form.Label>
          </div>
        </div>

        <Row className='mb-4'>
          <Col>
            <div className='startFlex'>
              <Form.Label>Date</Form.Label> <Form.Label>{formData?.date}</Form.Label>
            </div>

            <div className='startFlex'>
              <Form.Label>HON.</Form.Label> <Form.Label>{formData?.HON}</Form.Label>
            </div>

            <Form.Group className='left-input-group'>
              <Form.Label>Presiding Judge</Form.Label>

              <div className='startFlex'>
                <Form.Label>Regional Court, Branch</Form.Label>{' '}
                <Form.Label>{formData?.regional_court_branch}</Form.Label>
              </div>

              <Form.Label>National Capital Judicial Region, Manila</Form.Label>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className='startFlex'>
              <p>Dear</p>
              <p>{formData?.dear}</p>
            </div>
          </Col>
        </Row>

        <Row className='mb-2'>
          <Col>
            <p className='mb-4'>
              This has reference with the Honorable Court&apos;s Subpoena Duces Tecum dated
             <Form.Label>{formData?.dated}</Form.Label>, in the case of People vs {' '}
              <Form.Label>{formData?.vs}</Form.Label>, {' '}
              <Form.Label>{formData?.case_no}</Form.Label>{' '}<Form.Label>{formData?.year}</Form.Label>
            </p>

            <p className='mb-4'>
              We deeply regret that due to reason beyond our control, we will not be to present the
              Subpoenaed document on the said date. May we request the honorable court to reset or
              re-schedule the date for us to have ample time to locate the needed medical record.
            </p>

            <p className='mb-4'>
              Praying that this explanation merit your very kind consideration and understanding.
              Thank you.
            </p>

            <div className='signature mb-4 align-items-start'>
              <p>Respectfully yours,</p>
              <Form.Label>{formData?.chief_of_hospital}</Form.Label>
              <Form.Label>Chief of Hospital</Form.Label>
              <p><b>Noted By</b></p>
              <Form.Label>{formData?.himd_supervisor}</Form.Label>
              <Form.Label>COH</Form.Label>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
});
