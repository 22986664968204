/* eslint-disable camelcase */
/* eslint-disable multiline-comment-style */
/* eslint-disable no-unused-vars */
//  import core & vendor packages below
import axios from 'axios';
import PropTypes from 'prop-types';
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';
import { useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Modal, Row, Col, Button, Form, InputGroup, FormControl, Table } from 'react-bootstrap';

// import components below
import TableUI from 'components/UI/TableUI';

// Printing Forms
import PrintMedicalCert from './PrintComponents/MedicalCertificate';
import PrintCertificateOfConfinement from './PrintComponents/Confinement';
import PrintMedicoLegalCertificate from './PrintComponents/MedicoLegalCertificate';
import { PrintSubpoenaAdTestificandum } from './PrintComponents/PrintSubpoenaAdTestificandum';
import { PrintSubpoenaDucesTecum } from './PrintComponents/PrintSubpoenaDucesTecum';
import PrintContent from './PrintComponents/HistoryAndPhysicalExamination';
import PrintMedicalRecords from './PrintComponents/PrintMedicalRecords';
import MedicalRecordWrapper from './PrintContent';
import PrintLiveBirthForm from './PrintComponents/LiveBirthForm';
import PrintOutpatientRecord from './PrintComponents/OutPatientRecord';
import PrintCertificateOfFatalDeath from './PrintComponents/CertificateOfFetalDeath';

// import assets below
import './index.scss';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';
import { ReactComponent as MagnifyingGlassSVG } from 'assets/svg/magnifying_glass.svg';
// import PrintContent from './PrintComponents/MedicalCertificate';

// main component
const FormModal = ({ setViewForm, tableData, show, setShow, setShowScreen, setTableData }) => {
  const handleShow = () => setShow(false);

  const medicalCertRef = useRef(null);
  const medicoLegalCertificate = useRef(null);
  const certificateOfConfinementRef = useRef(null);
  const subpeonaAdTestificandum = useRef(null);
  const subpoenaDucesTecum = useRef(null);
  const historyAndPhysicalExamination = useRef(null);
  const medicalRecord = useRef(null);
  const liveBirthForm = useRef(null);
  const outpatientRecord = useRef(null);
  const certificateOfFetalDeath = useRef(null);
  
  const [formData, setFormData] = useState({});

  const [state, setState] = useState([]);
  const [keyword, setKeyword] = useState([]);

  if (!tableData) {
    return;
  }

  const handlePrintCertificateOfConfinement = useReactToPrint({
    content: () => certificateOfConfinementRef.current,
    onBeforePrint: () => console.log('before printing'),
  });

  const handlePrintMedCert = useReactToPrint({
    content: () => medicalCertRef.current,
    onBeforePrint: () => console.log('before printing'),
  });

  const handlePrintMedicoLegalCertificate = useReactToPrint({
    content: () => medicoLegalCertificate.current,
    onBeforePrint: () => console.log('before printing'),
  });

  const handlePrintSubpoenaAdTestificandum = useReactToPrint({
    content: () => subpeonaAdTestificandum.current,
    onBeforePrint: () => console.log('before printing'),
  });

  const handlePrintSubpoenaDecusTecum = useReactToPrint({
    content: () => subpoenaDucesTecum.current,
    onBeforePrint: () => console.log('before printing'),
  });

  const handlePrintHistoryAndPhysicalExamination = useReactToPrint({
    content: () => historyAndPhysicalExamination.current,
    onBeforePrint: () => console.log('before printing'),
  });

  const handlePrintMedicalRecord = useReactToPrint({
    content: () => medicalRecord.current,
    onBeforePrint: () => console.log('before printing'),
  });

  const handlePrintLiveBirthForm = useReactToPrint({
    content: () => liveBirthForm.current,
    onBeforePrint: () => console.log('before printing'),
  });

  const handlePrintOutpatientRecord = useReactToPrint({
    content: () => outpatientRecord.current,
    onBeforePrint: () => console.log('before printing'),
  });

  const handlePrintCertificateOfFatalDeath = useReactToPrint({
    content: () => certificateOfFetalDeath.current,
    onBeforePrint: () => console.log('before printing'),
  });
  
  const handleDownloadCertificate = (data) => {
    console.log('data', data);

    switch (data.type) {
      case 'certificate_of_confinement':
        axios
          .get(`${process.env.REACT_APP_API_BASE_URL}/medical_records/form/${data?.id}`)
          .then((response) => {
            toast.info('Preparing data');
            console.log('r', response.data?.data?.[0]);
            setFormData({
              ...response.data?.data?.[0],
              type: data?.type,
              test: 'a23',
            });
          })
          .then(() => {
            setTimeout(() => {
              handlePrintCertificateOfConfinement();
            }, [3000]);
          })
          .catch((error) => console.log('error fetching record info: ', error));
        break;

      case 'medical_cert':
        axios
          .get(`${process.env.REACT_APP_API_BASE_URL}/medical_records/form/${data?.id}`)
          .then((response) => {
            toast.info('Preparing data');
            console.log('re', response.data.data);
            setFormData({
              type: data?.type,
              name_of_hospital: response?.data?.data?.mr_medical_cert?.name_of_hospital,
              address: response?.data?.data?.mr_medical_cert?.address,
              cert_no: response?.data?.data?.mr_medical_cert?.cert_no,
              health_record_no: response?.data?.data?.mr_medical_cert?.health_record_no,
              date: response?.data?.data?.mr_medical_cert?.date,
              full_name: response?.data?.data?.mr_medical_cert?.full_name,
              age: response?.data?.data?.mr_medical_cert?.age,
              confined_start_date: response?.data?.data?.mr_medical_cert?.confined_start_date,
              confined_end_date: response?.data?.data?.mr_medical_cert?.confined_end_date,
              name_of_person_requesting:
                response?.data?.data?.mr_medical_cert?.name_of_person_requesting,
              attending_physician: response?.data?.data?.mr_medical_cert?.attending_physician,
              purpose: response?.data?.data?.mr_medical_cert?.purpose,
            });
          })
          .then(() => {
            setTimeout(() => {
              handlePrintMedCert();
            }, [3000]);
          })
          .catch((error) => console.log('error fetching record info: ', error));
        break;

      case 'medico_legal_cert':
        axios
          .get(`${process.env.REACT_APP_API_BASE_URL}/medical_records/form/${data?.id}`)
          .then((response) => {
            toast.info('Preparing data');
            setFormData({
              ...response.data.data.mr_medico_legal_cert,
              type: data?.type,
              test: 'a24',
            });
          })
          .then(() => {
            setTimeout(() => {
              handlePrintMedicoLegalCertificate();
            }, [3000]);
          })
          .catch((error) => console.log('error fetching record info: ', error));
        break;
        
        case 'subpoena_ad_testificandum':
        axios
          .get(`${process.env.REACT_APP_API_BASE_URL}/medical_records/form/${data?.id}`)
          .then((response) => {
            toast.info('Preparing data');
            setFormData({
              ...response.data.data.mr_subpoena_ad_testificandum,
              type: data?.type,
              test: 'a25',
            });
          })
          .then(() => {
            setTimeout(() => {
              handlePrintSubpoenaAdTestificandum();
            }, [3000]);
          })
          .catch((error) => console.log('error fetching record info: ', error));
        break;
        
        case 'subpoena_duces_tecum':
        axios
          .get(`${process.env.REACT_APP_API_BASE_URL}/medical_records/form/${data?.id}`)
          .then((response) => {
            toast.info('Preparing data');
            setFormData({
              ...response.data.data.mr_subpoena_duces_tecum,
              type: data?.type,
              test: 'a26',
            });
          })
          .then(() => {
            setTimeout(() => {
              handlePrintSubpoenaDecusTecum();
            }, [3000]);
          })
          .catch((error) => console.log('error fetching record info: ', error));
        break;

        case 'history_and_physical_examination':
        axios
          .get(`${process.env.REACT_APP_API_BASE_URL}/medical_records/form/${data?.id}`)
          .then((response) => {
            toast.info('Preparing data');
            console.log(response);
            setFormData({
              ...response.data.data.mr_history_and_physical_exam,
              type: data?.type,
              test: 'a27',
            });
          })
          .then(() => {
            setTimeout(() => {
              handlePrintHistoryAndPhysicalExamination();
            }, [3000]);
          })
          .catch((error) => console.log('error fetching record info: ', error));
        break;
        
        case 'medical_record':
        axios
          .get(`${process.env.REACT_APP_API_BASE_URL}/medical_records/form/${data?.id}`)
          .then((response) => {
            toast.info('Preparing data');
            console.log(response);
            setFormData({
              ...response.data.data.mr_medical_record,
              type: data?.type,
              test: 'a28',
            });
          })
          .then(() => {
            setTimeout(() => {
              handlePrintMedicalRecord();
            }, [3000]);
          })
          .catch((error) => console.log('error fetching record info: ', error));
        break;
        
        case 'live_birth_form':
        axios
          .get(`${process.env.REACT_APP_API_BASE_URL}/medical_records/form/${data?.id}`)
          .then((response) => {
            toast.info('Preparing data');
            console.log(response);
            setFormData({
              ...response.data.data.mr_live_birth_form,
              type: data?.type,
              test: 'a29',
            });
          })
          .then(() => {
            setTimeout(() => {
              handlePrintLiveBirthForm();
            }, [3000]);
          })
          .catch((error) => console.log('error fetching record info: ', error));
        break;

        case 'outpatient_record':
        axios
          .get(`${process.env.REACT_APP_API_BASE_URL}/medical_records/form/${data?.id}`)
          .then((response) => {
            toast.info('Preparing data');
            console.log(response);
            setFormData({
              ...response.data.data.mr_outpatient_record,
              type: data?.type,
              test: 'a30',
            });
          })
          .then(() => {
            setTimeout(() => {
              handlePrintOutpatientRecord();
            }, [3000]);
          })
          .catch((error) => console.log('error fetching record info: ', error));
        break;

        case 'cert_of_fetal_death':
        axios
          .get(`${process.env.REACT_APP_API_BASE_URL}/medical_records/form/${data?.id}`)
          .then((response) => {
            toast.info('Preparing data');
            console.log(response);
            setFormData({
              ...response.data.data.mr_cert_of_fetal_death,
              type: data?.type,
              test: 'a31',
            });
          })
          .then(() => {
            setTimeout(() => {
              handlePrintCertificateOfFatalDeath();
            }, [3000]);
          })
          .catch((error) => console.log('error fetching record info: ', error));
        break;

      default:
        toast.info('Print is now yet working');
        break;
    }
  };

  const downloadCertificate = async (id) => {
    toast.info('Printing Record');

    axios
      .post(
        '/medical_records/print/' + id,
        {},
        {
          responseType: 'blob',
        },
      )
      .then((response) => {
        const filename = response.headers['content-disposition']
          .split('filename=')[1]
          .split(';')[0];

        saveAs(response.data, filename);
      }, [])
      .catch((err) => {
        console.log(err);
        toast.error('Error printing record');
      });
  };

  const onChangeHandler = (event) => {
    const searchedWord = event.target.value;
    setKeyword(searchedWord);

    let newFilter = [];

    if (!isNaN(+searchedWord)) {
      // if input is number
      newFilter = tableData?.tableData?.filter((value) => {
        return value?.patientId === parseInt(searchedWord);
      });
    } else {
      newFilter = tableData?.tableData?.filter((value) => {
        return value?.patient?.full_name.toLowerCase().startsWith(searchedWord.toLowerCase());
      });
    }

    if (newFilter.length > 0) {
      setState(newFilter);
    } else {
      setState([]);
    }
  };

  return (
    <Modal show={show} size='lg' className='form-modal'>
      <Modal.Body>
        <div className='form-modal-header'>
          <div className='d-flex header'>
            <p>{tableData?.type}</p>
            <CircleXMark style={{ width: '2em', cursor: 'pointer' }} onClick={handleShow} />
          </div>
          <div style={{ display: 'none' }}>
            <PrintCertificateOfConfinement
              data={formData}
              ref={certificateOfConfinementRef}
              viewFormType={'Certificate of Confinement'}
            />
            <PrintMedicalCert
              data={formData}
              ref={medicalCertRef}
              viewFormType={'Medical Certificate'}
            />
            <PrintMedicoLegalCertificate
              data={formData}
              ref={medicoLegalCertificate}
              viewFormType={'Medico-Legal Certificate'}
            />
            <PrintSubpoenaAdTestificandum
              data={formData}
              ref={subpeonaAdTestificandum}
            />
            <PrintSubpoenaDucesTecum
              data={formData}
              ref={subpoenaDucesTecum}
            />
            <PrintContent
              data={formData}
              ref={historyAndPhysicalExamination}
              viewFormType={'HISTORY AND PHYSICAL EXAMINATION'}
            />
            <MedicalRecordWrapper ref={medicalRecord} viewFormType={'MEDICAL RECORDS'}>
              <PrintMedicalRecords data={formData} viewFormType={'MEDICAL RECORDS'} />
            </MedicalRecordWrapper>
            <PrintLiveBirthForm
              data={formData}
              ref={liveBirthForm}
              viewFormType={'CERTIFICATE LIVE BIRTH'}
            />
            <PrintOutpatientRecord
              data={formData}
              ref={outpatientRecord}
              viewFormType={'OUTPATIENT RECORDS'}
            />
            <PrintCertificateOfFatalDeath
              data={formData}
              ref={certificateOfFetalDeath}
              viewFormType={'CERTIFICATE OF FETAL DEATH'}
            />
          </div>
        </div>

        <div className='form'>
          <Row>
            <Col className='header-search'>
              <p>{`List of Records as of ${tableData?.date}`}</p>

              <Form.Group className='mb-2 basic-search'>
                <InputGroup>
                  <InputGroup.Text className='bg-white'>
                    <MagnifyingGlassSVG />
                  </InputGroup.Text>
                  <FormControl
                    type='search'
                    className='bg-white'
                    placeholder='Patient ID / Name'
                    onChange={onChangeHandler}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>

          <Row className='form-table-modal'>
            <Col>
              <TableUI>
                <Table responsive>
                  <thead>
                    <tr>
                      <th>Patient ID</th>
                      <th>Patient Name</th>
                      <th>Record Form Id</th>
                      <th>Record</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {keyword?.length > 0
                      ? state?.map((data) => {
                          return (
                            <tr key={data.id}>
                              <td>{data.patientId}</td>
                              <td>{data?.patient?.full_name ?? ''}</td>
                              <td>{data?.id}</td>
                              <td>{data?.name}</td>
                              <td className='table-buttons'>
                                <Button
                                  onClick={() => {
                                    setShow(false);
                                    setShowScreen(1);
                                    setViewForm({
                                      data: data,
                                      id: data?.id,
                                      type: tableData?.type,
                                      patientID: data?.patientId,
                                    });
                                  }}
                                  className='view'
                                >
                                  View
                                </Button>
                                {/* <Button className='download' onClick={() => downloadCertificate(data.id)}>Download Certificate</Button> */}
                                <Button
                                  className='download'
                                  onClick={() => handleDownloadCertificate(data)}
                                >
                                  Download Certificate
                                </Button>
                              </td>
                            </tr>
                          );
                        })
                      : tableData?.tableData?.map((data) => {
                          return (
                            <tr key={data.id}>
                              <td>{data.patientId}</td>
                              <td>{data?.patient?.full_name ?? ''}</td>
                              <td>{data?.id}</td>
                              <td>{data?.name}</td>
                              <td className='table-buttons'>
                                <Button
                                  onClick={() => {
                                    setShow(false);
                                    setShowScreen(1);
                                    setViewForm({
                                      data: data,
                                      id: data?.id,
                                      type: tableData?.type,
                                      patientID: data?.patientId,
                                    });
                                  }}
                                  className='view'
                                >
                                  View
                                </Button>
                                {/* <Button className='download' onClick={() => downloadCertificate(data.id)}>Download Certificate</Button> */}
                                <Button
                                  className='download'
                                  onClick={() => handleDownloadCertificate(data)}
                                >
                                  Download Certificate
                                </Button>
                              </td>
                            </tr>
                          );
                        })}
                  </tbody>
                </Table>
              </TableUI>
            </Col>
          </Row>

          <Row className='mt-4'>
            <Col className='buttons-cont'>
              <Button onClick={handleShow} className='close'>
                Close
              </Button>
            </Col>
          </Row>
        </div>
      </Modal.Body>
    </Modal>
  );
};

FormModal.defaultProps = {
  show: false,
  setShow: () => {},
  tableData: {},
  setViewForm: () => {},
};

FormModal.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
  tableData: PropTypes.object,
  setViewForm: PropTypes.func,
};

export default FormModal;
