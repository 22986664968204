/* eslint-disable */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable camelcase */
/* eslint-disable arrow-parens */
// import core & vendor packages below
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Table from 'components/ReactTable';
import axios from 'axios';

// import components below
import { selectPatientData } from 'reducers/patientReducer';

// import assets below
import './Table.scss';

// main component
const LabResultTable = ({
  showModal,
  setShowModal,
  state,
  setState,
  setPatientData,
  searchPatientLabData,
}) => {
    const searchList = searchPatientLabData(state);

  const data = useSelector(selectPatientData);
  const [formData, setFormData] = useState();
  /* useEffect(() => {
    axios
      .get('/patient')
      .then((response) => {
        setState(response.data.data);
        setState(response.data.data.filter((data) => data.laboratory_requests.length > 0));
      }, [])
      .catch((err) => {
        console.log('AXIOS ERROR: ', err);
      });
  }, []); */

  const tableColumns = React.useMemo(
    () => [
      { Header: 'ID', accessor: 'id' }, // accessor is the "key" in the data
      { Header: 'Patient MRN/PIN', accessor: 'mrn' },
      { Header: 'Patient Name', accessor: 'name' },
      { Header: 'Doctor', accessor: 'doctor' },
      // { Header: 'Location', accessor: 'location' },
    ],
    [],
  );

  const tableData = React.useMemo(
    () =>
      searchList?.map((dd) => ({
        id: dd?.id,
        mrn: dd?.patient?.MRN,
        name: dd?.patient?.full_name,
        doctor: dd?.user?.full_name ? dd?.user?.full_name : '_ _',
        location: dd?.patient?.reference_location ? dd?.patient?.reference_location : `_ _`,
      })),
    [searchList],
  );
  console.log(searchList, 'searchList');
  const handleTableAction = (eventKey, id) => {
    const row = searchList.find((dd) => dd.id === id);

    const action = {
      profile: () => {},
      view: () => {
        setShowModal((prevState) => {
          return {
            ...prevState,
            isShowView: true,
            profileData: row,
            profileId: row?.id,
          };
        });
      },
      edit: () => {},
      delete: () => {},
    };

    action[eventKey]();
  };
  console.log(showModal, 'formData2');
  return (
    <div className='nursing-lab-result-table'>
      <Table
        data={tableData}
        columns={tableColumns}
        actions={{ hasView: true }}
        onAction={handleTableAction}
        pagination={['BOTTOM']}
      />
    </div>
  );
};

export default LabResultTable;
