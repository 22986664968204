import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import './index.scss';

const PrintContent = React.forwardRef(({ data, viewFormType }, ref) => {
    const [printData, setPrintData] = useState({});
    // const { REACT_APP_LOGO, REACT_APP_HOSPITAL_NAME, REACT_APP_HOSPITAL_STREET_ADDRESS } = process.env;

    useEffect(() => {
        if (data) {
            setPrintData(data);
        }
    }, [data]);

    return (
        <div ref={ref}>
            <Row>
                <Col>
                    <p>OCRG Form No. 101</p>
                    <p>( Revised August 2021 )</p>
                </Col>
                <Col>
                    <p>Republic of the Philippines</p>
                    <p>OFFICE OF THE CIVIL REGISTRAT GENERAL</p>
                    <h2>{viewFormType}</h2>
                </Col>
                <Col>
                    <p>(To be accomplished in triplicate using black ink )</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Row>
                        <Col>
                            <p>Province</p>
                        </Col>
                        <Col>
                            <p>{printData?.province}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>City/ <br />Municipality</p>
                        </Col>
                        <Col>
                            <p>{printData?.city}</p>
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <p>Register No.</p>
                    <p>{printData?.register_no}</p>
                </Col>
            </Row>
            <div className='verticalLabelOnRightFlex'>
                <div>
                    <h3>CHILD</h3>
                </div>
                <div>
                    <Row>
                        <Col>
                            <p>1. NAME</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>( First )</p>
                            <p>{printData?.fname}</p>
                        </Col>
                        <Col>
                            <p>( Middle )</p>
                            <p>{printData?.mname}</p>
                        </Col>
                        <Col>
                            <p>( Last )</p>
                            <p>{printData?.lname}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>2. SEX</p>
                            <p>{printData?.sex}</p>
                        </Col>
                        <Col>
                            <p>3. APPROXIMATE AGE WHEN FOUND</p>
                            <p>{printData?.approximate_age_when_found}</p>
                        </Col>
                        <Col>
                            <Row>
                                <Col>
                                    <p>4. DATE WHEN FOUND</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p>( Day )</p>
                                </Col>
                                <Col>
                                    <p>( Month )</p>
                                </Col>
                                <Col>
                                    <p>( Year )</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p>{printData?.day_when_found}</p>
                                </Col>
                                <Col>
                                    <p>{printData?.month_when_found}</p>
                                </Col>
                                <Col>
                                    <p>{printData?.year_when_found}</p>
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <p>5. TIME WHEN FOUND</p>
                            <p>{printData?.time_when_found}</p>
                        </Col>
                        <Col>
                            <Row>
                                <Col>
                                    <p>6. APPROXIMATE DATE OF BIRTH</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p>( Day )</p>
                                </Col>
                                <Col>
                                    <p>( Month )</p>
                                </Col>
                                <Col>
                                    <p>( Year )</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p>{printData?.approximate_day_of_birth}</p>
                                </Col>
                                <Col>
                                    <p>{printData?.approximate_month_of_birth}</p>
                                </Col>
                                <Col>
                                    <p>{printData?.approximate_year_of_birth}</p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>7. PLACE WHERE FOUND</p>
                        </Col>
                        <Col>
                            <Row>
                                <p>( House No., St., Barangay )</p>
                                <p>{printData?.place_where_found}</p>
                            </Row>
                            <Row>
                                <p>( City / Municipality )</p>
                                <p>{printData?.city_where_found}</p>
                            </Row>
                            <Row>
                                <p>( Province )</p>
                                <p>{printData?.province_where_found}</p>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>8. COLOR OF THE EYES</p>
                            <p>{printData?.color_of_the_eyes}</p>
                        </Col>
                        <Col>
                            <p>9. COLOR OF THE HAIR</p>
                            <p>{printData?.color_of_the_hair}</p>
                        </Col>
                        <Col>
                            <p>10. DISTINCT BODY FEATURES OR MARKS</p>
                            <p>{printData?.distinct_body_features}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>11. CONDITION OF CHILD WHEN FOUND</p>
                            <p>{printData?.condition_of_child_when_found}</p>
                        </Col>
                        <Col>
                            <p>12. CITIZENSHIP OF THE CHILD</p>
                            <p>{printData?.citizenship_of_child}</p>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className='verticalLabelOnRightFlex'>
                <div>
                    <h3>FINDER</h3>
                </div>
                <div>
                    <Row>
                        <Col>
                            <p>13. NAME</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>( First )</p>
                            <p>{printData?.finder_fname}</p>
                        </Col>
                        <Col>
                            <p>( Middle )</p>
                            <p>{printData?.finder_mname}</p>
                        </Col>
                        <Col>
                            <p>( Last )</p>
                            <p>{printData?.finder_lname}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>14. SEX</p>
                            <p>{printData?.finder_sex}</p>
                        </Col>
                        <Col>
                            <p>15.AGE ( completed years )</p>
                            <p>{printData?.finder_age}</p>
                        </Col>
                        <Col>
                            <p>16. PHONE / EMAIL</p>
                            <p>{printData?.finder_phone_or_email}</p>
                        </Col>
                        <Col>
                            <p>17. OCCUPATION</p>
                            <p>{printData?.finder_occupation}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>18. RESIDENCE</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>( House No., St., Barangay )</p>
                            <p>{printData?.finder_residence}</p>
                        </Col>
                        <Col>
                            <p>( City / Municipality )</p>
                            <p>{printData?.finder_city}</p>
                        </Col>
                        <Col>
                            <p>( Province )</p>
                            <p>{printData?.finder_province}</p>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className='verticalLabelOnRightFlex'>
                <div>
                    <h3>INFORMAT</h3>
                </div>
                <div>
                    <Row>
                        <Col>
                            <p>19. NAME</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>( First )</p>
                            <p>{printData?.informat_fname}</p>
                        </Col>
                        <Col>
                            <p>( Middle )</p>
                            <p>{printData?.informat_mname}</p>
                        </Col>
                        <Col>
                            <p>( Last )</p>
                            <p>{printData?.informat_lname}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>20. SEX</p>
                            <p>{printData?.informat_sex}</p>
                        </Col>
                        <Col>
                            <p>21.AGE ( completed years )</p>
                            <p>{printData?.informat_age}</p>
                        </Col>
                        <Col>
                            <p>22. PHONE / EMAIL</p>
                            <p>{printData?.informat_phone_or_email}</p>
                        </Col>
                        <Col>
                            <p>23. OCCUPATION</p>
                            <p>{printData?.informat_occupation}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>24. RESIDENCE</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>( House No., St., Barangay )</p>
                            <p>{printData?.informat_residence}</p>
                        </Col>
                        <Col>
                            <p>( City / Municipality )</p>
                            <p>{printData?.informat_city}</p>
                        </Col>
                        <Col>
                            <p>( Province )</p>
                            <p>{printData?.informat_province}</p>
                        </Col>
                    </Row>
                </div>
            </div>
            <Row>
                <Col>
                    <p>25. CERTIFICATE OF INFORMAT</p>

                    <p>
                        <b>THIS IS TO CERTIFY</b> that the information given above are true and correct to my own knowledge and belief.
                    </p>

                    <p>{printData?.signature_of_informat}</p>
                    <p>Signature Over Printed Name of the Informat</p>

                    <p>
                        <b>SUBSCRIBED AND SWORN</b> to before me this {printData?.day} day of {printData?.month}, {printData?.year} at {printData?.place}, Philippines, affiant who exhibited to me his/her Commumity Tax Certificate or any government issued ID {printData?.id_type} issued on {printData?.id_issued_on} at {printData?.id_issued_at}
                    </p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <p>26. PREPARED BY</p>
                    <p>{printData?.prepared_by}</p>
                    <p>Signature Over Printed Name</p>
                    <Row>
                        <Col>Title or Position</Col>
                        <Col>{printData?.prepared_by_title_or_position}</Col>
                    </Row>
                    <Row>
                        <Col>Date</Col>
                        <Col>{printData?.prepared_date}</Col>
                    </Row>
                </Col>
                <Col>
                    <p>{printData?.signature_of_administering_officer}</p>
                    <p>Signature of the Administering Officer</p>
                    <p>{printData?.name_in_print}</p>
                    <p>Name in Print</p>
                    <p>{printData?.position_or_title_or_designation}</p>
                    <p>Position / Title / Designation</p>
                    <p>{printData?.address}</p>
                    <p>Address</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <p>27. RECEIVED BY</p>
                    <p>{printData?.received_by}</p>
                    <p>Signature Over Printed Name</p>
                    <Row>
                        <Col>
                            <p>{printData?.received_by_title_or_position}</p>
                        </Col>
                        <Col>
                            <p>Title or Position</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>{printData?.received_date}</p>
                        </Col>
                        <Col>
                            <p>Date</p>
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <p>28. REGISTERED AT THE OFFICE OF THE CIVIL REGISTRAR</p>
                    <p>{printData?.registrar_signature_over_printed_name}</p>
                    <p>Signature Over Printed Name</p>
                    <Row>
                        <Col>
                            <p>Title or Position</p>
                        </Col>
                        <Col>
                            <p>{printData?.registrar_title_or_position}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>Date</p>
                        </Col>
                        <Col>
                            <p>{printData?.registrar_date}</p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
});

PrintContent.displayName = 'PrintContent';

export default PrintContent;