/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */ 
//  import core & vendor packages below
import React from 'react';
import { Col, Container, Form, Row, Table } from 'react-bootstrap';

// import assets below
import './index.scss';

// main component
const headerTitleStyle = { 
  fontWeight: 'bold', 
  color: 'black', 
  fontSize: '16px', 
};
const headerSubtitleStyle = { 
  fontWeight: 'bold', 
  color: 'black', 
  fontSize: '14px', 
  marginTop: '5px', 
};
const PrintContent = React.forwardRef(({ data, viewFormType }, ref) => {
  const { REACT_APP_LOGO, REACT_APP_HOSPITAL_NAME, REACT_APP_HOSPITAL_STREET_ADDRESS } = process.env;
  const { 
    mname,
    given_name,
    lname,
    age,
    hrn,
    gender,
    address,
    name_of_hospital,
    ward_room_bed,
    discharge_diagnosis,
    admitting_diagnosis,
    date_discharge,
    date_admitted,
    operation_diagnosis,
    brief_clinical_history,
    pertinent_pe_bp,
    cr,
    dfc,
    aog,
    lmp,
    edc,
    g,
    p,
    abort,
    temperature,
    heent,
    chest_lungs,
    heart,
    abdomen,
    fh,
    fht,
    extremites,
    neurogical_examination,
    course_in_ward,
    laboratory_findings,
    medications,
    disposition,
    attending_physician,
    date_accomplished,
    patientId,
    type,
  } = data;
  console.log(data);
  return (
    <div className='print_body' ref={ref}> 
      <header>
          <div className='header_logo'>
            <img src={REACT_APP_LOGO} alt='print_logo' />
          </div>
          {/* Inline styling is used due to css class names .header_title and  .header_subtitle is not working at times */}
          <div style={headerTitleStyle}>{REACT_APP_HOSPITAL_NAME}</div>
          <div style={headerSubtitleStyle}>{REACT_APP_HOSPITAL_STREET_ADDRESS}</div>
      </header>
    <Table bordered>
      <thead>
        <tr>
          <th>{viewFormType}</th>
        </tr>
      </thead>
      <tbody>
        <Container>
          <Row className='mb-2'>
            <Col className='first-row'>
              <div className='input-group'>
                <p>{!name_of_hospital ? 'N/A' : name_of_hospital}</p>
                <label>Name of Healthcare Facility</label>
              </div>
              <div className='input-group'>
                <p>{!address ? 'N/A' : address}</p>
                <label>Address</label>
              </div>
            </Col>
          </Row>
          <Row className='mb-2'>
            <Col>
              <div className='input-group'>
              <label>Last name:</label>
              <p>{lname}</p>
              </div>
            </Col>
            <Col>
              <div className='input-group'>
              <label>Given name:</label>
              <p>{given_name}</p>
              </div>
            </Col>
            <Col>
              <div className='input-group'>
              <label>Middle name:</label>
              <p>{mname}</p>
              </div>
            </Col>
            <Col>
              <div className='input-group'>
              <label>HRN:</label>
              <p>{!hrn ? 'N/A' : hrn}</p>
              </div>
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col>
              <div className='input-group'>
                <label>Age:</label>
                <p>{!age ? 'N/A' : age}</p>
              </div>
            </Col>
            <Col>
              <div className='input-group'>
                <label>Gender:</label>
                <p>{!gender ? 'N/A' : gender}</p>
              </div>
            </Col>
            <Col>
              <div className='input-group'>
                <label>WARD / ROOM / BED</label>
                <p>{!ward_room_bed ? 'N/A' : ward_room_bed}</p>
              </div>
            </Col>
          </Row>
          <Row className='mb-2'>
            <Col>
              <h2>{viewFormType}</h2>
            </Col>
          </Row>
          <Row className='mb-2'>
            <Col className='header-textbox p-0'>
              <div className='input-group'>
                <label>Date Admitted</label>
                <p>{!date_admitted ? 'N/A' : date_admitted}</p>
              </div>
              <div className='input-group'>
                <label>Date Discharge</label>
                <p>{!date_discharge ? 'N/A' : date_discharge}</p>
              </div>
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col className='main-box-container p-0'>
              <div className='box-cont'>
                <Row>
                  <Col>
                    <div className='mb-2 input-group inline-label-textarea'>
                      <label>ADMITTING DIAGNOSIS</label>
                      <p>{admitting_diagnosis}</p>
                    </div>
                    <div className='mb-2 input-group inline-label-textarea'>
                      <label>DISCHARGE DIAGNOSIS</label>
                      <p>{discharge_diagnosis}</p>
                    </div>
                    <div className='mb-2 input-group inline-label-textarea'>
                      <label>OPERATION DIAGNOSIS</label>
                      <p>{operation_diagnosis}</p>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className='box-cont'>
                <Row className='mb-2'>
                  <Col sm={8}>
                    <div className='mb-2 input-group'>
                    <label>BRIEF CLINICAL HISTORY</label>
                    <p>{!brief_clinical_history ? 'N/A' : brief_clinical_history}</p>
                    </div>
                    <div className='inline-row'>
                      <p className='inline-header'>PERTINENT PE</p>
                      <div className='input-group inline-label'>
                        <label>B.P.</label>
                        <p>{!pertinent_pe_bp ? 'N/A' : pertinent_pe_bp}</p>
                      </div>
                      <div className='input-group inline-label'>
                        <label>C.R.</label>
                        <p>{!cr ? 'N/A' : cr}</p>
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className='mb-2 input-group inline-label'>
                      <label>LMP</label>
                      <p>{!lmp ? 'N/A' : lmp}</p>
                    </div>
                    <div className='mb-2 input-group inline-label'>
                      <label>EDC</label>
                      <p>{!edc ? 'N/A' : edc}</p>
                    </div>
                    <div className='mb-2 input-group inline-label'>
                      <label>AOG</label>
                      <p>{!aog ? 'N/A' : aog}</p>
                    </div>
                    <div className='mb-2 two-input'>
                      <div className='cont'>
                        <div className='input-group inline-label'>
                          <label>G</label>
                          <p>{!g ? 'N/A' : g}</p>
                        </div>
                      </div>
                      <div className='cont'>
                        <div className='input-group inline-label'>
                          <label>P</label>
                          <p>{!p ? 'N/A' : p}</p>
                        </div>
                      </div>
                    </div>
                    <div className='mb-2 input-group inline-label'>
                      <label>ABORT</label>
                      <p>{!abort ? 'N/A' : abort}</p>
                    </div>
                    <div className='mb-2 input-group inline-label'>
                      <label>TEMPERATURE</label>
                      <p>{!temperature ? 'N/A' : temperature}</p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={2}>
                    <h3 className='mb-1 mr-3'>GENERAL SURVEY</h3>
                  </Col>
                  <Col lg={10}>
                    <div className='mb-2 right-cont inline-label input-group'>
                      <label>HEENT:</label>
                      <p>{!heent ? 'N/A' : heent}</p>
                    </div>
                    <div className='mb-2 right-cont inline-label input-group'>
                      <label>CHEST / LUNGS:</label>
                      <p>{!chest_lungs ? 'N/A' : chest_lungs}</p>
                    </div>
                    <div className='mb-2 right-cont inline-label input-group'>
                      <label>HEART:</label>
                      <p>{!heart ? 'N/A' : heart}</p>
                    </div>
                    <div className='mb-2 right-cont inline-label input-group'>
                      <label>ABDOMEN:</label>
                      <p>{!abdomen ? 'N/A' : abdomen}</p>
                    </div>
                    <div className='mb-2 right-cont inline-label input-group'>
                      <div className='cont'>
                        <div className='input-group inline-label'>
                          <label>FH</label>
                          <p>{!fh ? 'N/A' : fh}</p>
                        </div>
                      </div>
                      <div className='cont'>
                        <div className='input-group inline-label'>
                          <label>FHT</label>
                          <p>{!fht ? 'N/A' : fht}</p>
                        </div>
                      </div>  
                    </div>
                    <div className='mb-2 right-cont inline-label input-group'>
                      <label>EXTREMITIES</label>
                      <p>{!extremites ? 'N/A' : extremites}</p>
                    </div>
                    <div className='mb-2 right-cont inline-label input-group'>
                      <label>NEUROLOGICAL EXAMINATION</label>
                      <p>{!neurogical_examination ? 'N/A' : neurogical_examination}</p>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className='box-cont'>
                <Row>
                  <Col>
                    <div className='mb-2 input-group inline-label-textarea flex-label'>
                      <label>COURSE IN THE WARD: ( Include Medications )</label>
                      <p>{!course_in_ward ? 'N/A' : course_in_ward}</p>
                    </div>
                    <div className='mb-2 input-group inline-label-textarea flex-label'>
                      <label>LABORATORY FINDINGS: ( Including EKG, X-ray and other diagnostic procedure )</label>
                      <p>{!laboratory_findings ? 'N/A' : laboratory_findings}</p>
                    </div>
                    <div className='input-group inline-label-textarea flex-label'>
                      <label>MEDICATION:</label>
                      <p>{!medications ? 'N/A' : medications}</p>
                    </div>
                    <div className='input-group inline-label-textarea flex-label'>
                      <label>DISPOSITION: ( Indicate home medication, special instruction and follow - up )</label>
                      <p>{!disposition ? 'N/A' : disposition}</p>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col>
              <div className='inline-label input-group'>
                <label>ATTENDING PHYSICIAN</label>
                <div className='signature'>
                  <p>{attending_physician}</p>
                  <p className='over-printed-name'>Signature Over Printed Name</p>
                </div>
              </div>
            </Col>
            <Col>
              <div className='justify-content-end inline-label input-group'>
                <label>DATE ACCOMPLISHED</label>
                <div className='signature'>
                  <p>{date_accomplished}</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </tbody>
    </Table>
    </div>
  );
}); 

PrintContent.displayName = 'PrintContent';

export default PrintContent; 