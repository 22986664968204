/* eslint-disable camelcase */
import React from 'react';
import moment from 'moment';
import { Col, Row } from 'react-bootstrap';

import logo from 'assets/images/strh.png';
import './PrintContent.scss';
import './index.scss';

// eslint-disable-next-line react/display-name
export const PrintContent = React.forwardRef((props, ref) => {
  const { REACT_APP_HOSPITAL_NAME, REACT_APP_HOSPITAL_STREET_ADDRESS } =
  process.env;
  const { reportIncomeStatement, date } = props;
  const { income_list, expenses_list } = reportIncomeStatement;
  return (
    <div id='print_income_statement' ref={ref}>
      <div className='header'>
        <img src={logo} alt='print_logo' />
        <h1>{REACT_APP_HOSPITAL_NAME}</h1>
        <h3>{REACT_APP_HOSPITAL_STREET_ADDRESS}</h3>
      </div>
      <div>
        <p style={{ float: 'right' }}>Date: {moment(date).format('MMMM DD, YYYY')}</p>
        <h5 className='mt-2 mb-4'>Income List:</h5>
        <Row className='mb-4'>
          <Col>
            <h6 className='mb-3'>Gross Profit</h6>
            <Row className='mb-4'>
              {income_list?.map(({ details, amount, id }) => {
                return (
                  <Row className='mb-2' key={id}>
                    <Col className='asset-name'>{details}</Col>
                    <Col className='asset-amount'>{amount}</Col>
                  </Row>
                );
              })}
            </Row>
            <Row>
              <Col className='asset-name'>Total Income</Col>
              <Col className='asset-amount'>{reportIncomeStatement?.total_income}</Col>
            </Row>
          </Col>

          <Col>
            <h6 className='mb-3'>Expenses</h6>
            <Row className='mb-4'>
              {expenses_list?.map(({ details, amount, id }) => {
                return (
                  <Row className='mb-2' key={id}>
                    <Col className='asset-name'>{details}</Col>
                    <Col className='asset-amount'>{amount}</Col>
                  </Row>
                );
              })}
            </Row>
            <Row>
              <Col className='asset-name'>Total Expenses</Col>
              <Col className='asset-amount'>{reportIncomeStatement?.total_expenses}</Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
});
