/* eslint-disable react/jsx-indent-props */
/* eslint-disable camelcase */
/* eslint-disable keyword-spacing */
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark2.svg';
// import axios from 'axios';
import { useState } from 'react';
import { useEffect } from 'react';
import { FormControl, Button, Form } from 'react-bootstrap';
import './index.scss';
import axios from 'axios';

const PatientAddOrder = ({ showProfile, setShowProfile }) => {

    const [userData, setUserData] = useState({
        uom: '',
        doze: '',
        notes: '',
        quantity: 0,
        unit_price: 0,
        patientId: showProfile.profileData.data.id,
        medication: '',
        total_amount: 0,
        route_of_administration: '',
        times_of_administration: '',
    });

    const handleChange = (key) => (e) => {
        const keys = ['quantity', 'unit_price'];
    
        if (keys.includes(key)) {
          setUserData({
            ...userData,
            [key]: e.target.value,
          });
          return true;
        }
    
        setUserData({
          ...userData,
          [key]: e.target.value,
        });
      };

    const handleSubmit = () => {
        console.log('userData', userData);
        axios.post('/medication', userData).then((res) => console.log('RESOLVED', res))
.catch((rej) => console.log('REJECTED', rej));
    };

    useEffect(() => { 
        if (userData.quantity !== '' && userData.unit_price !== '') {
          setUserData({
          ...userData,
          total_amount: parseInt(userData.quantity) * parseInt(userData.unit_price),
        });
        }
    }, [userData.quantity, userData.unit_price]);

    return(
        <div>
            <div className='headerButtonsContainer'>
                <CircleXMark 
                style={{ width: '2em', cursor: 'pointer' }}
                onClick={() =>
                    setShowProfile({ showProfile: false, profileId: null, showWorkList: true })
                }
                />
            </div>
            <div className='gridContainer my-5'>
              <div className='gridItem'>
                <Form.Label>Medication</Form.Label>
                <FormControl 
                    type='text'
                    placeholder='Enter Here'
                    value={userData.medication}
                    onChange={handleChange('medication')}
                />
              </div>
              <div className='gridItem'>
                <Form.Label>Doze</Form.Label>
                <FormControl 
                    type='text'
                    placeholder='Enter Here'
                    value={userData.doze}
                    onChange={handleChange('doze')}
                />
              </div>
              <div className='gridItem'>
                    <Form.Label>Route of Administration</Form.Label>
                  <Form.Select
                    onChange={handleChange('route_of_administration')}
                    value={userData.route_of_administration}
                  >
                    <option hidden>-Select-</option>
                    <option value='Ext - External use'>Ext - External use</option>
                    <option value='PR - Per Rectum'>PR - Per Rectum</option>
                    <option value='IM - Intramuscular'>IM - Intramuscular</option>
                    <option value='PV - Per Vagina'>PV - Per Vagina</option>
                    <option value='Inh - Inhalation'>Inh - Inhalation</option>
                    <option value='SC - Subcutaneous'>SC - Subcutaneous</option>
                    <option value='IV - Intravenous'>IV - Intravenous</option>
                    <option value='SL - Sublingual'>SL - Sublingual</option>
                    <option value='Neb - Nebulisation'>Neb - Nebulisation</option>
                    <option value='Top - Topical, indicating the specific area or eye/ear'>
                      Top - Topical, indicating the specific area or eye/ear
                    </option>
                    <option value='O/PO - By Mouth'>O/PO - By Mouth</option>
                  </Form.Select>
              </div>
              <div className='gridItem'>
                    <Form.Label>Times of Administration</Form.Label>
                  <Form.Select
                    onChange={handleChange('times_of_administration')}
                    value={userData.times_of_administration}
                  >
                    <option hidden>-Select-</option>
                    <option value='OD - Once a day'>OD - Once a day</option>
                    <option value='OM - Each morning'>OM - Each morning</option>
                    <option value='ON - Each night'>ON - Each night</option>
                    <option value='BD - Twice daily'>BD - Twice daily</option>
                    <option value='TDS - Three times daily'>TDS - Three times daily</option>
                    <option value='QDS - Four times daily'>QDS - Four times daily</option>
                    <option value='Mane - Morning'>Mane - Morning</option>
                    <option value='Nocte - At bedtime'>Nocte - At bedtime</option>
                    <option value='PRN - As required (with indication and interval stated)'>
                      PRN - As required (with indication and interval stated)
                    </option>
                  </Form.Select>
              </div>
              <div className='gridItem'>
                <Form.Label>Unit of Measurement</Form.Label>
                <Form.Select value={userData.oum} onChange={handleChange('uom')}>
                <option hidden>-Select-</option>
                <option value='g'>g(gram)</option>
                <option value='mg'>mg(miligram)</option>
                <option value='mcg'>mcg(microgram)</option>
                <option value='u'>U(unit)</option>
                <option value='TU'>TU(thousand units)</option>
                <option value='MU'>MU(million units)</option>
                <option value='mmol'>mmol(millimole)</option>
                <option value='ml'>ml(milliliter(e.g. eyedrops))</option>
                </Form.Select>
              </div>
              <div className='gridItem'>
                <Form.Label>Unit Price</Form.Label>
                <FormControl
                type='number'
                className='bg-white'
                placeholder='Enter Here'
                value={userData.unit_price}
                onChange={handleChange('unit_price')}
                onKeyDown={(evt) =>
                    (evt.key === 'e' || evt.key === 'E' || evt.key === '-' || evt.key === '.') &&
                    evt.preventDefault()
                }
                />
              </div>
              <div className='gridItem'>
                <Form.Label>Quantity</Form.Label>
                <FormControl
                type='number'
                className='bg-white'
                placeholder='Enter Here'
                // onKeyUp={console.log('aaa')}
                onKeyDown={(evt) => {
                    evt.key === 'e' && evt.preventDefault();
                    evt.key === '-' && evt.preventDefault();
                    evt.key === '+' && evt.preventDefault();
                }}
                value={userData.quantity}
                onChange={handleChange('quantity')}
                />
              </div>
              <div className='gridItem'>
                <Form.Label>Total Amount</Form.Label>
                <FormControl
                type='text'
                disabled
                className='bg-white'
                placeholder='Enter Here'
                value={userData.total_amount}
                />
              </div>
              <div className='gridItem'>
                <Form.Label>Notes</Form.Label>
                <FormControl
                as='textarea'
                rows={2}
                className='bg-white'
                placeholder='Enter Here'
                value={userData.notes}
                onChange={handleChange('notes')}
                />
              </div>
            </div>
            
            <Button
                onClick={handleSubmit}
            >
                Add Order
            </Button>
        </div>
    );
};

export default PatientAddOrder;