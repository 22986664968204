/* eslint-disable multiline-comment-style */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
//  import core & vendor packages below
import axios from 'axios';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useReactToPrint } from 'react-to-print';
import React, { useEffect, useState, useRef } from 'react';
import { Row, Form, Col, Button, Image, Table } from 'react-bootstrap';

// import components below
import TableUI from 'components/UI/TableUI';

// import assets below
import { PrintContent } from './PrintContent';
import { ReactComponent as UserSVG } from 'assets/svg/user.svg';
import { ReactComponent as ArrowSVG } from 'assets/svg/chevron-right.svg';

// dataset
const sampleMedication = [
  {
    id: 1,
    code: 'MED0000213',
    description: 'Biogesic 500mg tab',
    sub_description: 'Paracetamol 500mg tab',
    priority: 'STAT',
    ordering_physician: 'Abesamis, Midred',
    clinical_indication: 'For Fever as Needed',
    qty: '6',
    notes: '-',
    status: 'Cleared',
  },
  {
    id: 2,
    code: 'MED0000213',
    description: 'Biogesic 500mg tab',
    sub_description: 'Paracetamol 500mg tab',
    priority: 'STAT',
    ordering_physician: 'Abesamis, Midred',
    clinical_indication: 'For Fever as Needed',
    qty: '6',
    notes: '-',
    status: 'Cleared',
  },
];

const sampleSupplies = [
  {
    id: 1,
    code: 'SUP0212121',
    description: 'Wheelchair',
    sub_description: '',
    priority: 'STAT',
    ordering_physician: 'Abesamis, Midred',
    clinical_indication: '-',
    qty: '1',
    notes: '-',
    status: 'Cleared',
  },
  {
    id: 2,
    code: 'MED0000213',
    description: 'Hospital Blanket and Pillow',
    sub_description: '',
    priority: 'STAT',
    ordering_physician: 'Abesamis, Midred',
    clinical_indication: '-',
    qty: '1',
    notes: '-',
    status: 'Cleared',
  },
];

const sampleProcedures = [
  {
    id: 1,
    code: 'Details Here',
    descripiton: 'Details Here',
    remarks: 'Details Here',
    status: 'Ongoing',
  },
  {
    id: 2,
    code: 'Details Here',
    descripiton: 'Details Here',
    remarks: 'Details Here',
    status: 'Ongoing',
  },
];

const sampleTest = [
  {
    id: 1,
    name: 'Gastrocopy Test',
    data: [
      {
        id: 1,
        remarks: 'Details Here',
        link: '#',
        status: 'Cleared',
      },
      {
        id: 2,
        remarks: 'Details Here',
        link: '#',
        status: 'Ongoing',
      },
    ],
  },
  {
    id: 2,
    name: 'Biochemistry Test',
    data: [
      {
        id: 1,
        remarks: 'Details Here',
        link: '#',
        status: 'Cleared',
      },
      {
        id: 2,
        remarks: 'Details Here',
        link: '#',
        status: 'Cleared',
      },
    ],
  },
  {
    id: 3,
    name: 'Colonoscopy Test',
    data: [
      {
        id: 1,
        remarks: 'Details Here',
        link: '#',
        status: 'Ongoing',
      },
      {
        id: 2,
        remarks: 'Details Here',
        link: '#',
        status: 'Ongoing',
      },
    ],
  },
  {
    id: 4,
    name: 'Blood Test',
    data: [
      {
        id: 1,
        remarks: 'Details Here',
        link: '#',
        status: 'Cleared',
      },
      {
        id: 2,
        remarks: 'Details Here',
        link: '#',
        status: 'Ongoing',
      },
    ],
  },
  {
    id: 4,
    name: 'Stool Test',
    data: [
      {
        id: 1,
        remarks: 'Details Here',
        link: '#',
        status: 'Cleared',
      },
      {
        id: 2,
        remarks: 'Details Here',
        link: '#',
        status: 'Cleared',
      },
    ],
  },
  {
    id: 5,
    name: 'Sonography Test',
    data: [
      {
        id: 1,
        remarks: 'Details Here',
        link: '#',
        status: 'Cleared',
      },
      {
        id: 2,
        remarks: 'Details Here',
        link: '#',
        status: 'Cleared',
      },
    ],
  },
  {
    id: 6,
    name: 'X-Ray',
    data: [
      {
        id: 1,
        remarks: 'Details Here',
        link: '#',
        status: 'Cleared',
      },
      {
        id: 2,
        remarks: 'Details Here',
        link: '#',
        status: 'Cleared',
      },
    ],
  },
  {
    id: 7,
    name: 'Urine Test',
    data: [
      {
        id: 1,
        remarks: 'Details Here',
        link: '#',
        status: 'Cleared',
      },
      {
        id: 2,
        remarks: 'Details Here',
        link: '#',
        status: 'Cleared',
      },
    ],
  },
];
// main component
const Profile = ({ showProfile, setShowScreen }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get(`/medical_records/discharge_summary/${showProfile?.profileId}`)
      .then((response) => {
        setData(response.data.data);
      }, [])
      .catch((err) => {
        console.log('AXIOS ERROR: ', err);
      });
  }, []);

  const printRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  console.log('profile data: ', data);

  return (
    <>
      <div style={{ display: 'none' }}>
        <PrintContent ref={printRef} data={data} />
      </div>
      <div className='view-profile'>
        <Row className='mb-4'>
          <Col className='header-buttons'>
            <Button
              className='back-button'
              onClick={() => {
                setShowScreen(0);
              }}
            >
              Back to Patient List
            </Button>

            <Button className='print' onClick={handlePrint}>
              Print
            </Button>
          </Col>
        </Row>

        <Row className='mb-4'>
          <Col className='profile-info'>
            <Form.Group className='mb-3 avatar-container'>
              <UserSVG />
            </Form.Group>

            <Form.Group className='profile-name'>
              <h4>
                <b>{data?.last_name}</b> ,{data?.prefix ? data?.prefix : ''} {data?.first_name}
              </h4>
              <h6>
                Patient ID: <span>{showProfile?.profileId}</span>
              </h6>
              <div className='info'>
                {data?.age} / {data?.sex}
              </div>
            </Form.Group>
          </Col>
        </Row>

        <Row className='mb-5 patient-banner'>
          <Col lg='3' className='right'>
            <div className='p-3'>
              <h6>Allergies:</h6>
              <div className='allergies d-flex flex-column'>
                {data?.patient_allergies?.length > 0 ? (
                  data?.patient_allergies?.map((item, key) => {
                    return (
                      <span key={key} className='fw-bold'>
                        {' '}
                        {item?.allergy}
                      </span>
                    );
                  })
                ) : (
                  <span className='fw-bold'>N/A</span>
                )}
              </div>
            </div>
          </Col>
          <Col lg='6'>
            <div className='d-flex flex-column align-items-between h-100 p-3'>
              <Row>
                <Col>
                  <div className='mt-2'>
                    <span>Location: </span>
                    <span className='fw-bold'>
                      {data?.assigned_room ? data?.assigned_room?.room?.room : '-'}
                    </span>
                  </div>
                </Col>
                <Col>
                  <div className='mt-2'>
                    <span>Admission Date: </span>{' '}
                    <span>
                      {data?.admission_date
                        ? moment(data?.admission_date).format('MMMM-DD-YYYY')
                        : '-'}
                    </span>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className='mt-2'>
                    <span>Visit Number: </span>
                    <span className='fw-bold'>
                      {data?.active_visit_number ? data?.active_visit_number : '-'}
                    </span>
                  </div>
                </Col>
                <Col>
                  <div className='mt-2'>
                    <span>Length of Stay: </span>{' '}
                    <span className='fw-bold'>{data?.lengthOfStay}</span>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col lg='3' className='left'>
            <div className='p-3'>
              <h6>Doctors:</h6>
              <div className='d-flex flex-column'>
                {data?.assigned_doctors?.length > 0 ? (
                  data?.assigned_doctors?.map((item) => {
                    return (
                      <span key='doctor' className='fw-bold'>
                        Dr. {item?.user?.full_name} {item?.user?.first_name}{' '}
                        {item?.user?.middle_name}
                      </span>
                    );
                  })
                ) : (
                  <span className='fw-bold'>N/A</span>
                )}
              </div>
            </div>
          </Col>
        </Row>

        <Row className='mb-4'>
          <Col className='discharge-table'>
            <h5>Medications</h5>

            <TableUI>
              <Table responsive>
                <thead>
                  <tr>
                    <th>Medication Description</th>
                    <th>Doze</th>
                    <th>Route of Administration</th>
                    <th>Times of Administration</th>
                    <th>Unit of measure</th>
                    <th>Unit price</th>
                    <th>QTY</th>
                    <th>Total Amount</th>

                    {/* <th>Item Code</th>
                    <th>Medication Description</th>
                    <th>Priority</th>
                    <th>Ordering Physician</th>
                    <th>Clinical Indication</th>
                    <th>QTY</th>
                    <th>Notes</th>
                    <th></th> */}
                  </tr>
                </thead>
                <tbody>
                  {data &&
                    data?.medications?.map((data) => {
                      return (
                        <tr key={data.id}>
                          <td>{data.medication}</td>
                          <td>{data.doze}</td>
                          <td>{data.route_of_administration}</td>
                          <td>{data.times_of_administration}</td>
                          <td>{data.uom}</td>
                          <td>{data.unit_price}</td>
                          <td>{data.quantity}</td>
                          <td>{data.total_amount}</td>
                          {/* <td>{data.code}</td>
                          <td>
                            <p>
                              {data.medication} <span>{data.sub_description}</span>
                            </p>
                          </td>
                          <td>{data.priority}</td>
                          <td>{data.ordering_physician}</td>
                          <td>{data.clinical_indication}</td>
                          <td>{data.qty}</td>
                          <td>{data.notes}</td>
                          <td className={data.status}>
                            <span>{data.status}</span>
                          </td> */}
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </TableUI>
          </Col>
        </Row>

        <Row className='mb-4'>
          <Col className='discharge-table'>
            <h5>Supplies</h5>

            <TableUI>
              <Table responsive>
                <thead>
                  <tr>
                    <th>Supply Description</th>
                    <th>Quantity</th>
                    <th>Priority</th>
                    <th>Notes</th>

                    {/* <th>Item Code</th>
                    <th>Medication Description</th>
                    <th>Priority</th>
                    <th>Ordering Physician</th>
                    <th>Clinical Indication</th>
                    <th>QTY</th>
                    <th>Notes</th>
                    <th></th> */}
                  </tr>
                </thead>
                <tbody>
                  {data?.supplies &&
                    data?.supplies.map((data) => {
                      return (
                        <tr key={data.id}>
                          <td>{data.supply}</td>
                          <td>{data.quantity}</td>
                          <td>{data.priority}</td>
                          <td>{data.notes}</td>
                          {/* <td>{data.code}</td>
                          <td>
                            <p>
                              {data.description} <span>{data.sub_description}</span>
                            </p>
                          </td>
                          <td>{data.priority}</td>
                          <td>{data.ordering_physician}</td>
                          <td>{data.clinical_indication}</td>
                          <td>{data.qty}</td>
                          <td>{data.notes}</td>
                          <td className={data.status}>
                            <span>{data.status}</span>
                          </td> */}
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </TableUI>
          </Col>
        </Row>

        <Row className='mb-4'>
          <Col className='discharge-table'>
            <h5>Procedures</h5>

            <TableUI>
              <Table responsive>
                <thead>
                  <tr>
                    <th>Surgery Code</th>
                    <th>Description</th>
                    <th>Remarks</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {data?.request_procedures &&
                    data?.request_procedures.map((data) => {
                      return (
                        <tr key={data.id}>
                          <td>{data.ctp_code}</td>
                          <td>{data.procedure}</td>
                          <td>{data.remarks}</td>
                          <td className={data.status}>
                            <span>{data.status}</span>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </TableUI>
          </Col>
        </Row>

        <Row className='mb-4'>
          <Col className='discharge-table'>
            <h5>Tests</h5>

            <TableUI>
              <Table responsive>
                <thead>
                  <tr>
                    <th>Test</th>
                    <th>Ordering Physician</th>
                    <th>Notes</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {data?.laboratory_requests &&
                    data?.laboratory_requests.map((data) => {
                      return (
                        <tr key={data.id}>
                          <td>{data.lab_type}</td>
                          <td>{data.user.full_name ? data.user.full_name : data?.user?.first_name + ' ' + data?.user?.last_name}</td>
                          <td>{data.remarks}</td>
                          <td className={data.status}>
                            <span>{data.status}</span>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </TableUI>
          </Col>
        </Row>

        {/* <Row className='mb-4'>
          <Col className='discharge-table'>
            <h5>Test</h5>

            {sampleTest.map((data) => {
              return (
                <TableUI key={data.id}>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th colSpan={4}>{data.name}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.data.map((listData) => {
                        return (
                          <tr key={listData.id}>
                            <td>Remarks:</td>
                            <td>{listData.remarks}</td>
                            <td>
                              <a
                                rel='noreferrer'
                                target='_blank'
                                href={listData.link}
                                className='link'
                              >
                                View Test Information
                              </a>
                            </td>
                            <td className={listData.status}>
                              <span>{listData.status}</span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </TableUI>
              );
            })}
          </Col>
        </Row> */}
      </div>
    </>
  );
};

Profile.defaultProps = {
  setShowScreen: () => { },
};

Profile.propTypes = {
  setShowScreen: PropTypes.func,
};

export default Profile;
