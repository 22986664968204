/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Form, FormControl, OverlayTrigger } from 'react-bootstrap';
import moment from 'moment';

// import components below
import Table from 'components/ReactTable';
import ConfirmModal from './components/ConfirmModal';

// import assets below
import './index.scss';
import { ReactComponent as EyeSVG } from 'assets/svg/eye.svg';
import { ReactComponent as XmarkSVG } from 'assets/svg/circle-xmark-red.svg';
import { ReactComponent as ThreeDotsSVG } from 'assets/svg/three-dots-svgrepo-com.svg';
import axios from 'axios';

// main component
const samplePhoto = 'https://picsum.photos/id/237/200/300';

const state = [
  {
    avatar: samplePhoto,
    id: 1,
    name: 'Juan dela Cruz',
    date: '01-28-2023',
    log_in: '8:53 AM',
    log_out: '6:03 PM',
    status: 'Complete',
    task: '- Memo Distribution (March - April Holidays)',
  },
  {
    avatar: samplePhoto,
    id: 2,
    name: 'Juan dela Cruz',
    date: '01-29-2023',
    log_in: '8:57 AM',
    log_out: '6:07 PM',
    status: 'Complete',
    task: '- Memo Distribution (March - April Holidays)',
  },
  {
    avatar: samplePhoto,
    id: 3,
    name: 'Juan dela Cruz',
    date: '02-02-2023',
    log_in: '8:59 AM',
    log_out: '6:05 PM',
    status: 'Complete',
    task: '- Memo Distribution (March - April Holidays)',
  },
  {
    avatar: samplePhoto,
    id: 4,
    name: 'Juan dela Cruz',
    date: '02-04-2023',
    log_in: '8:49 AM',
    log_out: '6:03 PM',
    status: 'Complete',
    task: '- Memo Distribution (March - April Holidays)',
  },
];

const index = ({ setShowScreen, setRequestData, userData, setUserData, searchEmployee, setSearchEmployee }) => {
  const [openModal, setOpenModal] = useState();
  const [modalType, setModalType] = useState();
  const [filteredState, setFilteredState] = useState(state);

  const tableColumns = React.useMemo(
    () => [
      { Header: 'Avatar', accessor: 'avatar' }, // accessor is the "key" in the data
      { Header: 'ID', accessor: 'id' }, // accessor is the "key" in the data
      { Header: 'Name', accessor: 'name' },
      { Header: 'Date', accessor: 'date' },
      { Header: 'Log-in', accessor: 'log_in' },
      { Header: 'Log-out', accessor: 'log_out' },
    ],
    [],
  );
  
  const tableData = React.useMemo(
    () =>
    filteredState?.map((dd) => ({
        name: dd?.name,
        id: dd?.id,
        log_out: dd?.log_out,
        date: dd?.date,
        log_in: dd?.log_in,
        status: dd?.status,
        task: dd?.task,
        avatar: <img src={dd?.avatar} className='avatar' />,
        data: dd,
      })),
    [filteredState],
  );

  const handleDelete = () => {
    setModalType('delete');
    setOpenModal(true);
  };

  const formatDate = (date) => {
    const dateSplit = date.split('-');
    const formattedDate = dateSplit[2] + '-' + dateSplit[0] + '-' + dateSplit[1];
    return formattedDate;
  };

  const decDay = (date) => {
    const dateSplit = date.split('-');
    const decedDay = dateSplit[0] + '-' + dateSplit[1] + '-' + (parseInt(dateSplit[2]) - 1).toString();
    return decedDay;
  };

  const incDay = (date) => {
    const dateSplit = date.split('-');
    const decedDay = dateSplit[0] + '-' + dateSplit[1] + '-' + (parseInt(dateSplit[2]) + 1).toString();
    return decedDay;
  };
  
  /* useEffect(() => {
       axios.get(`${process.env.REACT_APP_API_BASE_URL}/employee/lists`).then((res) => { console.log('RESOLVED', res); });
     });  */
 
  useEffect(() => {
    if (userData.triggered === true && userData.fromDate !== '' && userData.toDate === '') {
      setFilteredState(state.filter((date) => moment(formatDate(date.date)).isSameOrAfter(userData.fromDate)));
    }
    if (userData.triggered === true && userData.toDate !== '' && userData.fromDate === '') {
      setFilteredState(state.filter((date) => moment(formatDate(date.date)).isSameOrBefore(userData.toDate)));
    }
    if (userData.triggered === true && userData.toDate !== '' && userData.fromDate !== '') {
      setFilteredState(state.filter((date) => moment(formatDate(date.date)).isBetween(decDay(userData.fromDate), incDay(userData.toDate))));
    }
    if (userData.triggered === true && userData.toDate === '' && userData.fromDate === '') {
      setFilteredState(state);
    }
    if (userData.triggered === true && userData.searchEmployee !== '') {
      setFilteredState(filteredState.filter((stt) => stt.name.match(userData.searchEmployee) !== null));
    }
    setUserData({
      ...userData,
      triggered: false,
    });
  }, [userData.triggered]);

  return (
    <div className='attendance-table'>
      <div className='main-table'>
        <Table
          data={tableData}
          columns={tableColumns}
          pagination={['BOTTOM']}
          customAction={(id, data) => {
            return (
              <div className='attendance-actions'>
                <EyeSVG
                  onClick={() => {
                    setShowScreen(2);
                    setRequestData(data);
                  }}
                  style={{ width: '1.5em', cursor: 'pointer' }}
                />
                <XmarkSVG
                  onClick={handleDelete}
                  className='delete'
                  style={{ width: '1.5em', cursor: 'pointer' }}
                />
              </div>
            );
          }}
        />
      </div>
      <ConfirmModal modalType={modalType} openModal={openModal} setOpenModal={setOpenModal} />
    </div>
  );
};

export default index;
