/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
//  import core & vendor packages below
import Moment from 'moment';
import { toast } from 'react-toastify';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Row, Col, Form, FormControl } from 'react-bootstrap';
import { DropdownDate, DropdownComponent } from 'react-dropdown-date';

// import components below
import PatientSelection from './PatientSelection';
import ConditionalRender from 'components/ConditionalRender';
import useCreateRecordResponse from './hooks/useCreateRecordResponse';
import { getPatients, selectPatientData } from 'reducers/patientReducer';
import {
  createCertofDeath,
  getMedicalRecordID,
  getMedicalFormsbyID,
  selectActivePatients,
} from 'reducers/medicalRecordReducer';

// import assets below
import './DeathForm.scss';
import { data } from 'jquery';

// main component
const DeathForm = ({ setShowScreen, setViewForm, viewForm, setIsEdit, isEdit }) => {
  const dispatch = useDispatch();
  const logs = useSelector(selectActivePatients);
  const patientSelection = useSelector(selectPatientData);

  const [page, setPage] = useState(1);
  const [isAdd, setIsAdd] = useState(false);
  const [validated, setValidated] = useState(false);
  const [showPatientSelection, setShowPatientSelection] = useState(false);
  const [DOB, setDOB] = useState({ selectedDate: 'YYYY-MM-DD', date: null });
  const [DOD, setDOD] = useState({ selectedDate: 'YYYY-MM-DD', date: null });
  const [trigger, setTrigger] = useState({ trigger: false, patientID: false, recordID: false });
  const [formData, setFormData] = useState({
    province: '',
    city: '',
    register_no: '',
    fname: '',
    mname: '',
    lname: '',
    sex: '',
    day_of_death: '',
    month_of_death: '',
    year_of_death: '',
    day_of_birth: '',
    month_of_birth: '',
    year_of_birth: '',
    completed_years: '',
    completed_months: '',
    completed_days: '',
    completed_hours: '',
    completed_minute_second: '',
    place_of_death_hospital: '',
    place_of_death_house_no_st_brgy: '',
    place_of_death_city: '',
    place_of_death_province: '',
    civil_status: '',
    religion: '',
    citizenship: '',
    residence_house_no_st_brgy: '',
    residence_city: '',
    residence_province: '',
    residence_country: '',
    occupation: '',
    name_of_father: '',
    name_of_mother: '',
    immediate_cause: '',
    immediate_cause_interval_between_onset_and_death: '',
    antecedent_cause: '',
    antecedent_cause_interval_between_onset_and_death: '',
    underlying_cause: '',
    underlying_cause_interval_between_onset_and_death: '',
    other_condition_contributing_to_death: '',
    maternal_condition: '',
    external_manner_of_death: '',
    external_place_of_occurrent_of_external_cause: '',
    is_autopsy: '',
    is_private_physician: '',
    is_public_health_officer: '',
    is_hospital_authority: '',
    is_none: '',
    others_please_specify: '',
    duration_start_date: '',
    duration_end_date: '',
    cert_of_death_have_attended: '',
    cert_of_death_have_not_attended: '',
    cert_of_death_time_of_occurence: '',
    cert_of_death_signature: '',
    cert_of_death_name_in_print: '',
    cert_of_death_title_or_position: '',
    cert_of_death_address: '',
    cert_of_death_date: '',
    cert_of_death_reviewed_by: '',
    cert_of_death_signature_over_printed_name: '',
    cert_of_death_reviewed_date: '',
    corpse_disposal: '',
    burial_cremation_permit_no: '',
    burial_cremation_permit_date_issued: '',
    transfer_permit_no: '',
    transfer_permit_date_issued: '',
    name_and_address_of_cemetery_or_crematory: '',
    cert_of_informat_signature: '',
    cert_of_informat_name_in_print: '',
    cert_of_informat_relation_to_deceased: '',
    cert_of_informat_address: '',
    cert_of_informat_date: '',
    prepared_by_signature: '',
    prepared_by_name_in_print: '',
    prepared_by_title_or_position: '',
    prepared_by_address: '',
    prepared_by_date: '',
    recieved_by_signature: '',
    recieved_by_name_in_print: '',
    recieved_by_title_or_position: '',
    recieved_by_address: '',
    recieved_by_date: '',
    civil_registrar_signature: '',
    civil_registrar_name_in_print: '',
    civil_registrar_title_or_position: '',
    civil_registrar_address: '',
    civil_registrar_date: '',
    remarks: '',
    age_of_mother: '',
    method_of_delivery: '',
    length_of_pregnancy_in_weeks: '',
    type_of_birth: '',
    if_multiple_child_was: '',
    main_disease: '',
    other_diseases: '',
    main_maternal_diseases: '',
    other_maternal_diseases: '',
    other_relevant_circumstances: '',
    cause_of_death: '',
    postmortem_signature: '',
    postmortem_name_in_print: '',
    postmortem_date: '',
    postmortem_title_designation: '',
    postmortem_address: '',
    name: '',
    embalmer_signature: '',
    embalmer_name_in_print: '',
    embalmer_address: '',
    embalmer_title_designation: '',
    embalmer_license_no: '',
    embalmer_license_issued_on: '',
    embalmer_license_issued_at: '',
    embalmer_license_expiry_date: '',
    affidavit_name: '',
    affidavit_civil_status: '',
    affidavit_address: '',
    affidavit_deceased_name: '',
    affidavit_died_on: '',
    affidavit_died_in: '',
    affidavit_burried_or_cremated_in: '',
    affidavit_burried_or_cremated_on: '',
    affidavit_was_attended: '',
    affidavit_was_not_attended: '',
    affidavit_was_attented_by: '',
    affidavit_cause_of_death: '',
    affidavit_reason_for_late_registration_of_death: '',
    affidavit_day: '',
    affidavit_month: '',
    affidavit_year: '',
    affidavit_address_1: '',
    affidavit_signature_over_printed_name_of_affiant: '',
    affidavit_subscribed_and_sworn_day: '',
    affidavit_subscribed_and_sworn_month: '',
    affidavit_subscribed_and_sworn_year: '',
    affidavit_subscribed_and_sworn_address: '',
    affidavit_tax_certificate: '',
    affidavit_tax_certificate_issued_on: '',
    affidavit_tax_certificate_issued_at: '',
    affidavit_signature_of_administering_officer: '',
    affidavit_name_in_print: '',
    affidavit_position: '',
    affidavit_address_2: '',
    patientId: Math.random(),
    type: 'cert_of_death',
  });

  const isEditing = isEdit === true;
  const isAdding = isAdd === true;
  const isPageOne = page === 1;
  const isPageTwo = page === 2;

  const handleShowPatientSelection = () => {
    setShowPatientSelection(true);
    return;
  };

  const handleClosePatientSelection = () => {
    setShowPatientSelection(false);
    return;
  };

  // For Add
  const addRecord = () => {
    setTrigger({ ...trigger, trigger: true, recordID: true });
    dispatch(
      createCertofDeath({
        ...formData,
        method: 'post',
        type: 'cert_of_death',
      }),
    );
    setIsEdit(false);
    return;
  };

  // For Edit
  const saveChanges = () => {
    setTrigger({ ...trigger, trigger: true, recordID: true });
    dispatch(
      createCertofDeath({
        ...formData,
        method: 'put',
        type: 'cert_of_death',
      }),
    );
    // setViewForm({ ...viewForm, data: formData });
    setIsEdit(false);
    return;
  };

  const handleChange = (key) => (e) => {
    setFormData((prev) => {
      return { ...prev, [key]: e.target.value };
    });

    return;
  }; // For Add

  const textBox = (key) => {
    return (
      <FormControl
        type='text'
        disabled={!isEdit}
        style={{ width: `${isEdit ? '' : `${formData[key]?.length}ch`}` }}
        className={`${isEdit ? 'editable' : 'non-editable'}`}
        value={formData[key]}
        onChange={handleChange(key)}
      />
    );
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;

    event.preventDefault();
    event.stopPropagation();

    if (!form.checkValidity() === false) {
      setTrigger({ ...trigger, trigger: true });
      setIsEdit(false);
      dispatch(createCertofDeath({ ...formData, method: 'post', type: 'cert_of_death' }));
      setIsAdd(false);
      return;
    }

    toast.warning('Fill up the required fields');
    setValidated(true);
  };

  useEffect(() => {
    dispatch(getPatients());

    if (viewForm?.patientID) {
      setTrigger({ ...trigger, medicalRecordID: true });
      const formID = viewForm?.data?.id;

      dispatch(getMedicalRecordID(formID));
    }
  }, []);

  useEffect(() => {
    if (Object.keys(viewForm.data).length === 0) {
      setIsAdd(true);
      setIsEdit(true);
    }
  }, [isEdit]);

  useEffect(() => {
    const {
      isFailed,
      isSuccess,
      patientID,
      medicalRecordID,
      isFetchViewSuccess,
      isFetchRecordSuccess,
    } = logs;

    useCreateRecordResponse({
      trigger,
      isFailed,
      dispatch,
      isSuccess,
      patientID,
      setIsEdit,
      setTrigger,
      setShowScreen,
      medicalRecordID,
      isFetchViewSuccess,
      isFetchRecordSuccess,
    });

    if (isFetchRecordSuccess && trigger.medicalRecordID) {
      const data = medicalRecordID.mr_cert_of_death;

      setFormData(data);
    }
  }, [logs]);

  return (
    <>
      <Form className='death-form' noValidate validated={validated} onSubmit={handleSubmit}>
        <ConditionalRender
          condition={isPageOne}
          renderIf={
            <div className='mb-4 black-border-container'>
              <div className='black-row'>
                <div className='header'>
                  <p className='left-header'>
                    OCRG Form No. 101 <span>( Revised August 2021 )</span>
                  </p>

                  <div className='center-header'>
                    <p>Republic of the Philippines</p>
                    <p>OFFICE OF THE CIVIL REGISTRAT GENERAL</p>
                    <h2>CERTIFICATE OF DEATH</h2>
                  </div>

                  <p className='right-header'>
                    (To be accomplished in triplicate using black ink )
                  </p>
                </div>
              </div>

              <div className='black-row'>
                <Row className='m-0'>
                  <Col sm={8} className='block-container right-border'>
                    <Form.Group className='mb-3 input-group inline-label p-0'>
                      <Form.Label>Province</Form.Label>
                      <FormControl
                        placeholder='Enter Here'
                        value={formData?.province}
                        onChange={handleChange('province')}
                      />
                    </Form.Group>

                    <Form.Group className='input-group inline-label p-0'>
                      <Form.Label>City / Municipality</Form.Label>
                      <FormControl
                        placeholder='Enter Here'
                        value={formData?.city}
                        onChange={handleChange('city')}
                      />
                    </Form.Group>
                  </Col>
                  <Col className='block-container'>
                    <Form.Group className='input-group p-0'>
                      <Form.Label>Register No.</Form.Label>
                      <FormControl
                        placeholder='Enter Here'
                        value={formData?.register_no}
                        onChange={handleChange('register_no')}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>

              <div className='black-row'>
                <Row className='m-0'>
                  <Col className='block-container right-border'>
                    <h4 className='mb-2'>1. NAME</h4>

                    <div className='inline-group'>
                      <Form.Group className='input-group p-0'>
                        <Form.Label className='blue'>( First )</Form.Label>
                        <FormControl
                          placeholder='Enter Here'
                          value={formData?.fname}
                          // onChange={handleChange('fname')}
                          onClick={handleShowPatientSelection}
                        />
                      </Form.Group>

                      <Form.Group className='input-group p-0'>
                        <Form.Label className='blue'>( Middle )</Form.Label>
                        <FormControl
                          placeholder='Enter Here'
                          value={formData?.mname}
                          // onChange={handleChange('mname')}
                          onClick={handleShowPatientSelection}
                        />
                      </Form.Group>

                      <Form.Group className='input-group p-0'>
                        <Form.Label className='blue'>( Last )</Form.Label>
                        <FormControl
                          placeholder='Enter Here'
                          value={formData?.lname}
                          // onChange={handleChange('lname')}
                          onClick={handleShowPatientSelection}
                        />
                      </Form.Group>
                    </div>
                  </Col>

                  <Col sm={2} className='block-container'>
                    <h4 className='mb-2'>2. SEX</h4>

                    <div className='selections'>
                      <Form.Check
                        type='radio'
                        name='sex'
                        label='Male'
                        value='male'
                        checked={formData?.sex?.toLowerCase() === 'male'}
                        onChange={handleChange('sex')}
                      />
                      <Form.Check
                        type='radio'
                        name='sex'
                        label='Female'
                        value='female'
                        checked={formData?.sex?.toLowerCase() === 'female'}
                        onChange={handleChange('sex')}
                      />
                    </div>
                  </Col>
                </Row>
              </div>

              <div className='black-row'>
                <Row className='m-0'>
                  <Col sm={3} className='block-container right-border'>
                    <h4 className='mb-4'>3. DATE OF DEATH</h4>

                    <div className='inline-group'>
                      <Form.Group className='input-group p-0'>
                        <Form.Label className='blue'>( Day )</Form.Label>
                        {/* <FormControl placeholder='Enter Here' /> */}
                      </Form.Group>

                      <Form.Group className='input-group p-0'>
                        <Form.Label className='blue'>( Month )</Form.Label>
                        {/* <FormControl placeholder='Enter Here' /> */}
                      </Form.Group>

                      <Form.Group className='input-group p-0'>
                        <Form.Label className='blue'>( Year )</Form.Label>
                        {/* <FormControl placeholder='Enter Here' /> */}
                      </Form.Group>
                    </div>
                    <div className='inline-group'>
                      <DropdownDate
                        classes={{
                          dateContainer: 'date-container',
                          yearContainer: 'year-container',
                          monthContainer: 'month-container',
                          dayContainer: 'day-container',
                          year: 'dropdown-year',
                          month: 'dropdown-month',
                          day: 'dropdown-day',
                        }}
                        order={[
                          DropdownComponent.day,
                          DropdownComponent.month,
                          DropdownComponent.year,
                        ]}
                        selectedDate={DOD.selectedDate}
                        options={{
                          yearReverse: true,
                          monthShort: true,
                          monthCaps: true,
                        }}
                        onDateChange={(date) => {
                          const dateconfig = Moment(date).format('YYYY-MM-DD');
                          const date_separete = dateconfig.split('-');
                          setFormData((prev) => {
                            return {
                              ...prev,
                              day_of_death: date_separete[2],
                              month_of_death: date_separete[1],
                              year_of_death: date_separete[0],
                            };
                          });
                          setDOD((prev) => {
                            return { ...prev, selectedDate: Moment(date).format('YYYY-MM-DD') };
                          });
                        }}
                      />
                    </div>
                  </Col>

                  <Col sm={3} className='block-container right-border'>
                    <h4 className='mb-4'>4. DATE OF BIRTH</h4>

                    <div className='inline-group'>
                      <Form.Group className='input-group p-0'>
                        <Form.Label className='blue'>( Day )</Form.Label>
                        {/* <FormControl placeholder='Enter Here' /> */}
                      </Form.Group>

                      <Form.Group className='input-group p-0'>
                        <Form.Label className='blue'>( Month )</Form.Label>
                        {/* <FormControl placeholder='Enter Here' /> */}
                      </Form.Group>

                      <Form.Group className='input-group p-0'>
                        <Form.Label className='blue'>( Year )</Form.Label>
                        {/* <FormControl placeholder='Enter Here' /> */}
                      </Form.Group>
                    </div>
                    <div className='inline-group'>
                      <DropdownDate
                        classes={{
                          dateContainer: 'date-container',
                          yearContainer: 'year-container',
                          monthContainer: 'month-container',
                          dayContainer: 'day-container',
                          year: 'dropdown-year',
                          month: 'dropdown-month',
                          day: 'dropdown-day',
                        }}
                        order={[
                          DropdownComponent.day,
                          DropdownComponent.month,
                          DropdownComponent.year,
                        ]}
                        selectedDate={DOB.selectedDate}
                        options={{
                          yearReverse: true,
                          monthShort: true,
                          monthCaps: true,
                        }}
                        onDateChange={(date) => {
                          const dateconfig = Moment(date).format('YYYY-MM-DD');
                          const date_separete = dateconfig.split('-');
                          setFormData((prev) => {
                            return {
                              ...prev,
                              day_of_birth: date_separete[2],
                              month_of_birth: date_separete[1],
                              year_of_birth: date_separete[0],
                            };
                          });
                          setDOB((prev) => {
                            return { ...prev, selectedDate: Moment(date).format('YYYY-MM-DD') };
                          });
                        }}
                      />
                    </div>
                  </Col>

                  <Col className='m-0 p-0'>
                    <div className='black-row'>
                      <Row className='m-0'>
                        <Col className='block-container'>
                          <h4 className=''>
                            5. AGE AT THE TIME OF DEATH{' '}
                            <span className='blue'>
                              ( Fill - in below according to age category )
                            </span>
                          </h4>
                        </Col>
                      </Row>
                    </div>

                    <div className='black-row'>
                      <Row className='m-0'>
                        <Col className='block-container small right-border text-center'>
                          <p className='p-header blue m-0'>a. IF 1 YEAR OR ABOVE</p>
                        </Col>

                        <Col className='block-container small right-border text-center'>
                          <p className='p-header blue m-0'>b. IF UNDER 1 YEAR</p>
                        </Col>

                        <Col className='block-container small text-center'>
                          <p className='p-header blue m-0'>c. IF UNDER 24 HOURS</p>
                        </Col>
                      </Row>
                    </div>

                    <div className='black-row last-row'>
                      <Row className='m-0'>
                        <Col className='block-container small right-border text-center'>
                          <Form.Group className='input-group p-0'>
                            <Form.Label className='blue'>[ 2 ] Completed Years</Form.Label>
                            <FormControl
                              placeholder='Enter Here'
                              value={formData?.completed_years}
                              onChange={handleChange('completed_years')}
                            />
                          </Form.Group>
                        </Col>

                        <Col className='block-container small right-border text-center'>
                          <div className='inline-group'>
                            <Form.Group className='input-group p-0'>
                              <Form.Label className='blue'>[ 1 ] Months</Form.Label>
                              <FormControl
                                placeholder='Enter Here'
                                value={formData?.completed_months}
                                onChange={handleChange('completed_months')}
                              />
                            </Form.Group>
                            <Form.Group className='input-group p-0'>
                              <Form.Label className='blue'>[ 0 ] Days</Form.Label>
                              <FormControl
                                placeholder='Enter Here'
                                value={formData?.completed_days}
                                onChange={handleChange('completed_days')}
                              />
                            </Form.Group>
                          </div>
                        </Col>

                        <Col className='block-container small text-center'>
                          <div className='inline-group'>
                            <Form.Group className='input-group p-0'>
                              <Form.Label className='blue'>Hours</Form.Label>
                              <FormControl
                                placeholder='Enter Here'
                                value={formData?.completed_hours}
                                onChange={handleChange('completed_hours')}
                              />
                            </Form.Group>
                            <Form.Group className='input-group p-0'>
                              <Form.Label className='blue'>Min / Sec</Form.Label>
                              <FormControl
                                placeholder='Enter Here'
                                value={formData?.completed_minute_second}
                                onChange={handleChange('completed_minute_second')}
                              />
                            </Form.Group>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </div>

              <div className='black-row'>
                <Row className='m-0'>
                  <Col className='block-container right-border'>
                    <h4 className='mb-2'>6. PLACE OF DEATH</h4>

                    <div className='inline-group'>
                      <Form.Group className='input-group p-0'>
                        <Form.Label className='blue'>
                          ( Name of Hospital / Clinic / Institution )
                        </Form.Label>
                        <FormControl
                          placeholder='Enter Here'
                          value={formData?.place_of_death_hospital}
                          onChange={handleChange('place_of_death_hospital')}
                        />
                      </Form.Group>

                      <Form.Group className='input-group p-0'>
                        <Form.Label className='blue'>( House No., St., Barangay )</Form.Label>
                        <FormControl
                          placeholder='Enter Here'
                          value={formData?.place_of_death_house_no_st_brgy}
                          onChange={handleChange('place_of_death_house_no_st_brgy')}
                        />
                      </Form.Group>

                      <Form.Group className='input-group p-0'>
                        <Form.Label className='blue'>( City / Municipality )</Form.Label>
                        <FormControl
                          placeholder='Enter Here'
                          value={formData?.place_of_death_city}
                          onChange={handleChange('place_of_death_city')}
                        />
                      </Form.Group>

                      <Form.Group className='input-group p-0'>
                        <Form.Label className='blue'>( Province )</Form.Label>
                        <FormControl
                          placeholder='Enter Here'
                          value={formData?.place_of_death_province}
                          onChange={handleChange('place_of_death_province')}
                        />
                      </Form.Group>
                    </div>
                  </Col>
                  <Col sm={2} className='block-container flex-column'>
                    <h4 className='mb-2'>7. CIVIL STATUS</h4>
                    <Form.Group className='input-group p-0'>
                      <Form.Label className='blue'>
                        ( Single/ Married/ Widow/ Widower/ Annulled/ Divorced )
                      </Form.Label>
                      <FormControl
                        placeholder='Enter Here'
                        value={formData?.civil_status}
                        onChange={handleChange('civil_status')}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>

              <div className='black-row'>
                <Row className='m-0'>
                  <Col sm={3} className='block-container right-border'>
                    <h4 className='mb-2'>8. RELIGION / RELIGIOUS SECT</h4>

                    <Form.Group className='input-group p-0'>
                      <FormControl
                        placeholder='Enter Here'
                        value={formData?.religion}
                        onChange={handleChange('religion')}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={2} className='block-container right-border'>
                    <h4 className='mb-2'>9. CITIZENSHIP</h4>

                    <Form.Group className='input-group p-0'>
                      <FormControl
                        placeholder='Enter Here'
                        value={formData?.citizenship}
                        onChange={handleChange('citizenship')}
                      />
                    </Form.Group>
                  </Col>
                  <Col className='block-container'>
                    <h4 className='mb-2'>10. RESIDENCE</h4>

                    <div className='inline-group'>
                      <Form.Group className='input-group p-0'>
                        <Form.Label className='blue'>( House No., St., Barangay )</Form.Label>
                        <FormControl
                          placeholder='Enter Here'
                          value={formData?.residence_house_no_st_brgy}
                          onChange={handleChange('residence_house_no_st_brgy')}
                        />
                      </Form.Group>

                      <Form.Group className='input-group p-0'>
                        <Form.Label className='blue'>( City / Municipality )</Form.Label>
                        <FormControl
                          placeholder='Enter Here'
                          value={formData?.residence_city}
                          onChange={handleChange('residence_city')}
                        />
                      </Form.Group>

                      <Form.Group className='input-group p-0'>
                        <Form.Label className='blue'>( Province )</Form.Label>
                        <FormControl
                          placeholder='Enter Here'
                          value={formData?.residence_province}
                          onChange={handleChange('residence_province')}
                        />
                      </Form.Group>

                      <Form.Group className='input-group p-0'>
                        <Form.Label className='blue'>( Country )</Form.Label>
                        <FormControl
                          placeholder='Enter Here'
                          value={formData?.residence_country}
                          onChange={handleChange('residence_country')}
                        />
                      </Form.Group>
                    </div>
                  </Col>
                </Row>
              </div>

              <div className='black-row'>
                <Row className='m-0'>
                  <Col sm={2} className='block-container right-border'>
                    <h4 className='mb-2'>11. OCCUPATION</h4>

                    <Form.Group className='input-group p-0'>
                      <FormControl
                        placeholder='Enter Here'
                        value={formData?.occupation}
                        onChange={handleChange('occupation')}
                      />
                    </Form.Group>
                  </Col>

                  <Col sm={5} className='block-container right-border'>
                    <h4 className='mb-2'>
                      12. NAME OF FATHER <span className='blue'>( First, Middle, Last )</span>
                    </h4>

                    <Form.Group className='input-group p-0'>
                      <FormControl
                        placeholder='Enter Here'
                        value={formData?.name_of_father}
                        onChange={handleChange('name_of_father')}
                      />
                    </Form.Group>
                  </Col>

                  <Col sm={5} className='block-container'>
                    <h4 className='mb-2'>
                      13. NAME OF MOTHER <span className='blue'>( First, Middle, Last )</span>
                    </h4>

                    <Form.Group className='input-group p-0'>
                      <FormControl
                        placeholder='Enter Here'
                        value={formData?.name_of_mother}
                        onChange={handleChange('name_of_mother')}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>

              <div className='black-row'>
                <Row className='m-0'>
                  <Col className='block-container medical-certificate'>
                    <h2>MEDICAL CERTIFICATE</h2>
                    <p>( For Ages 0 to 7 days, accomplish items 14 - 19a at the back )</p>
                  </Col>
                </Row>
              </div>

              <div className='black-row'>
                <Row className='m-0'>
                  <Col sm={8} className='block-container pb-0'>
                    <h4 className='mb-2'>
                      19b. CAUSES OF DEATH ( If the deceased is aged 8 days and over )
                    </h4>

                    <Form.Group className='mb-3 inline-label input-group p-0'>
                      <Form.Label>I. Immediate cause : a.</Form.Label>
                      <FormControl
                        placeholder='Enter Here'
                        value={formData?.immediate_cause}
                        onChange={handleChange('immediate_cause')}
                      />
                    </Form.Group>

                    <Form.Group className='mb-3 inline-label input-group p-0'>
                      <Form.Label>Antecedent cause : b.</Form.Label>
                      <FormControl
                        placeholder='Enter Here'
                        value={formData?.antecedent_cause}
                        onChange={handleChange('antecedent_cause')}
                      />
                    </Form.Group>

                    <Form.Group className='inline-label input-group p-0'>
                      <Form.Label>Underlying cause : c.</Form.Label>
                      <FormControl
                        placeholder='Enter Here'
                        value={formData?.underlying_cause}
                        onChange={handleChange('underlying_cause')}
                      />
                    </Form.Group>
                  </Col>

                  <Col className='block-container pb-0'>
                    <h4 className='mb-2'>Internal Between Onset and Death</h4>

                    <Form.Group className='mb-3 input-group p-0'>
                      <FormControl
                        placeholder='Enter Here'
                        value={formData?.immediate_cause_interval_between_onset_and_death}
                        onChange={handleChange('immediate_cause_interval_between_onset_and_death')}
                      />
                    </Form.Group>

                    <Form.Group className='mb-3 input-group p-0'>
                      <FormControl
                        placeholder='Enter Here'
                        value={formData?.antecedent_cause_interval_between_onset_and_death}
                        onChange={handleChange('antecedent_cause_interval_between_onset_and_death')}
                      />
                    </Form.Group>

                    <Form.Group className='input-group p-0'>
                      <FormControl
                        placeholder='Enter Here'
                        value={formData?.underlying_cause_interval_between_onset_and_death}
                        onChange={handleChange('underlying_cause_interval_between_onset_and_death')}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row className='m-0'>
                  <Col className='block-container'>
                    <Form.Group className='inline-label input-group p-0'>
                      <Form.Label>
                        II. Other significant conditions contributing to death:
                      </Form.Label>
                      <FormControl
                        placeholder='Enter Here'
                        value={formData?.other_condition_contributing_to_death}
                        onChange={handleChange('other_condition_contributing_to_death')}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>

              <div className='black-row'>
                <Row className='m-0'>
                  <Col className='block-container'>
                    <h4 className='mb-2'>
                      19c. MATERNAL CONDITION ( If the deceased is female aged 15-49 years old )
                    </h4>

                    <div className='selections inline'>
                      <Form.Check
                        type='radio'
                        name='maternal-condition'
                        label='a. pregnant, not in labour'
                        value='a. pregnant, not in labour'
                        checked={formData?.maternal_condition === 'a. pregnant, not in labour'}
                        onChange={handleChange('maternal_condition')}
                      />
                      <Form.Check
                        type='radio'
                        name='maternal-condition'
                        label='b. pregnant, in labour'
                        value='b. pregnant, in labour'
                        checked={formData?.maternal_condition === 'b. pregnant, in labour'}
                        onChange={handleChange('maternal_condition')}
                      />
                      <Form.Check
                        type='radio'
                        name='maternal-condition'
                        label='c. less than 42 days after delivery'
                        value='c. less than 42 days after delivery'
                        checked={
                          formData?.maternal_condition === 'c. less than 42 days after delivery'
                        }
                        onChange={handleChange('maternal_condition')}
                      />
                      <Form.Check
                        type='radio'
                        name='maternal-condition'
                        label='d. 42 days to 1 year after delivery'
                        value='d. 42 days to 1 year after delivery'
                        checked={
                          formData?.maternal_condition === 'd. 42 days to 1 year after delivery'
                        }
                        onChange={handleChange('maternal_condition')}
                      />
                      <Form.Check
                        type='radio'
                        name='maternal-condition'
                        label='e. None of the choices'
                        value='e. None of the choices'
                        checked={formData?.maternal_condition === 'e. None of the choices'}
                        onChange={handleChange('maternal_condition')}
                      />
                    </div>
                  </Col>
                </Row>
              </div>

              <div className='black-row'>
                <Row className='m-0'>
                  <Col sm={10} className='block-container right-border'>
                    <h4 className='mb-2'>19d. DEATH BY EXTERNAL CAUSES</h4>

                    <Form.Group className='mb-3 inline-label input-group p-0'>
                      <Form.Label>
                        a. Manner of death{' '}
                        <span className='blue'>
                          ( Homicide, Suicide, Accident, Legal intervention, etc. )
                        </span>
                      </Form.Label>
                      <FormControl
                        placeholder='Enter Here'
                        value={formData?.external_manner_of_death}
                        onChange={handleChange('external_manner_of_death')}
                      />
                    </Form.Group>

                    <Form.Group className='mb-3 inline-label input-group p-0'>
                      <Form.Label>
                        b. Place of Occurrence of External Cause{' '}
                        <span className='blue'>(e.g. home, farm, factory, street, sea, etc. )</span>
                      </Form.Label>
                      <FormControl
                        placeholder='Enter Here'
                        value={formData?.external_place_of_occurrent_of_external_cause}
                        onChange={handleChange('external_place_of_occurrent_of_external_cause')}
                      />
                    </Form.Group>
                  </Col>
                  <Col className='block-container'>
                    <h4 className='mb-2'>AUTOPSY</h4>

                    <div className='selections'>
                      <Form.Check
                        type='radio'
                        name='autopsy'
                        label='Yes'
                        value='Yes'
                        checked={formData?.is_autopsy === 'Yes'}
                        onChange={handleChange('is_autopsy')}
                      />
                      <Form.Check
                        type='radio'
                        name='autopsy'
                        label='No'
                        value='No'
                        checked={formData?.is_autopsy === 'No'}
                        onChange={handleChange('is_autopsy')}
                      />
                    </div>
                  </Col>
                </Row>
              </div>

              <div className='black-row'>
                <Row className='m-0'>
                  <Col sm={8} className='block-container right-border'>
                    <h4 className='mb-2'>21a. ATTENDANT</h4>

                    <div className='selections mb-2 inline'>
                      <Form.Check
                        type='radio'
                        name='attendant'
                        label='1. Private Physician'
                        value='1. Private Physician'
                        checked={formData?.attendant === '1. Private Physician'}
                        onChange={handleChange('attendant')}
                      />
                      <Form.Check
                        type='radio'
                        name='attendant'
                        label='3. Hospital Authority'
                        value='3. Hospital Authority'
                        checked={formData?.attendant === '3. Hospital Authority'}
                        onChange={handleChange('attendant')}
                      />

                      <div className='d-flex checkbox-textbox inline'>
                        <Form.Check
                          type='radio'
                          name='attendant'
                          label='5. Others ( Specify )'
                          value='5. Others ( Specify )'
                          checked={formData?.attendant === '5. Others ( Specify )'}
                          onChange={handleChange('attendant')}
                        />
                        <Form.Group>
                          <FormControl
                            placeholder='Enter Here'
                            value={formData?.others_please_specify}
                            onChange={handleChange('others_please_specify')}
                          />
                        </Form.Group>
                      </div>
                    </div>

                    <div className='selections inline'>
                      <Form.Check
                        type='radio'
                        name='attendant'
                        label='2. Public Health Officer'
                        value='2. Public Health Officer'
                        checked={formData?.attendant === '2. Public Health Officer'}
                        onChange={handleChange('attendant')}
                      />
                      <Form.Check
                        type='radio'
                        name='attendant'
                        label='4. None'
                        value='4. None'
                        checked={formData?.attendant === '4. None'}
                        onChange={handleChange('attendant')}
                      />
                    </div>
                  </Col>
                  <Col className='block-container'>
                    <h4 className='mb-2'>
                      21b. If attended, state duration <span className='blue'>( mm/dd/yyyy )</span>
                    </h4>

                    <div className='inline-group'>
                      <Form.Group className='input-group inline-label p-0'>
                        <Form.Label>From</Form.Label>
                        <FormControl
                          type='date'
                          placeholder='Enter Here'
                          value={formData?.duration_start_date}
                          onChange={handleChange('duration_start_date')}
                        />
                      </Form.Group>

                      <Form.Group className='input-group inline-label p-0'>
                        <Form.Label>To</Form.Label>
                        <FormControl
                          type='date'
                          placeholder='Enter Here'
                          value={formData?.duration_end_date}
                          onChange={handleChange('duration_end_date')}
                        />
                      </Form.Group>
                    </div>
                  </Col>
                </Row>
              </div>

              <div className='black-row'>
                <Row className='m-0'>
                  <Col className='block-container'>
                    <h4 className='mb-2'>22. CERTIFICATE OF DEATH</h4>

                    <p className='certify'>
                      I hereby certify that the forgoing particulars are correct as near as same can
                      be ascertained and I further certify that I{' '}
                      <Form.Check
                        className='form-check'
                        type='radio'
                        name='attended-hearby-death'
                        value={'true'}
                        checked={formData?.cert_of_death_have_attended === 'true'}
                        onChange={handleChange('cert_of_death_have_attended')}
                      ></Form.Check>{' '}
                      have attended /{' '}
                      <Form.Check
                        type='radio'
                        className='form-check'
                        name='attended-hearby-death'
                        value={'true'}
                        checked={formData?.cert_of_death_have_attended === 'true'}
                        onChange={handleChange('cert_of_death_have_not_attended')}
                      ></Form.Check>{' '}
                      have not attended the deceased and that death occurred at am/pm on the date of
                      death of specified above.
                    </p>
                  </Col>
                </Row>

                <Row className='m-0'>
                  <Col className='block-container'>
                    <Form.Group className='mb-3 input-group inline-label no-border p-0'>
                      <Form.Label>Signature</Form.Label>
                      <FormControl
                        value={formData?.cert_of_death_signature}
                        onChange={handleChange('cert_of_death_signature')}
                      />
                    </Form.Group>

                    <Form.Group className='mb-3 input-group inline-label p-0'>
                      <Form.Label>Name in Print</Form.Label>
                      <FormControl
                        placeholder='Enter Here'
                        value={formData?.cert_of_death_name_in_print}
                        onChange={handleChange('cert_of_death_name_in_print')}
                      />
                    </Form.Group>

                    <Form.Group className='mb-3 input-group inline-label p-0'>
                      <Form.Label>Title or Position</Form.Label>
                      <FormControl
                        placeholder='Enter Here'
                        value={formData?.cert_of_death_title_or_position}
                        onChange={handleChange('cert_of_death_title_or_position')}
                      />
                    </Form.Group>

                    <Form.Group className='mb-3 input-group inline-label p-0'>
                      <Form.Label>Address</Form.Label>
                      <FormControl
                        placeholder='Enter Here'
                        value={formData?.cert_of_death_address}
                        onChange={handleChange('cert_of_death_address')}
                      />
                    </Form.Group>

                    <Form.Group className='mb-3 input-group inline-label p-0'>
                      <Form.Label>Date</Form.Label>
                      <FormControl
                        type='date'
                        placeholder='Enter Here'
                        value={formData?.cert_of_death_date}
                        onChange={handleChange('cert_of_death_date')}
                      />
                    </Form.Group>
                  </Col>
                  <Col className='block-container small-border'>
                    <h4 className='mb-5'>REVIEWED BY:</h4>

                    <div className='mb-5 signature m-auto'>
                      <FormControl
                        placeholder='Enter Here'
                        value={formData?.cert_of_death_signature_over_printed_name}
                        onChange={handleChange('cert_of_death_signature_over_printed_name')}
                      />
                      <p className='over-printed-name'>
                        Signature Over Printed Name of Health Officer
                      </p>
                    </div>

                    <div className='mb-5 signature m-auto'>
                      <FormControl
                        type='date'
                        placeholder='Enter Here'
                        value={formData?.cert_of_death_reviewed_date}
                        onChange={handleChange('cert_of_death_reviewed_date')}
                      />
                      <p className='over-printed-name'>Date</p>
                    </div>
                  </Col>
                </Row>
              </div>

              <div className='black-row'>
                <Row className='m-0'>
                  <Col className='block-container'>
                    <h4 className='mb-2'>
                      23. CORPSE DISPOSAL{' '}
                      <span className='blue'>( Burial, Cremation, if others, specify )</span>
                    </h4>

                    <Form.Group className='input-group inline-label p-0'>
                      <FormControl
                        placeholder='Enter Here'
                        value={formData?.corpse_disposal}
                        onChange={handleChange('corpse_disposal')}
                      />
                    </Form.Group>
                  </Col>
                  <Col className='block-container'>
                    <h4 className='mb-2'>24a. BURIAL / CREMATION PERMIT</h4>

                    <Form.Group className='input-group mb-3 inline-label p-0'>
                      <Form.Label>Number</Form.Label>
                      <FormControl
                        placeholder='Enter Here'
                        value={formData?.burial_cremation_permit_no}
                        onChange={handleChange('burial_cremation_permit_no')}
                      />
                    </Form.Group>

                    <Form.Group className='input-group inline-label p-0'>
                      <Form.Label>Date Issued</Form.Label>
                      <FormControl
                        type='date'
                        placeholder='Enter Here'
                        value={formData?.burial_cremation_permit_date_issued}
                        onChange={handleChange('burial_cremation_permit_date_issued')}
                      />
                    </Form.Group>
                  </Col>
                  <Col className='block-container'>
                    <h4 className='mb-2'>24b. TRANSFER PERMIT</h4>

                    <Form.Group className='input-group mb-3 inline-label p-0'>
                      <Form.Label>Number</Form.Label>
                      <FormControl
                        placeholder='Enter Here'
                        value={formData?.transfer_permit_no}
                        onChange={handleChange('transfer_permit_no')}
                      />
                    </Form.Group>

                    <Form.Group className='input-group inline-label p-0'>
                      <Form.Label>Date Issued</Form.Label>
                      <FormControl
                        type='date'
                        placeholder='Enter Here'
                        value={formData?.transfer_permit_date_issued}
                        onChange={handleChange('transfer_permit_date_issued')}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>

              <div className='black-row'>
                <Row className='m-0'>
                  <Col className='block-container'>
                    <h4 className='mb-2'>25. NAME AND ADDRESS OF CEMETERY OR CREMATORY</h4>

                    <Form.Group className='input-group inline-label p-0'>
                      <FormControl
                        placeholder='Enter Here'
                        value={formData?.name_and_address_of_cemetery_or_crematory}
                        onChange={handleChange('name_and_address_of_cemetery_or_crematory')}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>

              <div className='black-row'>
                <Row className='m-0'>
                  <Col className='block-container right-border'>
                    <h4 className='mb-2'>26. CERTIFICATION OF INFORMAT</h4>

                    <p className='certify'>
                      I hereby certify that all information supplied are true and correct to my own
                      knowledge and belief.
                    </p>

                    <Form.Group className='mb-3 input-group inline-label no-border p-0'>
                      <Form.Label>Signature</Form.Label>
                      <FormControl
                        value={formData?.cert_of_informat_signature}
                        onChange={handleChange('cert_of_informat_signature')}
                      />
                    </Form.Group>

                    <Form.Group className='mb-3 input-group inline-label p-0'>
                      <Form.Label>Name in Print</Form.Label>
                      <FormControl
                        placeholder='Enter Here'
                        value={formData?.cert_of_informat_name_in_print}
                        onChange={handleChange('cert_of_informat_name_in_print')}
                      />
                    </Form.Group>

                    <Form.Group className='mb-3 input-group inline-label p-0'>
                      <Form.Label>Title or Position</Form.Label>
                      <FormControl
                        placeholder='Enter Here'
                        value={formData?.cert_of_informat_relation_to_deceased}
                        onChange={handleChange('cert_of_informat_relation_to_deceased')}
                      />
                    </Form.Group>

                    <Form.Group className='mb-3 input-group inline-label p-0'>
                      <Form.Label>Address</Form.Label>
                      <FormControl
                        placeholder='Enter Here'
                        value={formData?.cert_of_informat_address}
                        onChange={handleChange('cert_of_informat_address')}
                      />
                    </Form.Group>

                    <Form.Group className='mb-3 input-group inline-label p-0'>
                      <Form.Label>Date</Form.Label>
                      <FormControl
                        type='date'
                        placeholder='Enter Here'
                        value={formData?.cert_of_informat_date}
                        onChange={handleChange('cert_of_informat_date')}
                      />
                    </Form.Group>
                  </Col>

                  <Col className='block-container'>
                    <h4 className='mb-2'>27. PREPARED BY</h4>

                    <Form.Group className='mb-3 input-group inline-label no-border p-0'>
                      <Form.Label>Signature</Form.Label>
                      <FormControl
                        value={formData?.prepared_by_signature}
                        onChange={handleChange('prepared_by_signature')}
                      />
                    </Form.Group>

                    <Form.Group className='mb-3 input-group inline-label p-0'>
                      <Form.Label>Name in Print</Form.Label>
                      <FormControl
                        placeholder='Enter Here'
                        value={formData?.prepared_by_name_in_print}
                        onChange={handleChange('prepared_by_name_in_print')}
                      />
                    </Form.Group>

                    <Form.Group className='mb-3 input-group inline-label p-0'>
                      <Form.Label>Title or Position</Form.Label>
                      <FormControl
                        placeholder='Enter Here'
                        value={formData?.prepared_by_title_or_position}
                        onChange={handleChange('prepared_by_title_or_position')}
                      />
                    </Form.Group>

                    <Form.Group className='mb-3 input-group inline-label p-0'>
                      <Form.Label>Address</Form.Label>
                      <FormControl
                        placeholder='Enter Here'
                        value={formData?.prepared_by_address}
                        onChange={handleChange('prepared_by_address')}
                      />
                    </Form.Group>

                    <Form.Group className='mb-3 input-group inline-label p-0'>
                      <Form.Label>Date</Form.Label>
                      <FormControl
                        type='date'
                        placeholder='Enter Here'
                        value={formData?.prepared_by_date}
                        onChange={handleChange('prepared_by_date')}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>

              <div className='black-row'>
                <Row className='m-0'>
                  <Col className='block-container right-border'>
                    <h4 className='mb-2'>28. RECEIVED BY</h4>

                    <Form.Group className='mb-3 input-group inline-label no-border p-0'>
                      <Form.Label>Signature</Form.Label>
                      <FormControl
                        value={formData?.recieved_by_signature}
                        onChange={handleChange('recieved_by_signature')}
                      />
                    </Form.Group>

                    <Form.Group className='mb-3 input-group inline-label p-0'>
                      <Form.Label>Name in Print</Form.Label>
                      <FormControl
                        placeholder='Enter Here'
                        value={formData?.recieved_by_name_in_print}
                        onChange={handleChange('recieved_by_name_in_print')}
                      />
                    </Form.Group>

                    <Form.Group className='mb-3 input-group inline-label p-0'>
                      <Form.Label>Title or Position</Form.Label>
                      <FormControl
                        placeholder='Enter Here'
                        value={formData?.recieved_by_title_or_position}
                        onChange={handleChange('recieved_by_title_or_position')}
                      />
                    </Form.Group>

                    <Form.Group className='mb-3 input-group inline-label p-0'>
                      <Form.Label>Address</Form.Label>
                      <FormControl
                        placeholder='Enter Here'
                        value={formData?.recieved_by_address}
                        onChange={handleChange('recieved_by_address')}
                      />
                    </Form.Group>

                    <Form.Group className='mb-3 input-group inline-label p-0'>
                      <Form.Label>Date</Form.Label>
                      <FormControl
                        type='date'
                        placeholder='Enter Here'
                        value={formData?.recieved_by_date}
                        onChange={handleChange('recieved_by_date')}
                      />
                    </Form.Group>
                  </Col>

                  <Col className='block-container'>
                    <h4 className='mb-2'>29. REGISTERED BY THE CIVIL REGISTRAR</h4>

                    <Form.Group className='mb-3 input-group inline-label no-border p-0'>
                      <Form.Label>Signature</Form.Label>
                      <FormControl
                        value={formData?.civil_registrar_signature}
                        onChange={handleChange('civil_registrar_signature')}
                      />
                    </Form.Group>

                    <Form.Group className='mb-3 input-group inline-label p-0'>
                      <Form.Label>Name in Print</Form.Label>
                      <FormControl
                        placeholder='Enter Here'
                        value={formData?.civil_registrar_name_in_print}
                        onChange={handleChange('civil_registrar_name_in_print')}
                      />
                    </Form.Group>

                    <Form.Group className='mb-3 input-group inline-label p-0'>
                      <Form.Label>Title or Position</Form.Label>
                      <FormControl
                        placeholder='Enter Here'
                        value={formData?.civil_registrar_title_or_position}
                        onChange={handleChange('civil_registrar_title_or_position')}
                      />
                    </Form.Group>

                    <Form.Group className='mb-3 input-group inline-label p-0'>
                      <Form.Label>Address</Form.Label>
                      <FormControl
                        placeholder='Enter Here'
                        value={formData?.civil_registrar_address}
                        onChange={handleChange('civil_registrar_address')}
                      />
                    </Form.Group>

                    <Form.Group className='mb-3 input-group inline-label p-0'>
                      <Form.Label>Date</Form.Label>
                      <FormControl
                        type='date'
                        placeholder='Enter Here'
                        value={formData?.civil_registrar_date}
                        onChange={handleChange('civil_registrar_date')}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>

              <div className='black-row remarks-row'>
                <Row className='m-0'>
                  <Col className='block-container'>
                    <h2>REMARKS / ANNOTATIONS ( For LCRO / OCRG Use Only )</h2>
                  </Col>
                </Row>
              </div>

              <div className='black-row last-row'>
                <Row className='m-0'>
                  <Col className='block-container'>
                    <h2>TO BE FILLED - UP AT THE OFFICE OF THE CIVIL REGISTRAR</h2>

                    <div className='boxes-cont'>
                      <div className='boxes'>
                        <p className='mb-1'>5</p>

                        <div className='boxes-count'>
                          <div className='box'></div>
                          <div className='box'></div>
                          <div className='box'></div>
                        </div>
                      </div>

                      <div className='boxes'>
                        <p className='mb-1'>8</p>

                        <div className='boxes-count'>
                          <div className='box'></div>
                          <div className='box'></div>
                        </div>
                      </div>

                      <div className='boxes'>
                        <p className='mb-1'>9</p>

                        <div className='boxes-count'>
                          <div className='box'></div>
                          <div className='box'></div>
                        </div>
                      </div>

                      <div className='boxes'>
                        <p className='mb-1'>10</p>

                        <div className='boxes-count'>
                          <div className='box'></div>
                          <div className='box'></div>
                          <div className='box'></div>
                          <div className='box'></div>
                          <div className='box'></div>
                          <div className='box'></div>
                          <div className='box'></div>
                          <div className='box'></div>
                        </div>
                      </div>

                      <div className='boxes'>
                        <p className='mb-1'>11</p>

                        <div className='boxes-count'>
                          <div className='box'></div>
                          <div className='box'></div>
                          <div className='box'></div>
                        </div>
                      </div>

                      <div className='boxes'>
                        <p className='mb-1'>19a(a)/19b</p>

                        <div className='boxes-count'>
                          <div className='box'></div>
                          <div className='box'></div>
                          <div className='box'></div>
                          <div className='box'></div>
                        </div>
                      </div>

                      <div className='boxes'>
                        <p className='mb-1'>19a(c)</p>

                        <div className='boxes-count'>
                          <div className='box'></div>
                          <div className='box'></div>
                          <div className='box'></div>
                          <div className='box'></div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          }
        />

        <ConditionalRender
          condition={isPageTwo}
          renderIf={
            <>
              <div className='mb-4 black-border-container'>
                <div className='black-row'>
                  <Row className='m-0'>
                    <Col className='block-container medical-certificate'>
                      <h2 className='m-0'>FOR CHILDREN AGED 0 TO 7 DAYS</h2>
                    </Col>
                  </Row>
                </div>

                <div className='black-row'>
                  <Row className='m-0'>
                    <Col sm={2} className='block-container'>
                      <h4 className='mb-2'>14. AGE OF MOTHER</h4>

                      <Form.Group className='input-group p-0'>
                        <FormControl
                          placeholder='Enter Here'
                          value={formData?.age_of_mother}
                          onChange={handleChange('age_of_mother')}
                        />
                      </Form.Group>
                    </Col>

                    <Col sm={5} className='block-container'>
                      <h4 className='mb-2'>
                        15. METHOD OF DELIVERY{' '}
                        <span className='blue'>
                          ( Normal spontaneous vertex, if others, specify )
                        </span>
                      </h4>

                      <Form.Group className='input-group p-0'>
                        <FormControl
                          placeholder='Enter Here'
                          value={formData?.method_of_delivery}
                          onChange={handleChange('method_of_delivery')}
                        />
                      </Form.Group>
                    </Col>

                    <Col sm={5} className='block-container'>
                      <h4 className='mb-2'>
                        16. LENGTH OF PREGNANCY <span className='blue'>( In complete weeks )</span>
                      </h4>

                      <Form.Group className='input-group p-0'>
                        <FormControl
                          placeholder='Enter Here'
                          value={formData?.length_of_pregnancy_in_weeks}
                          onChange={handleChange('length_of_pregnancy_in_weeks')}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </div>

                <div className='black-row'>
                  <Row className='m-0'>
                    <Col className='block-container'>
                      <h4 className='mb-2'>
                        17. TYPE OF BIRTH{' '}
                        <span className='blue'>( Single, Twin, Triplet, Etc. )</span>
                      </h4>

                      <Form.Group className='input-group p-0'>
                        <FormControl
                          placeholder='Enter Here'
                          value={formData?.type_of_birth}
                          onChange={handleChange('type_of_birth')}
                        />
                      </Form.Group>
                    </Col>

                    <Col className='block-container'>
                      <h4 className='mb-2'>
                        18. IF MULTIPLE BIRHT. CHILD WAS{' '}
                        <span className='blue'>( First, Second, Third, Etc. )</span>
                      </h4>

                      <Form.Group className='input-group p-0'>
                        <FormControl
                          placeholder='Enter Here'
                          value={formData?.if_multiple_child_was}
                          onChange={handleChange('if_multiple_child_was')}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </div>

                <div className='black-row'>
                  <Row className='m-0'>
                    <Col className='block-container medical-certificate'>
                      <h2 className='m-0'>MEDICAL CERTIFICATE</h2>
                    </Col>
                  </Row>
                </div>

                <div className='black-row last-row'>
                  <Row className='m-0'>
                    <Col className='block-container pb-0'>
                      <h4 className='mb-2'>19a. CAUSES OF DEATH</h4>

                      <Form.Group className='mb-3 inline-label input-group p-0'>
                        <Form.Label>a. Main disease / condition of infant</Form.Label>
                        <FormControl
                          placeholder='Enter Here'
                          value={formData?.main_disease}
                          onChange={handleChange('main_disease')}
                        />
                      </Form.Group>

                      <Form.Group className='mb-3 inline-label input-group p-0'>
                        <Form.Label>b. Other diseases / conditions of infant</Form.Label>
                        <FormControl
                          placeholder='Enter Here'
                          value={formData?.other_diseases}
                          onChange={handleChange('other_diseases')}
                        />
                      </Form.Group>

                      <Form.Group className='inline-label input-group p-0'>
                        <Form.Label>
                          c. Main maternal diseases / condition affecting infant
                        </Form.Label>
                        <FormControl
                          placeholder='Enter Here'
                          value={formData?.main_maternal_diseases}
                          onChange={handleChange('main_maternal_diseases')}
                        />
                      </Form.Group>

                      <Form.Group className='inline-label input-group p-0'>
                        <Form.Label>
                          d. Other maternal disease / condition affecting infant
                        </Form.Label>
                        <FormControl
                          placeholder='Enter Here'
                          value={formData?.other_maternal_diseases}
                          onChange={handleChange('other_maternal_diseases')}
                        />
                      </Form.Group>

                      <Form.Group className='mb-3 inline-label input-group p-0'>
                        <Form.Label>e. Other relevant circumtances</Form.Label>
                        <FormControl
                          placeholder='Enter Here'
                          value={formData?.other_relevant_circumstances}
                          onChange={handleChange('other_relevant_circumstances')}
                        />
                      </Form.Group>

                      <h6 className='text-center mb-3'>CONTINUE TO FILL UP ITEM 20</h6>
                    </Col>
                  </Row>
                </div>
              </div>

              <div className='mb-4 black-border-container'>
                <div className='black-row'>
                  <Row className='m-0'>
                    <Col className='block-container medical-certificate'>
                      <h2 className='m-0'>POSTMORTEM CERTIFICATION OF DEATH</h2>
                    </Col>
                  </Row>
                </div>

                <div className='black-row last-row'>
                  <Row className='m-0'>
                    <Col className='block-container'>
                      <h4 className='mb-2 text-center'>
                        I HEREBY VERTIFY that I have performed an autopsy upon the body of the
                        deceased and that cause of death was
                      </h4>

                      <Form.Group className='input-group p-0'>
                        <FormControl
                          placeholder='Enter Here'
                          value={formData?.cause_of_death}
                          onChange={handleChange('cause_of_death')}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className='m-0'>
                    <Col className='block-container'>
                      <Form.Group className='mb-3 input-group inline-label no-border p-0'>
                        <Form.Label>Signature</Form.Label>
                        <FormControl
                          value={formData?.postmortem_signature}
                          onChange={handleChange('postmortem_signature')}
                        />
                      </Form.Group>

                      <Form.Group className='mb-3 input-group inline-label p-0'>
                        <Form.Label>Name in Print</Form.Label>
                        <FormControl
                          placeholder='Enter Here'
                          value={formData?.postmortem_name_in_print}
                          onChange={handleChange('postmortem_name_in_print')}
                        />
                      </Form.Group>

                      <Form.Group className='mb-3 input-group inline-label p-0'>
                        <Form.Label>Date</Form.Label>
                        <FormControl
                          type='date'
                          placeholder='Enter Here'
                          value={formData?.postmortem_date}
                          onChange={handleChange('postmortem_date')}
                        />
                      </Form.Group>
                    </Col>
                    <Col className='block-container'>
                      <Form.Group className='mb-3 input-group inline-label p-0'>
                        <Form.Label>Title or Position</Form.Label>
                        <FormControl
                          placeholder='Enter Here'
                          value={formData?.postmortem_title_designation}
                          onChange={handleChange('postmortem_title_designation')}
                        />
                      </Form.Group>

                      <Form.Group className='mb-3 input-group inline-label p-0'>
                        <Form.Label>Address</Form.Label>
                        <FormControl
                          placeholder='Enter Here'
                          value={formData?.postmortem_address}
                          onChange={handleChange('postmortem_address')}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
              </div>

              <div className='mb-4 black-border-container'>
                <div className='black-row'>
                  <Row className='m-0'>
                    <Col className='block-container medical-certificate'>
                      <h2 className='m-0'>CERTIFICATION OF EMBALMER</h2>
                    </Col>
                  </Row>
                </div>

                <div className='black-row last-row'>
                  <Row className='m-0'>
                    <Col className='block-container'>
                      <h4 className='mb-2 text-center'>
                        I HEREBY VERIFY that I have embalmed
                        <FormControl value={formData?.name} onChange={handleChange('name')} />
                        following all the regulations prescribed by the Department of Health.
                      </h4>
                    </Col>
                  </Row>

                  <Row className='m-0'>
                    <Col className='block-container'>
                      <Form.Group className='mb-3 input-group inline-label no-border p-0'>
                        <Form.Label>Signature</Form.Label>
                        <FormControl
                          value={formData?.embalmer_signature}
                          onChange={handleChange('embalmer_signature')}
                        />
                      </Form.Group>

                      <Form.Group className='mb-3 input-group inline-label p-0'>
                        <Form.Label>Name in Print</Form.Label>
                        <FormControl
                          placeholder='Enter Here'
                          value={formData?.embalmer_name_in_print}
                          onChange={handleChange('embalmer_name_in_print')}
                        />
                      </Form.Group>

                      <Form.Group className='mb-3 input-group inline-label p-0'>
                        <Form.Label>Date</Form.Label>
                        <FormControl
                          type='date'
                          placeholder='Enter Here'
                          value={formData?.embalmer_date}
                          onChange={handleChange('embalmer_date')}
                        />
                      </Form.Group>
                    </Col>
                    <Col className='block-container'>
                      <Form.Group className='mb-3 input-group inline-label p-0'>
                        <Form.Label>Title/Designation</Form.Label>
                        <FormControl
                          placeholder='Enter Here'
                          value={formData?.embalmer_title_designation}
                          onChange={handleChange('embalmer_title_designation')}
                        />
                      </Form.Group>

                      <Form.Group className='mb-3 input-group inline-label p-0'>
                        <Form.Label>License No.</Form.Label>
                        <FormControl
                          placeholder='Enter Here'
                          value={formData?.embalmer_license_no}
                          onChange={handleChange('embalmer_license_no')}
                        />
                      </Form.Group>

                      <div className='inline-group'>
                        <Form.Group className='mb-3 input-group inline-label p-0'>
                          <Form.Label>Issued on</Form.Label>
                          <FormControl
                            placeholder='Enter Here'
                            value={formData?.embalmer_license_issued_on}
                            onChange={handleChange('embalmer_license_issued_on')}
                          />
                        </Form.Group>

                        <Form.Group className='mb-3 input-group inline-label p-0'>
                          <Form.Label>at</Form.Label>
                          <FormControl
                            placeholder='Enter Here'
                            value={formData?.embalmer_license_issued_at}
                            onChange={handleChange('embalmer_license_issued_at')}
                          />
                        </Form.Group>
                      </div>

                      <Form.Group className='mb-3 input-group inline-label p-0'>
                        <Form.Label>Expiry Date</Form.Label>
                        <FormControl
                          type='date'
                          placeholder='Enter Here'
                          value={formData?.embalmer_license_expiry_date}
                          onChange={handleChange('embalmer_license_expiry_date')}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
              </div>

              <div className='mb-4 black-border-container'>
                <div className='black-row last-row'>
                  <Row className='m-0'>
                    <Col className='block-container affidavit'>
                      <h2>AFFIDAVIT FOR DELAYED REGISTRATION OF DEATH</h2>

                      <p>
                        I,{' '}
                        <FormControl
                          disabled={!isEdit}
                          value={formData?.affidavit_name}
                          onChange={handleChange('affidavit_name')}
                        />
                        , of legal age{' '}
                        <div className='inline-group-check'>
                          <Form.Check
                            className='form-check'
                            name='affidavet-status'
                            type='radio'
                            label='Single'
                            value='Single'
                            checked={formData?.affidavit_civil_status === 'Single'}
                            onChange={handleChange('affidavit_civil_status')}
                          />{' '}
                          <Form.Check
                            className='form-check'
                            type='radio'
                            name='affidavet-status'
                            label='Married'
                            value='Married'
                            checked={formData?.affidavit_civil_status === 'Married'}
                            onChange={handleChange('affidavit_civil_status')}
                          />{' '}
                          <Form.Check
                            className='form-check'
                            type='radio'
                            name='affidavet-status'
                            label='Divorced'
                            value='Divorced'
                            checked={formData?.affidavit_civil_status === 'Divorced'}
                            onChange={handleChange('affidavit_civil_status')}
                          />{' '}
                          <Form.Check
                            type='radio'
                            className='form-check'
                            label='Widow'
                            name='affidavet-status'
                            value='Widow'
                            checked={formData?.affidavit_civil_status === 'Widow'}
                            onChange={handleChange('affidavit_civil_status')}
                          />
                          <Form.Check
                            type='radio'
                            className='form-check'
                            label='Widower'
                            name='affidavet-status'
                            value='Widower'
                            checked={formData?.affidavit_civil_status === 'Widower'}
                            onChange={handleChange('affidavit_civil_status')}
                          />
                        </div>
                        , with residence and postal address{' '}
                        <FormControl
                          disabled={!isEdit}
                          value={formData?.affidavit_address}
                          onChange={handleChange('affidavit_address')}
                        />{' '}
                        , after being duly sworn in accordance with law, do hereby depose and say;
                      </p>

                      <p className='mb-3'>
                        1. That{' '}
                        <FormControl
                          disabled={!isEdit}
                          value={formData?.affidavit_deceased_name}
                          onChange={handleChange('affidavit_deceased_name')}
                        />{' '}
                        died on{' '}
                        <FormControl
                          disabled={!isEdit}
                          value={formData?.affidavit_died_on}
                          onChange={handleChange('affidavit_died_on')}
                        />
                        in{' '}
                        <FormControl
                          disabled={!isEdit}
                          value={formData?.affidavit_died_in}
                          onChange={handleChange('affidavit_died_in')}
                        />{' '}
                        and was burried / cremated in{' '}
                        <FormControl
                          disabled={!isEdit}
                          value={formData?.affidavit_burried_or_cremated_in}
                          onChange={handleChange('affidavit_burried_or_cremated_in')}
                        />{' '}
                        on{' '}
                        <FormControl
                          disabled={!isEdit}
                          value={formData?.affidavit_burried_or_cremated_on}
                          onChange={handleChange('affidavit_burried_or_cremated_on')}
                        />
                      </p>

                      <p className=''>2. That the deceased at the time of his / her death: </p>

                      <div className='mb-3 attended'>
                        <div className='inline-group'>
                          <Form.Check
                            type='radio'
                            name='deceased_attended'
                            className='form-check'
                            label='was attended by'
                            value='was attended by'
                            checked={formData?.deceased_attended === 'was attended by'}
                            onChange={handleChange('deceased_attended')}
                          />
                          <FormControl
                            placeholder='Enter Here'
                            onChange={handleChange('deceased_attended')}
                          />
                        </div>
                        <Form.Check
                          type='radio'
                          name='deceased_attended'
                          className='form-check'
                          label='was not attended.'
                          value='was not attended.'
                          checked={formData?.deceased_attended === 'was not attended.'}
                          onChange={handleChange('deceased_attended')}
                        />
                      </div>

                      <p className='mb-3'>
                        3. That the cause of death of the deceased was{' '}
                        <FormControl
                          disabled={!isEdit}
                          value={formData?.affidavit_cause_of_death}
                          onChange={handleChange('affidavit_cause_of_death')}
                        />
                      </p>

                      <p className='mb-3'>
                        4. That the reason for the delay in registering this death was due to
                        <FormControl
                          disabled={!isEdit}
                          value={formData?.affidavit_reason_for_late_registration_of_death}
                          onChange={handleChange('affidavit_reason_for_late_registration_of_death')}
                        />
                      </p>

                      <p className='mb-3'>
                        5. That I am executing this affidavit to attest to the truthfulness of the
                        foregoing statements for all legal intents and purposes.
                      </p>

                      <p className='mb-3'>
                        In truth whereof, I have affixed my signature below this{' '}
                        <FormControl
                          disabled={!isEdit}
                          value={formData?.affidavit_day}
                          onChange={handleChange('affidavit_day')}
                        />
                        day of{' '}
                        <FormControl
                          disabled={!isEdit}
                          value={formData?.affidavit_month}
                          onChange={handleChange('affidavit_month')}
                        />
                        <FormControl
                          disabled={!isEdit}
                          value={formData?.affidavit_year}
                          onChange={handleChange('affidavit_year')}
                        />
                        at{' '}
                        <FormControl
                          disabled={!isEdit}
                          value={formData?.affidavit_address_1}
                          onChange={handleChange('affidavit_address_1')}
                        />{' '}
                        , Philippines.
                      </p>
                    </Col>
                  </Row>

                  <Row className='m-0'>
                    <Col className='block-container'>
                      <div className='mb-5 signature'>
                        <FormControl
                          placeholder='Enter Here'
                          value={formData?.affidavit_signature_over_printed_name_of_affiant}
                          onChange={handleChange(
                            'affidavit_signature_over_printed_name_of_affiant',
                          )}
                        />
                        <p className='over-printed-name'>
                          ( Signature Over Printed Name of Affiant )
                        </p>
                      </div>
                    </Col>
                  </Row>

                  <Row className='m-0'>
                    <Col className='block-container affidavit'>
                      <p>
                        <b>SUBSCRIBED AND SWORN</b> to be me this{' '}
                        <FormControl
                          disabled={!isEdit}
                          value={formData?.affidavit_subscribed_and_sworn_day}
                          onChange={handleChange('affidavit_subscribed_and_sworn_day')}
                        />
                        day of{' '}
                        <FormControl
                          disabled={!isEdit}
                          value={formData?.affidavit_subscribed_and_sworn_month}
                          onChange={handleChange('affidavit_subscribed_and_sworn_month')}
                        />
                        ,{' '}
                        <FormControl
                          disabled={!isEdit}
                          value={formData?.affidavit_subscribed_and_sworn_year}
                          onChange={handleChange('affidavit_subscribed_and_sworn_year')}
                        />{' '}
                        at{' '}
                        <FormControl
                          disabled={!isEdit}
                          value={formData?.affidavit_subscribed_and_sworn_address}
                          onChange={handleChange('affidavit_subscribed_and_sworn_address')}
                        />{' '}
                        , Philippines, affiant who exhibited to me his Community Tax Certificate{' '}
                        <FormControl
                          disabled={!isEdit}
                          value={formData?.affidavit_tax_certificate}
                          onChange={handleChange('affidavit_tax_certificate')}
                        />{' '}
                        issued on{' '}
                        <FormControl
                          disabled={!isEdit}
                          value={formData?.affidavit_tax_certificate_issued_on}
                          onChange={handleChange('affidavit_tax_certificate_issued_on')}
                        />{' '}
                        at{' '}
                        <FormControl
                          disabled={!isEdit}
                          value={formData?.affidavit_tax_certificate_issued_at}
                          onChange={handleChange('affidavit_tax_certificate_issued_at')}
                        />
                      </p>
                    </Col>
                  </Row>

                  <Row className='m-0'>
                    <Col className='block-container'>
                      <div className='mb-5 signature no-border '>
                        <FormControl
                          value={formData?.affidavit_signature_of_administering_officer}
                          onChange={handleChange('affidavit_signature_of_administering_officer')}
                        />
                        <p className='over-printed-name'>
                          ( Signature of the Administering Officer )
                        </p>
                      </div>

                      <div className='mb-5 signature'>
                        <FormControl
                          placeholder='Enter Here'
                          value={formData?.affidavit_name_in_print}
                          onChange={handleChange('affidavit_name_in_print')}
                        />
                        <p className='over-printed-name'>Name in Print</p>
                      </div>
                    </Col>
                    <Col className='block-container'>
                      <div className='mb-5 signature'>
                        <FormControl
                          placeholder='Enter Here'
                          value={formData?.affidavit_position}
                          onChange={handleChange('affidavit_position')}
                        />
                        <p className='over-printed-name'>Position / Title / Designation</p>
                      </div>

                      <div className='mb-5 signature'>
                        <FormControl
                          placeholder='Enter Here'
                          value={formData?.affidavit_address_2}
                          onChange={handleChange('affidavit_address_2')}
                        />
                        <p className='over-printed-name'>Address</p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </>
          }
        />

        <hr />

        <ConditionalRender
          condition={isPageOne}
          renderIf={
            <Row className=''>
              <Col className='form-buttons'>
                <Button
                  onClick={() => {
                    setPage(2);
                  }}
                  className='save'
                >
                  NEXT PAGE
                </Button>
              </Col>
            </Row>
          }
        />

        <ConditionalRender
          condition={isPageTwo}
          renderIf={
            <Row className=''>
              <Col className='form-buttons'>
                {/* Buttons to show for Add */}
                <ConditionalRender
                  condition={isAdding}
                  renderIf={
                    <>
                      <Button
                        type='submit'
                        className='save'
                        disabled={trigger?.trigger}
                        // onClick={addRecord}
                      >
                        CREATE RECORD
                      </Button>
                      <Button
                        onClick={() => {
                          setIsEdit(false);
                          setShowScreen(0);
                        }}
                        className='cancel'
                      >
                        CANCEL
                      </Button>
                      <Button
                        type='submit'
                        className='cancel'
                        onClick={() => {
                          setPage(1);
                        }}
                      >
                        PREVIOUS PAGE
                      </Button>
                    </>
                  }
                />

                {/* Buttons to show for Edit/View */}
                <ConditionalRender
                  condition={!isAdding}
                  renderIf={
                    <>
                      <ConditionalRender
                        condition={!isEditing}
                        renderIf={
                          <>
                            <Button
                              className='print'
                              onClick={() => {
                                setPage(1);
                              }}
                            >
                              Back
                            </Button>
                            <Button className='print'>PRINT CERTIFICATE</Button>
                            <Button
                              onClick={() => {
                                setIsEdit(true);
                              }}
                              className='edit'
                            >
                              EDIT CERTIFICATE
                            </Button>
                          </>
                        }
                      />

                      <ConditionalRender
                        condition={isEditing}
                        renderIf={
                          <>
                            <Button
                              className='print'
                              onClick={() => {
                                setPage(1);
                              }}
                            >
                              Back
                            </Button>
                            <Button
                              onClick={() => {
                                saveChanges();
                              }}
                              className='save'
                            >
                              SAVE CHANGES
                            </Button>
                            <Button
                              onClick={() => {
                                setIsEdit(false);
                              }}
                              className='cancel'
                            >
                              CANCEL
                            </Button>
                          </>
                        }
                      />
                    </>
                  }
                />
              </Col>
            </Row>
          }
        />
      </Form>

      <PatientSelection
        fields={formData}
        setFields={setFormData}
        patientSelection={patientSelection}
        showPatientSelection={showPatientSelection}
        handleClosePatientSelection={handleClosePatientSelection}
      />
    </>
  );
};

export default DeathForm;
