/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-indent-props */
import axios from 'axios';
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Table, Button, Col, Form } from 'react-bootstrap';

// import components below
import TableUI from 'components/UI/TableUI';
import LayoutReport from 'components/LayoutReport';
import { sendDoh } from '../../../../reducers/thunks/reportThunks';

import './style.scss';

const StaffingPattern = () => {
  const input = useParams();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [staffingPattern, setStaffingPattern] = useState([]);
  const [year, setYear] = useState('');

  const medicalFilteredData = [];
  const alliedMedicalFilteredData = [];
  const nonMedicalFilteredData = [];
  const generalSupportStaffData = [];

  // Medical
  for (let i = 0; i < staffingPattern.length; i++) {
    const element = staffingPattern[i];
    if (element.code < 22) {
      medicalFilteredData.push(element);
    }
  }
  medicalFilteredData.sort((a, b) => a.code - b.code);

  // Allied Medical
  for (let i = 0; i < staffingPattern.length; i++) {
    const e = staffingPattern[i];
    if (e.code >= 22 && e.code <= 34) {
      alliedMedicalFilteredData.push(e);
    }
  }
  alliedMedicalFilteredData.sort((a, b) => a.code - b.code);

  // Non Medical
  for (let i = 0; i < staffingPattern.length; i++) {
    const e = staffingPattern[i];
    if (e.code >= 35 && e.code <= 41) {
      nonMedicalFilteredData.push(e);
    }
  }
  nonMedicalFilteredData.sort((a, b) => a.code - b.code);

  // General Support Staff
  for (let i = 0; i < staffingPattern.length; i++) {
    const e = staffingPattern[i];
    if (e.code >= 42 && e.code <= 45) {
      generalSupportStaffData.push(e);
    }
  }
  generalSupportStaffData.sort((a, b) => a.code - b.code);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_BASE_ACL}/api/reports/${input.id}`).then((response) => {
      const parsedData = JSON.parse(response.data.data.attributes.data);
      setStaffingPattern(Object.values(parsedData));
      setYear(response.data.data.attributes.year);
    }, []);
  }, []);

  const params = {
    report_type: 'staffing_pattern',
  };

  const generatePdf = () => {
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/report/create-pdf`, params)
      .then((response) => {
        axios
          .get(`${process.env.REACT_APP_API_BASE_URL}/report/fetch-pdf`, {
            params: { file_name: response.data },
            responseType: 'blob',
          })
          .then((res) => {
            const pdfBlob = new Blob([res.data], { type: 'application/pdf' });

            saveAs(pdfBlob, 'Staffing Pattern.pdf');
          });
      });
  };

  const updateDoh = async () => {
    // const params = { type: Constants.STAFFINGPATTERNS };
    try {
      await setIsLoading(true);
      await dispatch(sendDoh(input.id));
      toast.success('Success');
    } catch (e) {
      console.error(e.message);
      toast.error(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  const redirectBackToPreviousPage = () => {
    window.history.back();
  };

  const designation = (param) => {
    switch (param) {
      case '1':
        return '1. Consultants';
      case '2':
        return '1.1 Internal Medicine';
      case '3':
        return 'a. Generalist';
      case '4':
        return 'b. Cardiologist';
      case '5':
        return 'c. Endocrinologist';
      case '6':
        return 'd. Gastro-Enterologist';
      case '7':
        return 'e. Pulmonologist';
      case '8':
        return 'f. Nephrologist';
      case '9':
        return 'g. Neurologist';
      case '10':
        return '1.2. Obstetrics/ Gynecology (and sub-specialty)';
      case '11':
        return '1.3. Pediatrics (and sub-specialty)';
      case '12':
        return '1.4. Surgery (and sub-specialty)';
      case '13':
        return '1.5. Anesthesiologist';
      case '14':
        return '1.6. Radiologist';
      case '15':
        return '1.7. Pathologist';
      case '16':
        return '2. Post-Graduate Fellows<br>(Indicate specialty/subspecialty)';
      case '17':
        return '3. Residents';
      case '18':
        return '3.1. Internal Medicine';
      case '19':
        return '3.2. Obstetricts-Gynecology';
      case '20':
        return '3.3. Pediatrics';
      case '21':
        return '3.4. Surgery';
      case '22':
        return '1. Nurses';
      case '23':
        return '2. Midwives';
      case '24':
        return '3. Nursing Aides';
      case '25':
        return '4. Nutritionist';
      case '26':
        return '5. Physical Therapist';
      case '28':
        return '6. Pharmacists';
      case '29':
        return '7. Medical Technologist';
      case '30':
        return '8. Laboratory Technician';
      case '31':
        return '9. X-Ray Technologist/X-Ray Technician';
      case '32':
        return '10. Medical Equipment Technician';
      case '33':
        return '11. Social Worker';
      case '34':
        return '12. Medical Records Officer/ Hospital Health Information Officer';
      case '35':
        return '1. Chief Administrative Officer';
      case '36':
        return '2. Accountant';
      case '37':
        return '3. Budget officer';
      case '38':
        return '4. Cashier';
      case '39':
        return '5. Clerk';
      case '40':
        return '6. Engineer';
      case '41':
        return '7. Driver';
      case '42':
        return '9. General Support Staff';
      case '43':
        return '- Janitorial';
      case '44':
        return '- Maintenance';
      case '45':
        return '- Security';

      default:
        return 'N/A';
    }
  };

  return (
    <LayoutReport pageTitle='Staffing Pattern'>
      <Row className='operation-report'>
        <Row className='header'>
          <h1>STAFFING PATTERN</h1>
        </Row>
        <Container>
          <Row className='body'>
            <Row className='d-flex align-items-center'>
              <Col lg={2}>
                <Form.Group className='mb-3'>
                  <Form.Label>Year</Form.Label>
                  <Form.Control
                    style={{ maxWidth: '320px' }}
                    type='text'
                    placeholder=''
                    disabled
                    value={year}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className='mb-3'>
                  <Form.Label>Name Of Hospital</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder=''
                    value={process.env.REACT_APP_HOSPITAL_NAME}
                    disabled
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <Form.Group className='mb-3'>
                  <Form.Label>
                    Address<span className='asterisk'>*</span>
                  </Form.Label>
                  <Form.Control
                    type='text'
                    disabled
                    value={process.env.REACT_APP_HOSPITAL_STREET_ADDRESS}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className='mb-3'>
                  <Form.Label>
                    Municipality<span className='asterisk'>*</span>
                  </Form.Label>
                  <Form.Control
                    type='text'
                    disabled
                    value={process.env.REACT_APP_HOSPITAL_MUNICIPALITY}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className='mb-3'>
                  <Form.Label>
                    Province<span className='asterisk'>*</span>
                  </Form.Label>
                  <Form.Control
                    type='text'
                    disabled
                    value={process.env.REACT_APP_HOSPITAL_PROVINCE}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className='mb-3'>
                  <Form.Label>
                    Region<span className='asterisk'>*</span>
                  </Form.Label>
                  <Form.Control
                    type='text'
                    disabled
                    value={process.env.REACT_APP_HOSPITAL_REGION}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className='mb-3 mt-3'>
              <div className='mt-3'>
                <TableUI>
                  <Table>
                    <thead>
                      <tr>
                        <th rowSpan='2' colSpan='1' id='th'>
                          <small>Designation</small>
                          <br></br>
                          A. Medical
                        </th>
                        <th rowSpan='1' colSpan='4'>
                          <center>
                            <small>Full-time (at least 40 hours/week)</small>
                          </center>
                        </th>
                        <th rowSpan='1' colSpan='4'>
                          <center>
                            <small>Part-time (at least 20 hours/week)</small>
                          </center>
                        </th>
                      </tr>
                      <tr>
                        <th rowSpan='2' colSpan='1'>
                          <small>Specialty Board Certified</small>
                        </th>
                        <th>
                          <small>Number of permanent full-time staff</small>
                        </th>
                        <th>
                          <small>Number of contractual staff</small>
                          <br></br>
                          <small>(include her personnel on Job Order)</small>
                        </th>
                        <th>
                          <small>Number of permanent part-time staff</small>
                        </th>
                        <th>
                          <small>Number of contractual staff</small>
                        </th>
                        <th>
                          <small>Active Rotating or Visiting/Affiliate</small>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {medicalFilteredData?.length > 0 &&
                        medicalFilteredData.map((item, index) => {
                          return (
                            <tr key={index + 1}>
                              <td>{designation(item?.code)}</td>
                              <td>{item?.specialty_board_certified}</td>
                              <td>{item?.full_time_permanent}</td>
                              <td>{item?.full_time_contractual}</td>
                              <td>{item?.part_time_permanent}</td>
                              <td>{item?.part_time_contractual}</td>
                              <td>{item?.active_rotating_affiliate}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                    <thead>
                      <tr>
                        <th rowSpan='2' colSpan='7' id='th'>
                          Allied Medical
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {alliedMedicalFilteredData?.length > 0 &&
                        alliedMedicalFilteredData?.map((item, index) => {
                          return (
                            <tr key={index + 1}>
                              <td>{designation(item.code)}</td>
                              <td>{item?.specialty_board_certified}</td>
                              <td>{item?.full_time_permanent}</td>
                              <td>{item?.full_time_contractual}</td>
                              <td>{item?.part_time_permanent}</td>
                              <td>{item?.part_time_contractual}</td>
                              <td>{item?.active_rotating_affiliate}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                    <thead>
                      <tr>
                        <th rowSpan='2' colSpan='7' id='th'>
                          Non - Medical
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {nonMedicalFilteredData?.length > 0 &&
                        nonMedicalFilteredData.map((item, index) => {
                          return (
                            <tr key={index + 1}>
                              <td>{designation(item.code)}</td>
                              <td>{item?.specialty_board_certified}</td>
                              <td>{item?.full_time_permanent}</td>
                              <td>{item?.full_time_contractual}</td>
                              <td>{item?.part_time_permanent}</td>
                              <td>{item?.part_time_contractual}</td>
                              <td>{item?.active_rotating_affiliate}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                    <thead>
                      <tr>
                        <th rowSpan='2' colSpan='7' id='th'>
                          General Support Staff(indicate if outsourced)
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {generalSupportStaffData?.length > 0 &&
                        generalSupportStaffData?.map((item, index) => {
                          return (
                            <tr key={index + 1}>
                              <td>{designation(item.code)}</td>
                              <td>{item?.specialty_board_certified}</td>
                              <td>{item?.full_time_permanent}</td>
                              <td>{item?.full_time_contractual}</td>
                              <td>{item?.part_time_permanent}</td>
                              <td>{item?.part_time_contractual}</td>
                              <td>{item?.active_rotating_affiliate}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                  <center>
                    <label className='fw-bold'>
                      (Positions with blocked portions are required to be full time permanent or
                      contractual; include Those under Job Order in the column for Contractual)
                    </label>
                  </center>
                </TableUI>
                <div className='d-flex justify-content-end mt-5'>
                  {staffingPattern.length > 0 && (
                    <>
                      <Button className='me-3' variant='save' onClick={generatePdf}>
                        GENERATE PDF
                      </Button>
                      <Button className='me-3' variant='save' onClick={() => updateDoh()}>
                        {isLoading ? 'Sending...' : 'SEND DOH'}
                      </Button>
                    </>
                  )}
                  <Button className='' variant='clear' onClick={redirectBackToPreviousPage}>
                    CANCEL
                  </Button>
                </div>
              </div>
            </Row>
          </Row>
        </Container>
      </Row>
    </LayoutReport>
  );
};

export default StaffingPattern;
