/* eslint-disable multiline-comment-style */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Button, Modal, Form, FormControl } from 'react-bootstrap';

// import assets below
import './style.scss';
import FormUI from 'components/UI/FormUI';
import { ReactComponent as UserSVG } from 'assets/svg/user.svg';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';
import moment from 'moment';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { selectPatientItem, showPatient } from 'reducers/patientReducer';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

// main component
const ModalNote = ({ showProfile, setShowProfile }) => {
    const navigate = useNavigate();
    const [userData, setUserData] = useState({
        patientId: '',
        note_description: '',
        additional_notes: '',
        userId: '',
    });
    const [saveButton, setSaveButton] = useState(true);

    const patientProfile = useSelector(selectPatientItem);

    const handleChange = (key) => (e) => {
        setUserData({
            ...userData,
            [key]: e.target.value,
        });
    };

    useEffect(() => {
        if (patientProfile) {
            setUserData({ ...userData, patientId: patientProfile.id });
        }
    }, [patientProfile]);

    // Submit data here
    const handleSubmit = async () => {
        axios
            .post('/note', userData)
            .then((response) => {
                toast.success('Successfully added!');
                setUserData({
                    note_description: '',
                    additional_notes: '',
                    patientId: patientProfile.id,
                });
                setSaveButton(true);
            })
            .catch((error) => {
                error.response.data.errors.map((item) => {
                    toast.warning(item.msg + ' ' + item.param);
                });
            });
    };

    return (
        <Modal
            size='lg'
            show={showProfile.note}
            onHide={() =>
                setShowProfile((prevState) => {
                    return {
                        ...prevState,
                        note: false,
                    };
                })
            }
            aria-labelledby='Add Lab Request Modal'
            className='add-lab-request-modal'
        >
            <Modal.Body>
                <div className='add-lab-request'>
                    <div className='d-flex header'>
                        <p>Add Note</p>

                        <CircleXMark
                            style={{ width: '2em', cursor: 'pointer' }}
                            onClick={() =>
                                setShowProfile((prevState) => {
                                    return { ...prevState, note: false };
                                })
                            }
                        />
                    </div>
                </div>

                <FormUI>
                    <Form className='lab-request-form'>
                        <Row>
                            <Col>
                                <Form.Group className='mb-4'>
                                    <Form.Label>Description</Form.Label>
                                    <FormControl
                                        as='textarea'
                                        rows={3}
                                        className='bg-white'
                                        placeholder='Enter Here'
                                        value={userData.note_description}
                                        onChange={handleChange('note_description')}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className='mb-4'>
                                    <Form.Label>Additional Notes</Form.Label>
                                    <FormControl
                                        as='textarea'
                                        rows={3}
                                        className='bg-white'
                                        placeholder='Enter Here'
                                        value={userData.additional_notes}
                                        onChange={handleChange('additional_notes')}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Form.Group className='mt-4 buttons-cont'>
                                    <Button
                                        className='add-button'
                                        variant='primary'
                                        onClick={(e) => {
                                            handleSubmit(e);
                                        }}
                                        disabled={!userData.note_description || !userData.additional_notes}
                                    >
                                        Add
                                    </Button>
                                    <Button
                                        className='cancel-button'
                                        vvariant='secondary'
                                        onClick={() =>
                                            setShowProfile((prevState) => {
                                                return {
                                                    ...prevState,
                                                    note: false,
                                                };
                                            })
                                        }
                                    >
                                        Cancel
                                    </Button>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </FormUI>
            </Modal.Body>
        </Modal>
    );
};

export default ModalNote;
