/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable react/jsx-key */
/* eslint-disable arrow-parens */
/* eslint-disable camelcase */

// import core & vendor packages below
import axios from 'axios';
import Constants from 'constant';
import moment from 'moment/moment';
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, Accordion, Form, Button } from 'react-bootstrap';

// import components below
import Layout from 'components/Layout';
import FormUI from 'components/UI/FormUI';
import AccordionUI from 'components/UI/AccordionUI';
import { updateDoh } from '../../../../reducers/thunks/reportThunks';

// import assets below
import './style.scss';

// main component

const GeneralInformation = () => {
  const input = useParams();
  const dispatch = useDispatch();

  const [year, setYear] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [faxNumber, setFaxNumber] = useState('');
  const [email, setEmail] = useState('');
  const [generalInformation, setGeneralInformation] = useState('');
  const [validated, setValidated] = useState(false);

  const params = {
    report_type: 'general_information',
    year: year,
    contact_number: contactNumber,
    fax_number: faxNumber,
    email: email,
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/report/general_information/` + input.id)
      .then((response) => {
        setGeneralInformation(response.data.data);
      }, []);
    console.log('data on use effect: ', generalInformation);
  }, []);

  const generatePdf = () => {
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/report/create-pdf`, params)
      .then((response) => {
        axios
          .get(`${process.env.REACT_APP_API_BASE_URL}/report/fetch-pdf`, {
            params: { file_name: response.data },
            responseType: 'blob',
          })
          .then((res) => {
            const pdfBlob = new Blob([res.data], { type: 'application/pdf' });

            saveAs(pdfBlob, 'General Information.pdf');
          });
      })
      .catch((error) => console.log('generate pdf error: ', error)); // cors issue here
  };

  const redirectBackToPreviousPage = () => {
    window.history.back();
  };

  const updateDohButton = () => {
    dispatch(updateDoh({ ...params, id: input.id, type: input.type }));
    toast.success('Success');
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  const updateDoh = async (id) => {
    const params = { type: Constants.GENERALINFORMATION };
    axios
      .put(`/report/doh/${id}`, params)
      .then((response) => {
        toast.success('Success!');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const genInfoClassification = generalInformation.report_gen_info_classifications?.[0];
  const genInfoQualityMgmt = generalInformation.report_gen_info_quality_mgmts?.[0];
  const genInfoBedCapacities = generalInformation.report_gen_info_bed_capacities?.[0];

  /* const [generalLvl1Hospital, setGeneralLvl1Hospital] = useState(false);
  const [generalLvl2Hospital, setGeneralLvl2Hospital] = useState(false); */

  return (
    <div className='formCreateTemporaryProfile'>
      <h1 id='title'>ANNUAL HOSPITAL STATISTICAL REPORT</h1>
      <FormUI>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <AccordionUI>
            <Accordion defaultActiveKey='0'>
              <Accordion.Item eventKey='0'>
                <Accordion.Body>
                  <Row className='d-flex align-items-center'>
                    <Col lg={2}>
                      <Form.Group className='mb-3'>
                        <Form.Label>Year</Form.Label>
                        <Form.Control
                          style={{ maxWidth: '320px' }}
                          type='text'
                          placeholder=''
                          disabled
                          value={generalInformation.year}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>Name Of Hospital</Form.Label>
                        <Form.Control
                          type='text'
                          placeholder=''
                          value={genInfoClassification?.name_of_hospital}
                          disabled
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={6}>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          Address<span className='asterisk'>*</span>
                        </Form.Label>
                        <Form.Control
                          type='text'
                          disabled
                          value={genInfoClassification?.street_address}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          Municipality<span className='asterisk'>*</span>
                        </Form.Label>
                        <Form.Control
                          type='text'
                          disabled
                          value={genInfoClassification?.municipality}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          Province<span className='asterisk'>*</span>
                        </Form.Label>
                        <Form.Control
                          type='text'
                          disabled
                          value={genInfoClassification?.province}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          Region<span className='asterisk'>*</span>
                        </Form.Label>
                        <Form.Control
                          type='text'
                          disabled
                          value={genInfoClassification?.region}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  {/*
                  <Row>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          Contact Number<span className='asterisk'>*</span>
                        </Form.Label>
                        <Form.Control
                          type='text'
                          onChange={(event) => {
                            setContactNumber(event.target.value);
                          }}
                          readOnly
                          required
                          value={genInfoClassification?.contact_number ?? contactNumber}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          Fax Number<span className='asterisk'>*</span>
                        </Form.Label>
                        <Form.Control
                          type='text'
                          onChange={(event) => {
                            setFaxNumber(event.target.value);
                          }}
                          required
                          readOnly
                          value={genInfoClassification?.fax_number ?? faxNumber}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          Email Address<span className='asterisk'>*</span>
                        </Form.Label>
                        <Form.Control
                          type='text'
                          onChange={(event) => {
                            setEmail(event.target.value);
                          }}
                          required
                          readOnly
                          value={genInfoClassification?.email ?? email}
                        />
                      </Form.Group>
                    </Col>
                  </Row> */}
                  <h4>
                    <b>A. CLASSIFICATION</b>
                  </h4>
                  <h5>
                    <b>1. Service Capability</b>
                  </h5>
                  <ul>
                    <li>
                      <p>
                        Service Capability: Capability of a hospital/other health facility to render
                        administrative, clinical, ancillary and other services
                      </p>
                    </li>
                  </ul>

                  <Row>
                    <Col lg={3}>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          General:<span className='asterisk'>*</span>
                        </Form.Label>
                        <Form.Check
                          type='checkbox'
                          label='Level 1 Hospital'
                          /* checked={genInfoClassification?.general === '1' ? !generalLvl1Hospital : generalLvl1Hospital}
                          onChange={() => setGeneralLvl1Hospital(!generalLvl1Hospital)} */
                          // checked={genInfoClassification?.general === '1'}
                          readOnly
                        />
                      </Form.Group>
                    </Col>

                    <Col lg={3}>
                      <Form.Group className='mt-3 mb-3'>
                        <Form.Label>
                          Specialty:<span className='asterisk'>*</span>
                        </Form.Label>

                        <Form.Check
                          type='checkbox'
                          // defaultChecked
                          label='Treats a particular disease (Specify)'
                          // checked={genInfoClassification?.specialty === '1'}
                          readOnly
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={2}>
                      <Form.Group>
                        <Form.Label></Form.Label>

                        <Form.Control
                          type='text'
                          placeholder=''
                          // readOnly
                          value={
                            genInfoClassification?.specialty === '1'
                              ? genInfoClassification?.specialtyspecify
                              : ''
                          }
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={3}>
                      <Form.Group className='mb-3'>
                        <Form.Check
                          type='checkbox'
                          label='Level 2 Hospital'
                          /* checked={genInfoClassification?.general === '2' ? !generalLvl2Hospital : generalLvl2Hospital}
                          onChange={() => setGeneralLvl2Hospital(!generalLvl2Hospital)} */
                          // checked={genInfoClassification?.general === '2'}
                          readOnly
                        />
                      </Form.Group>
                    </Col>

                    <Col lg={3}>
                      <Form.Group className='mt-3 mb-3'>
                        <Form.Check
                          type='checkbox'
                          label='Treats a particular organ (Specify)'
                          // checked={genInfoClassification?.specialty === '2'}
                          readOnly
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={2}>
                      <Form.Group>
                        <Form.Control
                          type='text'
                          placeholder=''
                          value={
                            genInfoClassification?.specialty === '2'
                              ? genInfoClassification?.specialtyspecify
                              : ''
                          }
                          // readOnly
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3}>
                      <Form.Group className='mb-3'>
                        <Form.Check
                          type='checkbox'
                          label='Level 3 Hospital (Teaching/Training)'
                          // checked={genInfoClassification?.general === '3'}
                          readOnly
                        />
                      </Form.Group>
                    </Col>

                    <Col lg={3}>
                      <Form.Group className='mt-3 mb-3'>
                        <Form.Check
                          type='checkbox'
                          label='Treats a particular class of patients (Specify)'
                         // checked={genInfoClassification?.specialty === '3'}
                          readOnly
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={2}>
                      <Form.Group>
                        <Form.Control
                          type='text'
                          placeholder=''
                          // readOnly
                          value={
                            genInfoClassification?.specialty === '3'
                              ? genInfoClassification?.specialtyspecify
                              : ''
                          }
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3}>
                      <Form.Group className='mb-3'>
                        <Form.Check
                          type='checkbox'
                          label='Infirmary'
                          // checked={genInfoClassification?.general === '4'}
                          readOnly
                        />
                      </Form.Group>
                    </Col>

                    <Col lg={3}>
                      <Form.Group className='mt-3 mb-3'>
                        <Form.Check
                          className=''
                          type='checkbox'
                          label='Others (Specify)'
                          // checked={genInfoClassification?.specialty === '4'}
                          readOnly
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={2}>
                      <Form.Group>
                        <Form.Control
                          type='text'
                          placeholder=''
                          value={
                            genInfoClassification?.specialty === '4'
                              ? genInfoClassification?.specialtyspecify
                              : ''
                          }
                          // readOnly
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={1}>
                      <Form.Label>
                        <Form.Label>Trauma Capability</Form.Label>
                      </Form.Label>
                    </Col>

                    <Col lg={2}>
                      <Form.Group>
                        <Form.Check
                          type='checkbox'
                          label='Trauma Capable'
                          // checked={genInfoClassification?.traumacapability === '1'}
                          readOnly
                        />
                      </Form.Group>
                    </Col>

                    <Col>
                      <Form.Group>
                        <Form.Check
                          type='checkbox'
                          label='Trauma Receiving'
                          // checked={genInfoClassification?.traumacapability === '2'}
                          readOnly
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={3}>
                      <h6>
                        <b>Trauma-Capable Facility</b>
                      </h6>
                    </Col>

                    <Col>
                      <h6>
                        <b>
                          {' '}
                          - DOG Licensed hopspital designated as a trauma center. (End referral
                          hospital for trauma cases).
                        </b>
                      </h6>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3}>
                      <h6>
                        <b>Trauma-Receiving Facility</b>
                      </h6>
                    </Col>

                    <Col>
                      <h6>
                        <b>
                          {' '}
                          - DOG Licensed hopspital within the trauma service area which receives
                          trauma patients for transport to the point of care or a trauma center.
                          (Receives trauma cases brough to ER then after stabilization or
                          institution of emergency care, refer such cases to trauma center or trauma
                          capable facility).
                        </b>
                      </h6>
                    </Col>
                  </Row>

                  <h5>
                    <b>2. Nature of Ownership</b>
                  </h5>

                  <Row>
                    <Col lg={3}>
                      <Form.Label>
                        Government:<span className='asterisk'>*</span>
                      </Form.Label>
                      <Form.Check
                        type='checkbox'
                        label='National'
                         // checked={genInfoClassification?.government === '1'}
                        readOnly
                      />
                    </Col>

                    <Col>
                      <Form.Label>
                        Local (Specify):<span className='asterisk'>*</span>
                      </Form.Label>
                      <Form.Control type='text' placeholder='' />
                    </Col>

                    <Col>
                      <Form.Label>
                        Private:<span className='asterisk'>*</span>
                      </Form.Label>
                      <Form.Check
                        type='checkbox'
                        label='Single Proprietorship'
                        // checked={genInfoClassification?.private === '1'}
                        readOnly
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={3}>
                      <Form.Check
                        type='checkbox'
                        label='DOH Retained'
                        // checked={genInfoClassification?.national === '1'}
                      />
                    </Col>

                    <Col>
                      <Form.Check
                        type='checkbox'
                        label='Province'
                        // checked={genInfoClassification?.local === '1'}
                        readOnly
                      />
                    </Col>

                    <Col>
                      <Form.Check
                        type='checkbox'
                         label='Single Partnership'
                        // checked={genInfoClassification?.private === '2'}
                        readOnly
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={3}>
                      <Form.Check
                        type='checkbox'
                        label='DILG - PNP'
                        // checked={genInfoClassification?.national === '2'}
                        readOnly
                      />
                    </Col>

                    <Col>
                      <Form.Check
                        type='checkbox'
                        label='City'
                        // checked={genInfoClassification?.local === '2'}
                        readOnly
                      />
                    </Col>

                    <Col>
                      <Form.Check
                        type='checkbox'
                        label='Corporation'
                        //  checked={genInfoClassification?.private === '3'}
                        readOnly
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={3}>
                      <Form.Check
                        type='checkbox'
                        label='DND - AFP'
                        // checked={genInfoClassification?.national === '3'}
                        readOnly
                      />
                    </Col>

                    <Col>
                      <Form.Check
                        type='checkbox'
                        label='Municipality'
                        // checked={genInfoClassification?.local === '3'}
                        readOnly
                      />
                    </Col>

                    <Col>
                      <Form.Check
                        type='checkbox'
                        label='Religious'
                        // checked={genInfoClassification?.private === '4'}
                        readOnly
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={3}>
                      <Form.Check
                        type='checkbox'
                        label='DOJ'
                        // checked={genInfoClassification?.national === '4'}
                        readOnly
                      />
                    </Col>

                    <Col></Col>

                    <Col>
                      <Form.Check
                        type='checkbox'
                        label='Civil Organization'
                        //  checked={genInfoClassification?.private === '5'}
                        readOnly
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={3}>
                      <Form.Check
                        type='checkbox'
                        label='State Universities and Colleges (SUCs)'
                        // checked={genInfoClassification?.government === '3'}
                        readOnly
                      />
                    </Col>

                    <Col></Col>

                    <Col>
                      <Form.Check
                        type='checkbox'
                        label='Foundation'
                        //  checked={genInfoClassification?.private === '6'}
                        readOnly
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={3}>
                      <Form.Check className='mb-1' type='checkbox' label='Others (Specify)' />
                      <Form.Control type='text' placeholder='' />
                    </Col>

                    <Col></Col>

                    <Col>
                      <Form.Check
                        className='mb-1'
                        type='checkbox'
                        label='Others (Specify)'
                        //  checked={genInfoClassification?.private === '7'}
                      />
                      <Form.Control type='text' placeholder='' />
                    </Col>
                  </Row>

                  <h4>
                    <b>B. QUALITY MANAGEMENT</b>
                  </h4>
                  <ul>
                    <li>
                      <p>
                        Quality Management / Quality Assurance Program Organized set of activities
                        designed to demonstrate on-going assessment of important aspects of patient
                        care and services.
                      </p>
                    </li>
                  </ul>

                  <Row>
                    <Col lg={8}>
                      <Form.Group className='mt-3 mb-3'>
                        <Form.Check
                          type='checkbox'
                          // defaultChecked
                          label='ISO Certified (Specify ISO Certifying Body and area(s) of the hospital with Certification)'
                          // checked={genInfoQualityMgmt?.qualitymgmttype === '1'}
                          readOnly
                        />
                      </Form.Group>
                    </Col>

                    <Col lg={1}>
                      <Form.Group className='mt-3 mb-3'>
                        <Form.Label>Validity Period</Form.Label>
                      </Form.Group>
                    </Col>

                    <Col>
                      <Form.Group className='mt-3 mb-3'>
                        <Form.Control
                          type='text'
                          placeholder=''
                          // readOnly
                          value={
                            genInfoQualityMgmt?.qualitymgmttype === '1'
                              ? `${moment(genInfoQualityMgmt?.validityfrom).format(
                                  'MM/DD/YYYY',
                                )} - ${moment(genInfoQualityMgmt?.validityto).format('MM/DD/YYYY')}`
                              : ''
                          }
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={8}>
                      <Form.Group className='mt-3 mb-3'>
                        <Form.Check
                          type='checkbox'
                          label='Others'
                          // checked={genInfoQualityMgmt?.qualitymgmttype === '5'}
                          readOnly
                        />
                      </Form.Group>
                    </Col>

                    <Col lg={1}>
                      <Form.Group className='mt-3 mb-3'>
                        <Form.Label>Validity Period</Form.Label>
                      </Form.Group>
                    </Col>

                    <Col>
                      <Form.Group className='mt-3 mb-3'>
                        <Form.Control
                          type='text'
                          placeholder=''
                          value={
                            genInfoQualityMgmt?.qualitymgmttype === '5'
                              ? `${moment(genInfoQualityMgmt?.validityfrom).format(
                                  'MM/DD/YYYY',
                                )} - ${moment(genInfoQualityMgmt?.validityto).format('MM/DD/YYYY')}`
                              : ''
                          }
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={8}>
                      <Form.Group className='mt-3 mb-3'>
                        <Form.Check
                          type='checkbox'
                          label='International Accreditation'
                          // checked={genInfoQualityMgmt?.qualitymgmttype === '2'}
                          readOnly
                        />
                      </Form.Group>
                    </Col>

                    <Col lg={1}>
                      <Form.Group className='mt-3 mb-3'>
                        <Form.Label>Validity Period</Form.Label>
                      </Form.Group>
                    </Col>

                    <Col>
                      <Form.Group className='mt-3 mb-3'>
                        <Form.Control
                          type='text'
                          placeholder=''
                          // readOnly
                          value={
                            genInfoQualityMgmt?.qualitymgmttype === '2'
                              ? `${moment(genInfoQualityMgmt?.validityfrom).format(
                                  'MM/DD/YYYY',
                                )} - ${moment(genInfoQualityMgmt?.validityto).format('MM/DD/YYYY')}`
                              : ''
                          }
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={8}>
                      <Form.Group className='mt-3 mb-3'>
                        <Form.Check
                          type='checkbox'
                          label='PhilHealth Accreditation'
                          // checked={genInfoQualityMgmt?.qualitymgmttype === '3'}
                          readOnly
                        />
                        <Form.Check
                          type='checkbox'
                          label='Basic Participation'
                          // checked={genInfoQualityMgmt?.philhealthaccreditation === '1'}
                          readOnly
                        />
                        <Form.Check
                          type='checkbox'
                          label='Advanced Participation'
                          // checked={genInfoQualityMgmt?.philhealthaccreditation === '2'}
                          readOnly
                        />
                      </Form.Group>
                    </Col>

                    <Col lg={1}>
                      <Form.Group className='mt-3 mb-3'>
                        <Form.Label>Validity Period</Form.Label>
                      </Form.Group>
                    </Col>

                    <Col>
                      <Form.Group className='mt-3 mb-3'>
                        <Form.Control
                          type='text'
                          placeholder=''
                          // readOnly
                          value={
                            genInfoQualityMgmt?.qualitymgmttype === '3'
                              ? `${moment(genInfoQualityMgmt?.validityfrom).format(
                                  'MM/DD/YYYY',
                                )} - ${moment(genInfoQualityMgmt?.validityto).format('MM/DD/YYYY')}`
                              : ''
                          }
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={8}>
                      <Form.Group className='mt-3 mb-3'>
                        <Form.Check
                          type='checkbox'
                          label='PCAHO'
                          // checked={genInfoQualityMgmt?.qualitymgmttype === '4'}
                          readOnly
                        />
                      </Form.Group>
                    </Col>

                    <Col lg={1}>
                      <Form.Group className='mt-3 mb-3'>
                        <Form.Label>Validity Period</Form.Label>
                      </Form.Group>
                    </Col>

                    <Col>
                      <Form.Group className='mt-3 mb-3'>
                        <Form.Control
                          type='text'
                          placeholder=''
                          value={
                            genInfoQualityMgmt?.qualitymgmttype === '4'
                              ? `${moment(genInfoQualityMgmt?.validityfrom).format(
                                  'MM/DD/YYYY',
                                )} - ${moment(genInfoQualityMgmt?.validityto).format('MM/DD/YYYY')}`
                              : ''
                          }
                          // readOnly
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <h4>
                    <b>C. BED CAPACITY</b>
                  </h4>
                  <Row>
                    <Col lg={3}>
                      <Form.Group className='mt-3 mb-3'>
                        <Form.Label>
                          <h5>
                            <b>1. Authorized Bed Capacity</b>
                          </h5>
                        </Form.Label>
                      </Form.Group>
                    </Col>

                    <Col lg={2}>
                      <Form.Group className='mt-3 mb-3'>
                        <Form.Control
                          type='text'
                          placeholder=''
                          readOnly
                          value={genInfoBedCapacities?.authorized_bed_capacity ?? ''}
                        />
                      </Form.Group>
                    </Col>

                    <Col lg={1}>
                      <Form.Group className='mt-3 mb-3'>
                        <Form.Label>
                          <h5>
                            <b>beds</b>
                          </h5>
                        </Form.Label>
                      </Form.Group>
                    </Col>
                  </Row>
                  <ul>
                    <li>
                      <p>
                        Authorized bed: Approved number of beds issued by HFSRB/Ro, the licensing
                        officers of DOH.
                      </p>
                    </li>
                  </ul>

                  <Row>
                    <Col lg={3}>
                      <Form.Group className='mt-3 mb-3'>
                        <Form.Label>
                          <h5>
                            <b>2. Implementing Beds:</b>
                          </h5>
                        </Form.Label>
                      </Form.Group>
                    </Col>

                    <Col lg={2}>
                      <Form.Group className='mt-3 mb-3'>
                        <Form.Control
                          type='text'
                          placeholder=''
                          readOnly
                          value={genInfoBedCapacities?.implementingbeds ?? ''}
                        />
                      </Form.Group>
                    </Col>

                    <Col lg={1}>
                      <Form.Group className='mt-3 mb-3'>
                        <Form.Label>
                          <h5>
                            <b>beds</b>
                          </h5>
                        </Form.Label>
                      </Form.Group>
                    </Col>
                  </Row>
                  <ul>
                    <li>
                      <p>
                        Implementing Beds: Actual beds used(based on hospital management decision
                        (This is not the basis for computing Bed Occupancy Rate)).
                      </p>
                    </li>
                  </ul>

                  <Row>
                    <Col lg={3}>
                      <Form.Group className='mt-3 mb-3'>
                        <Form.Label>
                          <h5>
                            <b>3. Bed Occupancy Rate (BOR Based on Authorized Beds):</b>
                          </h5>
                        </Form.Label>
                      </Form.Group>
                    </Col>

                    <Col lg={2}>
                      <Form.Group className='mt-3 mb-3'>
                        <Form.Control
                          type='text'
                          placeholder='Enter here %'
                          readOnly
                          value={
                            parseFloat(genInfoBedCapacities?.bed_occupancy_rate).toFixed(3) ?? ''
                          }
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <ul>
                    <li>
                      <p>
                        (Total Inpatient service days for the period/Total Number of Authorized
                        beds) X (Total days in the period)) X 100.
                      </p>
                    </li>
                    <li>
                      <p>
                        Bed Occupancy Rate: The percentage of inpatient beds occupied over a given
                        period of time. It is a measure of the intensity of hospital resources
                        utilized by in-patients.
                      </p>
                    </li>
                    <li>
                      <p>
                        Inpatient Service (inpatient bed days): Unit of measure denoting the
                        services received by one-in-patient in one 24 hour period.
                      </p>
                    </li>
                    <li>
                      <p>
                        **Total inpatient Service days or inpatient Bed days= [(Inpatients remaining
                        at midnight + Total admission) - Total discharge/deaths) + (number of
                        admissions and discharges on the same day)]
                      </p>
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </AccordionUI>
          <div className='d-flex justify-content-end mt-5' style={{ gap: '1rem' }}>
            <Button variant='save' onClick={generatePdf}>
              GENERATE PDF
            </Button>

            <Button variant='save' type='submit'>
              UPDATE DOH
            </Button>

            <Button
              className='btn-light-green'
              onClick={(e) => {
                updateDoh(generalInformation?.id);
              }}
            >
              SEND DOH
            </Button>

            <Button variant='clear' onClick={redirectBackToPreviousPage}>
              CANCEL
            </Button>
          </div>
        </Form>
      </FormUI>
    </div>
  );
};

export default GeneralInformation;
