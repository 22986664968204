/* eslint-disable */

// import core & vendor packages below
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import { Row, Col, Form, Button, FormControl, InputGroup } from 'react-bootstrap';

// import components below
import FormUI from 'components/UI/FormUI';
import { department } from 'helpers/constants';
import seturlParams from 'helpers/seturlParams';
import { getBorrowedList } from 'reducers/borrowReturnReducer';

// import assets below
import './Filter.scss';
import { ReactComponent as MagnifyingGlassSVG } from 'assets/svg/magnifying_glass.svg';

// main component
const SearchInventoryFilter = ({
  printRef,
  searchData,
  departmentID,
  setShowModal,
  setSearchData,
}) => {
  const dispatch = useDispatch();

  const handleChange = (key) => (e) => {
    setSearchData({
      ...searchData,
      [key]: e.target.value,
    });
  };

  const handleSearch = (reset = false) => {
    dispatch(
      getBorrowedList({
        department_id: departmentID,
        q: reset ? '' : searchData?.search,
        per_page: 100000,
      }),
    );
  };

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle: '@page { size: portrait }',
  });

  return (
    <div className='admissions-search-inventory-filter'>
      <FormUI>
        {/* Basic Search */}
        <Row>
          <Col className='d-flex align-items-center'>
            <Form.Group className='mb-3 me-3'>
              <Form.Label>Control Number</Form.Label>
              <InputGroup className='search'>
                <InputGroup.Text>
                  <MagnifyingGlassSVG />
                </InputGroup.Text>
                <FormControl
                  value={searchData?.search}
                  onChange={handleChange('search')}
                  type='text'
                  placeholder='Search here...'
                />
              </InputGroup>
            </Form.Group>
            <div style={{ marginTop: '15px' }}>
              <Button
                onClick={() => {
                  handleSearch();
                }}
                style={{ height: '50px' }}
                className='btn btn-success me-3'
              >
                APPLY
              </Button>
              <Button
                style={{ height: '50px' }}
                className='btn btn-secondary me-3'
                onClick={() => {
                  setSearchData({
                    ...searchData,
                    search: '',
                  });
                  handleSearch(true);
                }}
              >
                RESET
              </Button>
            </div>
          </Col>
          <Col sm={3} className='borrow-item-container'>
            <Button
              onClick={() => {
                setShowModal((prev) => {
                  return {
                    ...prev,
                    borrowItem: true,
                  };
                });
              }}
              className='borrow-item'
              style={{display:"flex",alignItems:"center"}}
            >
              BORROW ITEM
            </Button>
            <Button className='print-button' onClick={handlePrint}>
              Print
            </Button>
          </Col>
        </Row>
      </FormUI>
    </div>
  );
};

SearchInventoryFilter.defaultProps = {
  searchData: {},
  departmentID: '',
  setSearchData: () => {},
};

SearchInventoryFilter.propTypes = {
  searchData: PropTypes.object,
  setSearchData: PropTypes.func,
  departmentID: PropTypes.string,
};

export default SearchInventoryFilter;
