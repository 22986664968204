/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
// import core & vendor packages below
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { Row, Col, Form, Button, FormControl, InputGroup } from 'react-bootstrap';

// import components below
import FormUI from 'components/UI/FormUI';
import { getPatient } from 'reducers/patientReducer';
import { ReactComponent as MagnifyingGlassSVG } from 'assets/svg/magnifying_glass.svg';

// import assets below
import './Filter.scss';

// main component
const ClearanceFilter = ({ data, filter, setFilter, setFilteredData, setFilteredData1 }) => {
  const dispatch = useDispatch();

  /* const [filter, setFilter] = useState({
    physician_name: '',
    case_id: '',
    date: '',
  }); */

  const handleChange = (key) => (e) => {
    setFilter({
      ...filter,
      [key]: e.target.value,
    });
  };

  const handleSubmit = () => {
      // dispatch(getPatient(filter));
    const newFilteredData = data.filter((patient) => (
      patient?.id.toString() === filter.case_id.toString() &&
      // patient?.physician_name?.toLowerCase().includes(filter.physician_name.toLowerCase()) &&
      patient?.admission_date?.includes(filter.date) ||
      patient?.id.toString().includes(filter.case_id.toString())
    ));
    setFilteredData(newFilteredData);
  };
  
  useEffect(() => {
    dispatch(getPatient());
  }, []);

  return (
    <div className='clearanceFilter mb-4'>
      <FormUI>
        <Row className='d-flex align-items-end'>
          <Col>
            <Form.Group className='mb-3'>
              <Form.Label>Physician Name</Form.Label>
              <InputGroup>
                <InputGroup.Text className='bg-white'>
                  <MagnifyingGlassSVG />
                </InputGroup.Text>
                <FormControl
                  type='search'
                  className='bg-white'
                  placeholder='Search here...'
                  value={filter.physician_name}
                  onChange={handleChange('physician_name')}
                />
              </InputGroup>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className='mb-3'>
              <Form.Label>Case ID</Form.Label>
              <FormControl
                type='number'
                className='bg-white'
                placeholder='Enter Here'
                value={filter.case_id}
                onChange={handleChange('case_id')}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className='mb-3'>
              <Form.Label>Date</Form.Label>
              <FormControl
                type='date'
                className='bg-white'
                placeholder='Enter Here'
                value={filter.date}
                onChange={handleChange('date')}
              />
            </Form.Group>
          </Col>
          <Col>
            <div className='buttonWrapper mb-3'>
              <Button variant='search' onClick={() => handleSubmit()}>
                Apply
              </Button>
            </div>
          </Col>
        </Row>
      </FormUI>
    </div>
  );
};

export default ClearanceFilter;
