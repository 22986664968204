//  import core & vendor packages below
import React, { forwardRef } from 'react';
import { Col, Form, Row } from 'react-bootstrap';

// import assets below
import './index.scss';
import logo from 'assets/images/new-mcmc-logo.png';
import { useState } from 'react';
import { useEffect } from 'react';

// main component

// eslint-disable-next-line react/display-name
export const PrintSubpoenaAdTestificandum = forwardRef((props, ref) => {
  const { REACT_APP_HOSPITAL_NAME, REACT_APP_HOSPITAL_STREET_ADDRESS } = process.env;
  const [formData, setFormData] = useState({});
  useEffect(() => {
    if (props) {
      setFormData(props.data);
    }
  }, [props]);
  return (
    <div id='print_subpoena-ad-testificandum' ref={ref}>
      <div className='header'>
        <img src={logo} alt='print_logo' />
        <h1>{REACT_APP_HOSPITAL_NAME}</h1>
        <h3>{REACT_APP_HOSPITAL_STREET_ADDRESS}</h3>
      </div>
      <div>
        <div className='titleFlex'>
          <div className='titleFlex'>
            <Form.Label>{formData?.name_of_hospital}</Form.Label>
            <Form.Label>Name of Healthcare Facility</Form.Label>
          </div>
          <div className='titleFlex'>
            <Form.Label>{formData?.address}</Form.Label>
            <Form.Label>Address</Form.Label>
          </div>
        </div>

        <Row className='mb-4'>
          <Col>
            <div className='startFlex'>
              <Form.Label>Date</Form.Label> <Form.Label>{formData?.date}</Form.Label>
            </div>

            <div className='startFlex'>
              <Form.Label>HON.</Form.Label> <Form.Label>{formData?.HON}</Form.Label>
            </div>

            <Form.Group className='left-input-group'>
              <Form.Label>Presiding Judge</Form.Label>

              <div className='startFlex'>
                <Form.Label>Regional Court, Branch</Form.Label>{' '}
                <Form.Label>{formData?.regional_court_branch}</Form.Label>
              </div>

              <Form.Label>National Capital Judicial Region, Manila</Form.Label>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className='startFlex'>
              <p>Dear</p>
              <p>={formData?.dear}</p>
            </div>
          </Col>
        </Row>

        <Row className='mb-2'>
          <Col>
            <p className='mb-4'>
              May we write in connection with the Honorable Court&apos;s Subpoena ad testification
              addressed to the undersigned and dated <Form.Label>{formData?.dated}</Form.Label>, and
              directing me to appear ad testify in the case people vs.{' '}
              <Form.Label>{formData?.vs}</Form.Label> Crim. Case No.{' '}
              <Form.Label>{formData?.case_no}</Form.Label> which is scheduled for hearing on{' '}
              <Form.Label>{formData?.hearing_on}</Form.Label>. May I apologized in advance for the
              reason that Dr.
              <Form.Label>{formData?.dr_full_name}</Form.Label>, the actual attending physician of
              patient <Form.Label>{formData?.patient_full_name}</Form.Label> is no longer with our
              hospital / institution since <Form.Label>{formData?.since_year}</Form.Label> and we
              have no knowledge of where he is currently residing or practicing her profession. May
              I explain that I have no personal knowledge in the manner of treatment of her patient
              and it is only the patient&apos;s attending physician who can intelligently explain
              the entries in the medical record which is the subject of the Honorable Court&apos;s
              Subpoena. May statements before the court would only be bordering on speculations or
              hearsays.
            </p>

            <p className='mb-5'>
              Again our apologies for the situation and we express our willingness to help in some
              other ways that we can in connection the case.
            </p>

            <div className='signature mb-4 align-items-start'>
              <Form.Label>{formData?.chief_of_hospital}</Form.Label>
              <Form.Label>Chief of Hospital</Form.Label>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
});
