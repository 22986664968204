/* eslint-disable camelcase */
import React from 'react';
import logo from 'assets/images/strh.png';

import './PrintContent.scss';
import { Col, Row } from 'react-bootstrap';
import NetIncreaseList from './NetIncreaseList';
import ActivitiesList from './ActivitiesList';
import moment from 'moment/moment';

// eslint-disable-next-line react/display-name
export const PrintContent = React.forwardRef((props, ref) => {
  const { REACT_APP_HOSPITAL_NAME, REACT_APP_HOSPITAL_STREET_ADDRESS } =
  process.env;
  const { reportCashFlow, date } = props;
  return (
    <div id='print_cashflow_statement' ref={ref}>
      <div className='header'>
        <img src={logo} alt='print_logo' />
        <h1>{REACT_APP_HOSPITAL_NAME}</h1>
        <h3>{REACT_APP_HOSPITAL_STREET_ADDRESS}</h3>
      </div>
      <p style={{ float: 'right' }}>Date: {moment(date).format('MMMM DD, YYYY')}</p>
      <div>
        <h5 className='mt-2 mb-4'>Assets List</h5>
        <Row className='mb-4'>
          <Col>
            {reportCashFlow.length > 0 && (
              <>
                <NetIncreaseList data={reportCashFlow[0]} />
                <ActivitiesList data={reportCashFlow[1]} />
              </>
            )}
          </Col>

          <Col></Col>
        </Row>
      </div>
    </div>
  );
});
