/* eslint-disable multiline-comment-style */
/* eslint-disable no-unused-vars */

// import core & vendor packages below
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Form, FormControl, Button, Tabs, Tab, Image } from 'react-bootstrap';

// import components below
import AddRecord from './AddRecord';
import ViewRecord from './ViewRecord';
import TabsUI from 'components/UI/TabsUI';
import PayrollTable from './PayrollTable';
import ConditionalRender from 'components/ConditionalRender';
import { TableFiltersPermission, checkStringPermission } from 'helpers/filteredPermissions';
import { showUsers, getACLState, getRoles, getDepartments, getLogs } from 'reducers/aclReducer';

// import assets below
import './index.scss';
import logoImage from 'assets/images/strh.png';
import { ReactComponent as PlusSVG } from 'assets/svg/circle-plus-blue.svg';

// main components
const LeaveContainer = () => {
  const [showScreen, setShowScreen] = useState(1);
  const [requestData, setRequestData] = useState();

  return (
    <>
      {/* Default View */}
      <ConditionalRender
        condition={showScreen === 1}
        renderIf={
          <div className='advances-container shadow-sm'>
            <div className='box-container blue'>
              <Form.Group className='inline-group'>
                <Form.Label>PAYROLL LOGS</Form.Label>

                <FormControl className='blue-control' placeholder='Search Employee' />
              </Form.Group>
            </div>

            <div className='box-container justify-content-start'>
              <Image className='website-logo' src={logoImage} />

              <Form.Group className='employee-detail'>
                <p>DETAILS HERE</p>
                <span>Company Address</span>
              </Form.Group>

              <Form.Group className='employee-detail'>
                <p>JOHN DOE</p>
                <span>Department Manager</span>
              </Form.Group>
            </div>

            <hr />

            <div className='box-container py-0'>
              <div className='header'>
                <h2>Display Logs</h2>

                <div className='functions'>
                  <Form.Group className='inline-group'>
                    <Form.Label>From:</Form.Label>

                    <FormControl type='date' className='seven' placeholder='Enter Here' />
                  </Form.Group>

                  <Form.Group className='inline-group'>
                    <Form.Label>To:</Form.Label>

                    <FormControl type='date' className='seven' placeholder='Enter Here' />
                  </Form.Group>

                  <Button className='filter'>
                    {/* <UploadSVG /> */}
                    Apply Filter
                  </Button>
                </div>
              </div>
            </div>

            {/* Main Content */}
            <div className='box-container pt-0 px-0'>
              <PayrollTable setShowScreen={setShowScreen} setRequestData={setRequestData} />
            </div>
          </div>
        }
      />

      {/* View Record */}
      <ConditionalRender
        condition={showScreen === 2}
        renderIf={<ViewRecord requestData={requestData} setShowScreen={setShowScreen} />}
      />

      {/* Add Record */}
      <ConditionalRender
        condition={showScreen === 3}
        renderIf={<AddRecord setShowScreen={setShowScreen} />}
      />
    </>
  );
};

export default LeaveContainer;
