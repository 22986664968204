/* eslint-disable camelcase */
/* eslint-disable quotes */
/* eslint-disable no-unused-vars */
//  import core & vendor packages below
import { toast } from 'react-toastify';
import { useReactToPrint } from 'react-to-print';
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Table,
  Accordion,
  Button,
  OverlayTrigger,
  Popover,
  Row,
  Col,
  Form,
  FormControl,
} from 'react-bootstrap';

// import components below
import PatientSelection from './PatientSelection';
import { PrintSubpoenaDucesTecum } from './PrintComponents/PrintSubpoenaDucesTecum';
import { getPatients, selectPatientData } from 'reducers/patientReducer';
import ConditionalRender from 'components/ConditionalRender';
import { selectActivePatients } from 'reducers/medicalRecordReducer';
import useCreateRecordResponse from './hooks/useCreateRecordResponse';
import { createDucesTecum, getMedicalFormsbyID } from 'reducers/thunks/medicalRecordThunks';
import axios from 'axios';

// main component
const SubpoenaDuceTecum = ({ setShowScreen, setViewForm, viewForm, setIsEdit, isEdit }) => {
  const dispatch = useDispatch();
  const printRef = useRef(null);
  const logs = useSelector(selectActivePatients);
  const [trigger, setTrigger] = useState({ trigger: false, patientID: false, recordID: false });
  const patientSelection = useSelector(selectPatientData);
  const [showPatientSelection, setShowPatientSelection] = useState(false);
  const [defaultData, setDefaultData] = useState();

  const [isAdd, setIsAdd] = useState(false);
  const [formData, setFormData] = useState({
    name_of_hospital: '',
    address: '',
    date: '',
    HON: '',
    presiding_judge: '',
    regional_court_branch: '',
    dear: '',
    dated: '',
    vs: '',
    case_no: '',
    year: '',
    himd_supervisor: '',
    chief_of_hospital: '',
    patientId: Math.random(),
    type: 'subpoena_duces_tecum',
  });
  const [validated, setValidated] = useState(false);
  const isEditing = isEdit === true;
  const isAdding = isAdd === true;
  const isNotEditing = isEdit === false;
  const snakeCaseString = (str) => {
    return (
      str &&
      str
        .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
        .map((s) => s.toLowerCase())
        .join('_')
    );
  };
  // For Add
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    if (!form.checkValidity() === false) {
      setTrigger({ ...trigger, trigger: true });
      setIsEdit(false);
      dispatch(createDucesTecum({ ...formData, method: 'post' }));
      return;
    }
    toast.warning('Fill up the  fields');
    setValidated(true);
  };

  // For Edit
  const saveChanges = () => {
    setTrigger({ ...trigger, trigger: true, recordID: true });
    const res = dispatch(
      createDucesTecum({
        ...formData,
        method: 'put',
        type: 'subpoena_duces_tecum',
      }),
    );

    if (res.payload.status === 'Success') {
      setIsEdit(false);
      setDefaultData(formData);

      return;
    }

    toast.error('Record is not Created/Updated');
  };

  const handleChange = (key) => (e) => {
    setFormData({
      ...formData,
      [key]: e.target.value,
    });

    return;
  };

  const handleShowPatientSelection = () => {
    setShowPatientSelection(true);
    return;
  };
  
  const handleClosePatientSelection = () => {
    setShowPatientSelection(false);
    return;
  };

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    onBeforePrint: () => console.log('before printing'),
  });

  useEffect(() => {
    if (viewForm?.patientID) {
      setTrigger({ ...trigger, patientID: true });
      const patientid = viewForm?.patientID;
      dispatch(getMedicalFormsbyID(patientid));
    }
  }, []);

  /* useEffect(() => {
    if (Object.keys(viewForm.data).length === 0) {
      setIsAdd(true);
      setIsEdit(true);
    }
  }, [isEdit]); */
  useEffect(() => {
    dispatch(getPatients());

    if (Object.keys(viewForm.data).length === 0) {
      setIsAdd(true);
      setIsEdit(true);
      return;
    }

    // for refactor: externalize below axios code in to thunk
    const recordId = viewForm?.data?.id;
    console.log(recordId);
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/medical_records/form/${recordId}`)
      .then((response) => {
        setFormData(response.data?.data?.mr_subpoena_duces_tecum);
        setDefaultData(response.data?.data?.mr_subpoena_duces_tecum);
      })
      .catch((error) => console.log('error fetching record info: ', error));
  }, []);

  useEffect(() => {
    const {
      isSuccess,
      isFailed,
      isFetchViewSuccess,
      isFetchRecordSuccess,
      patientID,
      medicalRecordID,
      successData,
    } = logs;

    useCreateRecordResponse({
      isSuccess,
      isFailed,
      isFetchViewSuccess,
      isFetchRecordSuccess,
      patientID,
      medicalRecordID,
      trigger,
      setTrigger,
      setIsEdit,
      setShowScreen,
      dispatch,
    });

    /* if (isFetchRecordSuccess && trigger.recordID) {
      const data = medicalRecordID.mr_subpoena_duces_tecum;
      setFormData(data);
    } */
  }, [logs]); 

  const textBox = (key) => {
    return (
      <FormControl
        type={`${key === 'age' ? 'number' : 'text'}`}
        disabled={!isEdit}
        style={{ width: `${isEdit ? '' : `${formData[key]?.length}ch`}` }}
        className={`${isEdit ? 'editable' : 'non-editable'} ${key === 'age' ? 'age' : ''}`}
        value={formData[key]}
        onChange={handleChange(key)}
      />
    );
  };
  
  useEffect(() => { console.log(formData); });

  return (
    <>
      <div style={{ display: 'none' }}>
        <PrintSubpoenaDucesTecum data={formData} ref={printRef} />
      </div>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row className='mb-4'>
          <Col className='first-row'>
            <Form.Group className='input-group'>
              <FormControl
                placeholder='Enter Here'
                value={formData?.name_of_hospital}
                onChange={handleChange('name_of_hospital')}
                className='text-center'
                disabled={!isEdit}
              />
              <Form.Label>Name of Healthcare Facility</Form.Label>
            </Form.Group>

            <Form.Group className='input-group'>
              <FormControl
                placeholder='Enter Here'
                value={formData?.address}
                onChange={handleChange('address')}
                className='text-center'
                disabled={!isEdit}
              />
              <Form.Label>Address</Form.Label>
            </Form.Group>
          </Col>
        </Row>

        <Row className='mb-5'>
          <Col className='second-row align-items-start'>
            <Form.Group className='input-group mb-3'>
              <Form.Label>Date</Form.Label>
              <FormControl
                type='date'
                placeholder='Enter Here'
                value={formData?.date}
                onChange={handleChange('date')}
                disabled={!isEdit}
              />
            </Form.Group>

            <Form.Group className='input-group'>
              <Form.Label>HON.</Form.Label>
              <FormControl
                placeholder='Enter Here'
                value={formData?.HON}
                onChange={handleChange('HON')}
                disabled={!isEdit}
              />
            </Form.Group>

            <Form.Group className='left-input-group'>
              <p>Presiding Judge</p>

              <div className='group'>
                <Form.Label>Regional Court, Branch</Form.Label>
                <FormControl
                  placeholder='Enter Here'
                  value={formData?.regional_court_branch}
                  onChange={handleChange('regional_court_branch')}
                  disabled={!isEdit}
                />
              </div>

              <p>National Capital Judicial Region, Manila</p>
            </Form.Group>
          </Col>
        </Row>

        <Row className='mb-2'>
          <Col className='letter-cont'>
            <h2>Dear </h2>{' '}
            <FormControl
              placeholder='Enter Here'
              value={formData?.dear}
              onChange={handleChange('dear')}
              disabled={!isEdit}
            />
            ,
          </Col>
        </Row>

        <Row className='mb-2'>
          <Col>
          <Form.Group>
            <p className='mb-4'>
              This has reference with the Honorable Court&apos;s Subpoena Duces Tecum dated{' '}
              <FormControl
                type='date'
                placeholder='Enter Here'
                value={formData?.dated}
                onChange={handleChange('dated')}
                disabled={!isEdit}
              />
              , in the case of People vs{' '}
              <FormControl
                type={'vs'}
                style={{ width: `${isEdit ? '' : `${formData['vs']?.length}ch`}` }}
                disabled={!isEdit}
                placeholder='Enter Here'
                value={formData?.vs}
                onChange={handleChange('vs')}
                onClick={handleShowPatientSelection}
              />
              ,{' '}
              <FormControl
                placeholder='Case No.'
                value={formData?.case_no}
                onChange={handleChange('case_no')}
                disabled={!isEdit}
              />{' '}
              <FormControl
                type='number'
                placeholder='Year'
                disabled={!isEdit}
                value={formData?.year}
                onChange={handleChange('year')}
                onKeyDown={(evt) =>
                  (evt.key === 'e' || evt.key === 'E' || evt.key === '-' || evt.key === '.') &&
                  evt.preventDefault()
                }
              />
            </p>
            </Form.Group>

            <p className='mb-4'>
              We deeply regret that due to reason beyond our control, we will not be to present the
              Subpoenaed document on the said date. May we request the honorable court to reset or
              re-schedule the date for us to have ample time to locate the needed medical record.
            </p>

            <p className='mb-4'>
              Praying that this explanation merit your very kind consideration and understanding.
              Thank you.
            </p>

            <div className='signature mb-4 align-items-start'>
              <p>Respectfully yours,</p>
              <Form.Group className='attending-physician'>
                <FormControl
                  placeholder='Enter Here'
                  value={formData?.chief_of_hospital}
                  onChange={handleChange('chief_of_hospital')}
                  className='text-center'
                  disabled={!isEdit}
                />
                <Form.Label>Chief of Hospital</Form.Label>
              </Form.Group>

              <p>
                <b>Noted By</b>
              </p>

              <Form.Group className='attending-physician'>
                <FormControl
                  placeholder='Enter Here'
                  value={formData?.himd_supervisor}
                  className='text-center'
                  onChange={handleChange('himd_supervisor')}
                  disabled={!isEdit}
                />
                <Form.Label>COH</Form.Label>
              </Form.Group>
            </div>
          </Col>
        </Row>

        <Row className=''>
          <Col className='form-buttons'>
            {/* Buttons to show for Add */}
            <ConditionalRender
              condition={isAdding}
              renderIf={
                <>
                  <Button type='submit' className='save' disabled={trigger.trigger}>
                    CREATE RECORD
                  </Button>
                  <Button
                    onClick={() => {
                      setIsEdit(false);
                      setShowScreen(0);
                    }}
                    className='cancel'
                  >
                    CANCEL
                  </Button>
                </>
              }
            />

            {/* Buttons to show for Edit/View */}
            <ConditionalRender
              condition={!isAdding}
              renderIf={
                <>
                  <ConditionalRender
                    condition={isNotEditing}
                    renderIf={
                      <>
                        <Button className='print' onClick={handlePrint}>
                          PRINT CERTIFICATE
                        </Button>
                        <Button
                          onClick={() => {
                            setIsEdit(true);
                          }}
                          className='edit'
                        >
                          EDIT CERTIFICATE
                        </Button>
                      </>
                    }
                  />

                  <ConditionalRender
                    condition={isEditing}
                    renderIf={
                      <>
                        <Button
                          onClick={() => {
                            saveChanges();
                          }}
                          className='save'
                          disabled={trigger.trigger}
                        >
                          SAVE CHANGES
                        </Button>
                        <Button
                          onClick={() => {
                            setIsEdit(false);
                            setFormData(defaultData);
                          }}
                          className='cancel'
                        >
                          CANCEL
                        </Button>
                      </>
                    }
                  />
                </>
              }
            />
          </Col>
        </Row>
      </Form>
      <PatientSelection
        fields={formData}
        setFields={setFormData}
        patientSelection={patientSelection}
        showPatientSelection={showPatientSelection}
        handleClosePatientSelection={handleClosePatientSelection}
      /> 
    </>
  );
};

export default SubpoenaDuceTecum;
