/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable multiline-comment-style */
/* eslint-disable react/jsx-indent-props */

// import core & vendor packages below
import axios from 'axios';
import moment from 'moment';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button, Modal, Form, FormControl } from 'react-bootstrap';

// import assets below
import SOAPNote from './SOAPNote';
import NoteForm from './NoteForm';
import VitalSigns from './VitalSigns';
import FormUI from 'components/UI/FormUI';
import ProgressNotes from './ProgressNotes';
import PatientHistory from './PatientHistory';
import DischargeNotes from './DischargeNotes';
import TreatmentRecord from './TreatmentRecord';
import { showPatient } from 'reducers/patientReducer';
import PhysicalExamination from './PhysicalExamination';
import ConditionalRender from 'components/ConditionalRender';

import './style.scss';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';

const initialState = {
  // Note Type
  noteType: '',
  patientId: '',

  // Old Vital Signs
  temperature: '',
  blood_pressure: '',
  respiration: '',
  pain: '',
  pulse_rate: '',
  oxygen_saturation: '',

  // Progress Notes
  note_description: '',
  additional_notes: '',

  // Discharge Notes
  doctorName: '',
  follow_up_date: '',
  follow_up_time: '',
  name_of_medicine: '',
  purpose: '',
  amour_doze: '',
  frequency_route_duration: '',
  precautions: '',
  diet: '',
  home_care: '',
  home_care_details: '',
  others: '',

  // New Vital Signs
  bp: '',
  pr: '',
  rr: '',
  temp: '',
  o2Sat: '',
  on: '',
  painScale: '',
  weight: '',
  height: '',
  chiefComplaint: '',
  informat: '',
  reliability: '',
  historyOfIllness: [
    {
      id: 1,
      value: '',
    },
  ],
  pastMedicalHistory: {
    CVA: false,
    cancer: false,
    others: false,
    diabetes: false,
    asthmaCOPD: false,
    heartDisease: false,
    hypertension: false,
  },
  pastSurgeryHospitalization: [
    {
      id: 1,
      value: '',
    },
  ],
  developmentalHistory: [
    {
      id: 1,
      value: '',
    },
  ],
  familyHistory: {
    CVA: false,
    cancer: false,
    others: false,
    diabetes: false,
    asthmaCOPD: false,
    heartDisease: false,
    hypertension: false,
  },
  obHistory: {
    g: '',
    p: '',
    lmp: '',
    edc: '',
    aog: '',
  },
  allergies: {
    noneKnown: false,
    food: false,
    drug: false,
    others: false,
  },
  socialHistory: {
    tobacco: false,
    tobaccoPacks: '',
    alcohol: false,
    drugs: false,
  },
  currentMeds: '',
  lastmeal: '',
  reviewOfSystems: {
    constitutional: {
      isTrue: false,
      symptoms: {
        fever: false,
        chills: false,
        weightLoss: false,
        fatigue: false,
      },
    },
    eyes: {
      isTrue: false,
      symptoms: {
        acuityChanges: false,
        glasses: false,
        symptoms: false,
      },
    },
    earsNoseThroat: {
      isTrue: false,
      symptoms: {
        hearingLoss: false,
        soreThroat: false,
        discharge: false,
      },
    },
    cardiovascular: {
      isTrue: false,
      symptoms: {
        chestPain: false,
        palpitations: false,
      },
    },
    respiratory: {
      isTrue: false,
      symptoms: {
        SOB: false,
        cough: false,
        sputum: false,
      },
    },
    gastrointestinal: {
      isTrue: false,
      symptoms: {
        nausea: false,
        vomiting: false,
        diarrhea: false,
        abdominalPain: false,
      },
    },
    genitourinary: {
      isTrue: false,
      symptoms: {
        dysuria: false,
        frequency: false,
        urgency: false,
        nocturia: false,
      },
    },
    musculoskeletal: {
      isTrue: false,
      symptoms: {
        musclePain: false,
        jointPain: false,
      },
    },
    skin: {
      isTrue: false,
      symptoms: {
        rash: false,
        lesions: false,
      },
    },
    neurologic: {
      isTrue: false,
      symptoms: {
        headache: false,
        dizziness: false,
        seizure: false,
        focalWeakness: false,
      },
    },
    endocrine: {
      isTrue: false,
      symptoms: {
        depression: false,
        anxiety: false,
        psychHistory: false,
      },
    },
    hematology: {
      isTrue: false,
      symptoms: {
        adenopathy: false,
        bruising: false,
      },
    },
    immnuneAllergy: {
      isTrue: false,
      symptoms: {
        urticaria: false,
      },
    },
  },
  // name: '',
  // age: '',
  // hospitalRecord: '',
  // address: '',
  // dateOfBirth: '',
  // General
  general_appearance_skin: '',
  mental_status: '',
  head_eye_ears_neuroexam: '',
  chest_lungs: '',
  heart: '',
  abdomen: '',
  rectum_genitalias: '',
  extremities: '',
  equal: '',
  reactive: '',
  eye_opening: '',
  verbal_response: '',
  best_motor_response: '',
  glasgow_total: '',
  workingImpression: '',
  //
  observations: [
    {
      id: 1,
      time: '',
      observationRemarks: '',
      physicianOrders: '',
    },
  ],
  // For SOAP Note
  treatmentRecord: '',
};

// main component
const ModalNote = ({ showProfile, setShowProfile }) => {
  const dispatch = useDispatch();

  const [stateData, setStateData] = useState(initialState);

  const handleChange = (key) => (e) => {
    setStateData({
      ...stateData,
      [key]: e.target.value,
      patientId: showProfile?.profileId,
    });
  };

  const resetState = () => {
    setStateData({
      ...initialState,
      patientId: showProfile?.profileId,
    });
  };

  const handleSubmit = () => {
    const { noteType } = stateData;

    console.log('noteType', noteType);

    const arrNotesType = [
      {
        type: 'Progress Notes',
        url: 'note',
      },
      {
        type: 'Vital Signs',
        url: 'vital_sign',
      },
      {
        type: 'Discharge Note',
        url: 'discharge_note',
      },
      {
        type: 'Treatment Record',
        url: 'treatment_record',
      },
      {
        type: 'SOAP Note',
        url: 'treatment_record',
      },
      {
        type: 'Physical Examination on Admission',
        url: 'physical_exam',
      },
      {
        type: 'Patient History',
        url: 'patient_history',
      },
    ];

    if (noteType === 'Vital Signs') {
      const submitData = {
        bp: stateData?.bp,
        pr: stateData?.pr,
        rr: stateData?.rr,
        on: stateData?.on,
        temp: stateData?.temp,
        o2Sat: stateData?.o2Sat,
        heart: stateData?.heart,
        equal: stateData?.equal,
        weight: stateData?.weight,
        height: stateData?.height,
        abdomen: stateData?.abdomen,
        informat: stateData?.informat,
        lastmeal: stateData?.lastmeal,
        reactive: stateData?.reactive,
        patientId: stateData?.patientId,
        painScale: stateData?.painScale,
        obHistory: stateData?.obHistory,
        allergies: stateData?.allergies,
        reliability: stateData?.reliability,
        currentMeds: stateData?.currentMeds,
        chest_lungs: stateData?.chest_lungs,
        extremities: stateData?.extremities,
        eye_opening: stateData?.eye_opening,
        familyHistory: stateData?.familyHistory,
        socialHistory: stateData?.socialHistory,
        mental_status: stateData?.mental_status,
        glasgow_total: stateData?.glasgow_total,
        chiefComplaint: stateData?.chiefComplaint,
        reviewOfSystems: stateData?.reviewOfSystems,
        verbal_response: stateData?.verbal_response,
        historyOfIllness: stateData?.historyOfIllness,
        rectum_genitalias: stateData?.rectum_genitalias,
        workingImpression: stateData?.workingImpression,
        pastMedicalHistory: stateData?.pastMedicalHistory,
        best_motor_response: stateData?.best_motor_response,
        developmentalHistory: stateData?.developmentalHistory,
        general_appearance_skin: stateData?.general_appearance_skin,
        head_eye_ears_neuroexam: stateData?.head_eye_ears_neuroexam,
        pastSurgeryHospitalization: stateData?.pastSurgeryHospitalization,
      };

      axios.post('/vital_sign', submitData).then((response) => {
        axios.get('/user/pending').then((response) => {
          toast.success('Success');
          resetState();
          dispatch(showPatient(showProfile?.profileId));
        }, []);
      });

      return;
    }

    if (noteType === 'SOAP Note') {
      const submitData = {
        patientId: stateData?.patientId,
        description: stateData?.description,
      };

      axios.post('/treatment_record', submitData).then((response) => {
        axios.get('/user/pending').then((response) => {
          toast.success('Success');
          resetState();
          dispatch(showPatient(showProfile?.profileId));
        }, []);
      });

      return;
    }

    if (noteType === 'Patient History') {
      const submitData = {
        patientId: stateData?.patientId,
        description: stateData?.description,
      };

      axios.post('/note/history-note', submitData).then((response) => {
        axios.get('/user/pending').then((response) => {
          toast.success('Success');
          resetState();
          dispatch(showPatient(showProfile?.profileId));
        }, []);
      });

      return;
    }

    arrNotesType.forEach((data) => {
      if (data?.type === 'Physical Examination on Admission' && data?.type === noteType) {
        const { general_survey, bp, hr, rr, temp, height, weight } = stateData;

        if (!general_survey || !bp || !hr || !rr || !temp || !height || !weight) {
          toast.warning('Please fill in the required fields');
          return;
        }
      }

      if (data?.type === noteType) {
        axios.post(data?.url, stateData).then((response) => {
          axios.get('/user/pending').then((response) => {
            toast.success('Success');
            resetState();
            dispatch(showPatient(showProfile?.profileId));
          }, []);
        });
      }
    });
  };

  const closeModal = () => {
    setShowProfile((prevState) => {
      return {
        ...prevState,
        note: false,
      };
    });
  };

  const SelectedNote = [
    {
      id: 1,
      title: 'Progress Notes',
      condition: stateData?.noteType === 'Progress Notes',
      component: ProgressNotes,
    },
    // {
    //   id: 2,
    //   title: 'Vital Signs',
    //   condition: stateData?.noteType === 'Vital Signs',
    //   component: VitalSigns,
    // },
    {
      id: 3,
      title: 'Discharge Note',
      condition: stateData?.noteType === 'Discharge Note',
      component: DischargeNotes,
    },
    {
      id: 4,
      title: 'Treatment Record',
      condition: stateData?.noteType === 'Treatment Record',
      component: TreatmentRecord,
    },
    {
      id: 5,
      title: 'Vital Signs',
      condition: stateData?.noteType === 'Vital Signs',
      component: NoteForm,
    },
    {
      id: 6,
      title: 'SOAP Note',
      condition: stateData?.noteType === 'SOAP Note',
      component: SOAPNote,
    },
    {
      id: 7,
      title: 'Physical Examination on Admission',
      condition: stateData?.noteType === 'Physical Examination on Admission',
      component: PhysicalExamination,
    },
    {
      id: 8,
      title: 'Patient History',
      condition: stateData?.noteType === 'Patient History',
      component: PatientHistory,
    },
  ];

  getProfileId(showProfile, setStateData);

  useEffect(() => {
    console.log('stateData', stateData);
  }, [stateData]);

  return (
    <Modal
      size='lg'
      show={showProfile.note}
      onHide={closeModal}
      aria-labelledby='Add Lab Request Modal'
      className='add-lab-request-modal'
    >
      <Modal.Body>
        <div className='add-lab-request'>
          <div className='d-flex header'>
            <p>Add Note</p>

            <CircleXMark
              style={{ width: '2em', cursor: 'pointer' }}
              onClick={() =>
                setShowProfile((prevState) => {
                  return { ...prevState, note: false };
                })
              }
            />
          </div>
        </div>

        <FormUI>
          <Form className='lab-request-form'>
            <Row>
              <Col>
                <Form.Group className='mb-4'>
                  <Form.Label>Note Type</Form.Label>
                  <Form.Select value={stateData?.noteType} onChange={handleChange('noteType')}>
                    <option value='' hidden>
                      Select Type
                    </option>
                    {/* <option value='Note Form'>Note Form</option> */}
                    <option value='Vital Signs'>Vital Signs</option>
                    <option value='Progress Notes'>Progress Notes</option>
                    <option value='Discharge Note'>Discharge Note</option>
                    <option value='Patient History'>Patient History</option>
                    {/* <option value='Treatment Record'>Treatment Record</option> */}
                    <option value='SOAP Note'>SOAP Note</option>
                    <option value='Physical Examination on Admission'>
                      Physical Examination on Admission
                    </option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>

            <hr />

            {SelectedNote.map(({ id, condition, component: Component }) => {
              return (
                <ConditionalRender
                  key={id}
                  condition={condition}
                  renderIf={
                    <Component
                      stateData={stateData}
                      handleChange={handleChange}
                      setStateData={setStateData}
                    />
                  }
                />
              );
            })}

            <Row>
              <Col>
                <Form.Group className='mt-4 buttons-cont'>
                  <Button className='add-button' variant='primary' onClick={handleSubmit}>
                    Add
                  </Button>
                  <Button className='cancel-button' vvariant='secondary' onClick={closeModal}>
                    Cancel
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </FormUI>
      </Modal.Body>
    </Modal>
  );
};

const getProfileId = (showProfile, setStateData) => {
  useEffect(() => {
    if (showProfile?.profileId) {
      setStateData((prev) => {
        return {
          ...prev,
          patientId: showProfile?.profileId,
        };
      });
    }
  }, [showProfile]);
};

ModalNote.defaultProps = {
  showProfile: () => {},
  setShowProfile: () => {},
};

ModalNote.propTypes = {
  showProfile: PropTypes.func,
  setShowProfile: PropTypes.func,
};

export default ModalNote;
