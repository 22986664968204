import { useState } from 'react';
import PropTypes from 'prop-types';

// main components below
import PatientTable from './components/Table';
import SearchPatientFilter from './components/Filter';
import FormPatientProfile from './components/FormPatientProfile';
import ModalLabRequest from '../../../Nursing/components/SearchPatient/PatientWorklist/LabRequest';
import ModalNote from '../../../Nursing/components/SearchPatient/PatientWorklist/Note';
import ModalSupply from '../../../Nursing/components/SearchPatient/PatientWorklist/Supply';
import ModalAddOrder from '../../../Nursing/components/SearchPatient/PatientWorklist/AddOrder';
import ModalProcedure from '../../../Nursing/components/SearchPatient/PatientWorklist/Procedure';
import ModalMedication from '../../../Nursing/components/SearchPatient/PatientWorklist/Medication';
import FileUploadModal from '../../../Nursing/components/SearchPatient/PatientWorklist/FileUpload';

// main component
const EDWorkList = ({ handleTabOnChange }) => {
  const [state, setState] = useState([]);
  const [showProfile, setShowProfile] = useState({
    isShowView: false,
    profileId: null,
    labRequest: false,
    addOrder: false,
    procedure: false,
    medication: false,
    fileUpload: false,
  });

  return (
    <><div>
      {!showProfile.isShowView ? (
        <div className='searchPatient'>
          <SearchPatientFilter setState={setState} handleTabOnChange={handleTabOnChange} />

          <PatientTable state={state} setShowProfile={setShowProfile} />
        </div>
      ) : (
        <FormPatientProfile showProfile={showProfile} setShowProfile={setShowProfile} />
      )}
    </div>
      <ModalNote setShowProfile={setShowProfile} showProfile={showProfile} />
      <ModalSupply setShowProfile={setShowProfile} showProfile={showProfile} />
      <ModalAddOrder setShowProfile={setShowProfile} showProfile={showProfile} />
      <ModalProcedure setShowProfile={setShowProfile} showProfile={showProfile} />
      <ModalMedication setShowProfile={setShowProfile} showProfile={showProfile} />
      <ModalLabRequest setShowProfile={setShowProfile} showProfile={showProfile} />
      <FileUploadModal setShowProfile={setShowProfile} showProfile={showProfile} />
    </>
  );
};

EDWorkList.defaultProps = {
  handleTabOnChange: () => { },
};

EDWorkList.propTypes = {
  handleTabOnChange: PropTypes.func,
};

export default EDWorkList;
