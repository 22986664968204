/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// Packages
import React, { useRef, useState } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import FileSaver from 'file-saver';
import { PrintContent } from './PrintContent';

// CSS
import './index.scss';
import './PrintContent.scss';
// utils
import { searchReportIncomeStatement } from 'reducers/thunks/accountingThunks';
import { selectReportIncomeStatement } from 'reducers/accountingReducer';
import axios from 'axios';
import { toast } from 'react-toastify';

// Main Component
const IncomeStatement = () => {
  const dispatch = useDispatch();
  const [isDate, setIsDate] = useState('');
  const [searchData, setSearchData] = useState({
    search: '',
  });

  const reportIncomeStatement = useSelector(selectReportIncomeStatement);
  const { income_list, expenses_list } = reportIncomeStatement;

  const handleSearchChange = async (e) => {
    const { value } = e.target;
    setIsDate(value);
    setSearchData((prev) => {
      return {
        ...prev,
        search: value,
      };
    });

    const params = {
      search: value,
    };

    dispatch(searchReportIncomeStatement(params));
  };

  const params = {
    search: '',
  };

  /*  useEffect(() => {
    dispatch(searchReportIncomeStatement(params));
  }, []); */

  // usage

  const downloadExcelFile = async () => {
    if (!income_list.length && !expenses_list.length) {
      toast.warn('Unable to export due to no record saved on this date.');
      return;
    }

    const API_URL = process.env.REACT_APP_API_BASE_URL;
    try {
      const response = await axios.get(
        `${API_URL}/income_statement/downloadExcel?search=${isDate}`,
        {
          responseType: 'blob',
          method: 'GET',
        },
      );
      const fileData = await response?.data;
      FileSaver.saveAs(fileData, `income_statement_${isDate}.xlsx`);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  const printRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  return (
    <>
      <div style={{ display: 'none' }}>
        <PrintContent ref={printRef} reportIncomeStatement={reportIncomeStatement} date={isDate} />
      </div>
      <div className='income-statement'>
        <Row>
          <Col>
            <div className='income-details'>
              <Row className='mb-4'>
                <Col sm={3}>
                  <Form.Group className='inline-label'>
                    <Form.Label className='px-1'>Date</Form.Label>
                    <Form.Control
                      value={searchData?.search}
                      onChange={handleSearchChange}
                      type='date'
                      placeholder='Enter Here'
                    />
                  </Form.Group>
                </Col>

                <Col className='form-buttons'>
                  <Button className='print-button' disabled={!isDate} onClick={handlePrint}>
                    Print
                  </Button>
                  <Button className='export' onClick={downloadExcelFile} disabled={!isDate}>
                    Export
                  </Button>
                </Col>
              </Row>

              <h5 className='mt-2 mb-4'>Income List:</h5>
              {isDate && (
                <Row className='mb-4'>
                  <Col>
                    <h6 className='mb-3'>Gross Profit</h6>
                    <Row className='mb-4'>
                      {income_list?.map(({ details, amount, id }) => {
                        return (
                          <Row className='mb-2' key={id}>
                            <Col className='asset-name'>{details}</Col>
                            <Col className='asset-amount'>{amount}</Col>
                          </Row>
                        );
                      })}
                    </Row>
                    <Row>
                      <Col className='asset-name'>Total Income</Col>
                      <Col className='asset-amount'>{reportIncomeStatement?.total_income}</Col>
                    </Row>
                  </Col>

                  <Col>
                    <h6 className='mb-3'>Expenses</h6>
                    <Row className='mb-4'>
                      {expenses_list?.map(({ details, amount, id }) => {
                        return (
                          <Row className='mb-2' key={id}>
                            <Col className='asset-name'>{details}</Col>
                            <Col className='asset-amount'>{amount}</Col>
                          </Row>
                        );
                      })}
                    </Row>
                    <Row>
                      <Col className='asset-name'>Total Expenses</Col>
                      <Col className='asset-amount'>{reportIncomeStatement?.total_expenses}</Col>
                    </Row>
                  </Col>
                </Row>
              )}

              <hr />

              {/* <Row className='mt-4 mb-2'>
              <Col className='total-cont'>
                <p>
                  Net Profit: <span>Php 1,000,000.00</span>
                </p>
              </Col>
            </Row> */}
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default IncomeStatement;
