/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';

// import components below
import { billingHistoryClass } from 'helpers';
import Table from 'components/ReactTable';

// import assets below
import './index.scss';
import { selectBillingData } from 'reducers/billingReducer';
import axios from 'axios';
import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import PrintContent from '../../PrintableComponents/billingHistory';

// main component
const BillingHistoryTable = () => {
  const [billData, setBillData] = useState(null);
  const printRef = useRef(null);
  // const state = useSelector(selectBillingData);

  // Sample Table List
     const state = [
       {
         patientId: 1,
         amount: '15,000',
         billing_number: '14578220',
         date_time: '01/20/2023',
         status: 'For Billing',
       },
       {
         patientId: 2,
         amount: '15,000',
         billing_number: '14578220',
         date_time: '01/20/2023',
         status: 'Completed',
       },
     ];

  const tableColumns = React.useMemo(
    () => [
      { Header: 'Billing Number', accessor: 'billing_number' },
      { Header: 'Date / Time', accessor: 'date_time' },
      { Header: 'Amount', accessor: 'amount' },
      { Header: 'Status', accessor: 'status' },
    ],
    [],
  );

  const tableData = React.useMemo(
    () =>
      state?.map((dd) => ({
        data: dd,
        amount: `P${dd?.amount}`,
        patientId: dd?.patientId,
        status: billingHistoryClass(dd?.status),
        billing_number: dd?.billing_number,
        date_time: moment(dd?.createdAt).format('MMM-DD-YYYY hh:mm A'),
      })),
    [state],  
  );

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    onBeforePrint: () => console.log('before printing'),
  });

  const handleChange = (row) => {
    setBillData(row);
    setTimeout(() => {
      handlePrint();
    }, 1000);
  };

  return (
    <div className='billingHistoryTable'>
      <div style={{ display: 'none' }}>
        <PrintContent ref={printRef} data={billData} />
      </div>
      <Table
        data={tableData}
        columns={tableColumns}
        customAction={(id, data) => {
          const row = state.find((dd) => dd.patientId === data.data.patientId);
          
          return (
            <div className='table-buttons'>
              <Button className='export' onClick={() => { handleChange(row); }}>Export</Button>
              <Button className='print' onClick={() => { handleChange(row); }}>Print</Button>
            </div>
          );
        }}
        pagination={['BOTTOM']}
      />
    </div>
  );
};

export default BillingHistoryTable;
