/* eslint-disable react/no-unescaped-entities */
/* eslint-disable multiline-comment-style */
/* eslint-disable camelcase */
/* eslint-disable space-before-function-paren */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import axios from 'axios';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useState, useEffect, useRef } from 'react';
import { Row, Col, Table, Button, Form, FormControl, Accordion } from 'react-bootstrap';
import { useReactToPrint } from 'react-to-print';

// import components below
import TableUI from 'components/UI/TableUI';
import AccordionUI from 'components/UI/AccordionUI';
import ConfirmationModal from './Modal/Confirmation';
import { showPatient } from 'reducers/patientReducer';
import ViewVitalSignModal from './Modal/ViewVitalSign';
import ModalAssessmentEdit from './Modal/AssessmentEdit';
import { selectPatientItem } from 'reducers/patientReducer';
import ConditionalRender from 'components/ConditionalRender';
import PhysicalExaminationModal from './Modal/PhysicalExamination';
import { checkStringPermission } from 'helpers/filteredPermissions';
import CurrentMedicalInformation from './Components/CurrentMedicalInformation';

// import assets below
import './index.scss';
import { ReactComponent as ArrowUpSVG } from 'assets/svg/up-chevron-svgrepo-com.svg';
import { ReactComponent as ArrowDownSVG } from 'assets/svg/down-chevron-svgrepo-com.svg';

import { integerCharFieldRules } from 'helpers/fieldValidations';
import { PrintContent } from './Components/PrintDataSheet';

const { inValidateInput } = integerCharFieldRules;

// main component
const PatientChart = ({ showProfile, setShowProfile }) => {
  const dispatch = useDispatch();

  // Redux
  const patientProfile = useSelector(selectPatientItem);

  // Variable
  const payorInformation = [];

  patientProfile?.inpatient_visits[0]?.payor_guarantors?.map((item, key) => {
    payorInformation.push(item);
  });

  patientProfile?.inpatient_visits[0]?.other_payor_guarantors?.map((item, key) => {
    payorInformation.push(item);
  });

  const newPayorInformation = payorInformation.map(function (el) {
    const o = Object.assign({}, el);
    o.show = true;
    return o;
  });

  // State
  const [openModal, setOpenModal] = useState(false);
  const [openVitalSignViewModal, setOpenVitalSignViewModal] = useState({
    isOpen: false,
    modalValue: null,
  });
  const [openPhysicalExamination, setOpenPhysicalExamination] = useState({
    isOpen: false,
    modalValue: null,
  });
  const [visitInformation, setVisitInformation] = useState({
    employer: '',
    father_name: '',
    spouse_name: '',
    spouse_address: '',
    admission_date: '',
    admission_type: '',
    attending_clerk: '',
    employer_address: '',
    hospital_service: '',
    parent_contact_no: '',
    spouse_contact_no: '',
    date_furnished_by: '',
    mother_maiden_name: '',
    employer_contact_no: '',
    philhealth_category: '',
    relation_to_patient: '',
    informant_signature: '',
    furnished_by_address: '',
    attending_physician_id: '',
    attending_physician_name: '',
    patientId: patientProfile?.id,
    visit_number: patientProfile?.active_visit_number,
    inpatientVisitId: patientProfile?.inpatient_visits[0]?.id || '',

    // For condition
    canEdit: false,
  });

  const printRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle: '@page { size: portrait }',
  });

  const [diagnosis, setDiagnosis] = useState({
    label:
      visitInformation?.admitting_diagnosis?.length > 0
        ? visitInformation?.admitting_diagnosis
        : patientProfile?.inpatient_visits[0]?.admitting_diagnosis,
    value:
      visitInformation?.admitting_diagnosis?.length > 0
        ? visitInformation?.admitting_diagnosis
        : patientProfile?.inpatient_visits[0]?.admitting_diagnosis,
  });

  const [showModal, setShowModal] = useState({
    isShowView: false,
    isShowEdit: false,
    profileData: null,
  });

  const [isValid, setIsValid] = useState(true);

  const handleChange = (key, type) => (e) => {
    if (type === 'visitInformation') {
      setVisitInformation({
        ...visitInformation,
        [key]: e.target.value,
      });
    }

    if (
      inValidateInput(visitInformation?.spouse_contact_no) ||
      visitInformation.spouse_contact_no.length !== 11
    ) {
      setIsValid(false);
    }
  };

  const handleSave = () => {
    // if (checkIfValid()) {
    //   toast.warning('Check your inputs');
    // } else {
    axios.put('/inpatient_visit', visitInformation).then((response) => {
      dispatch(showPatient(showProfile.profileId));
      toast.success('Success');
      // return back to read-only view once done editing
      setVisitInformation((prevState) => {
        return {
          ...prevState,
          canEdit: false,
        };
      });
    });
    // }
  };

  const checkIfValid = () => {
    if (visitInformation?.canEdit) {
      if (
        inValidateInput(visitInformation?.spouse_contact_no) ||
        visitInformation.spouse_contact_no.length !== 11
      ) {
        return true;
      }
    }

    return false;
  };

  const displayModal = () => {
    setOpenModal(true);
  };

  const [causeOfDeath, setCauseOfDeath] = useState('');
  const [causeOfDeathOptions, setCauseOfDeathOptions] = useState([]);

  useEffect(() => {
    axios.get('/public/ricd10').then((response) => {
      const options = response.data?.map((data) => ({
        id: data.id,
        value: data.icd10_code,
        label: data.icd10_desc,
        category: data.icd10_cat,
      }));
      setCauseOfDeathOptions(options);
    });
  }, []);

  const filterOptions = (input) => {
    return causeOfDeathOptions.splice(0, 100).filter((i) => {
      return (
        i.value.toLowerCase().includes(input.toLowerCase()) ||
        i.label.toLowerCase().includes(input.toLowerCase())
      );
    });
  };

  const loadOptions = (input) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(filterOptions(input));
      }, 1000);
    });
  };

  const [icdCat, setIcdCat] = useState('');
  const [icdCode, setIcdCode] = useState('');

  const handleCauseOfDeath = (icddesc, icdcode, icdcat) => {
    setVisitInformation({
      ...visitInformation,
      icd_code: icdcode,
      icd_category: icdcat,
      admitting_diagnosis: icddesc,
    });
  };

  const selectStyles = {
    control: (styles) => ({
      ...styles,
      color: '#404040',
      padding: '0.45em',
      borderRadius: '10px',
      borderColor: '#c8c8c8',
    }),
    menu: (styles) => ({
      ...styles,
      zIndex: '100',
    }),
  };

  const patientRecentProfile = (patientProfile) => {
    return {
      patientId: patientProfile?.id,
      visit_number: patientProfile?.active_visit_number,
      employer: patientProfile?.inpatient_visits[0]?.employer || '',
      inpatientVisitId: patientProfile?.inpatient_visits[0]?.id || '',
      father_name: patientProfile?.inpatient_visits[0]?.father_name || '',
      spouse_name: patientProfile?.inpatient_visits[0]?.spouse_name || '',
      spouse_address: patientProfile?.inpatient_visits[0]?.spouse_address || '',
      admission_date: patientProfile?.inpatient_visits[0]?.admission_date || '',
      admission_type: patientProfile?.inpatient_visits[0]?.admission_type || '',
      attending_clerk: patientProfile?.inpatient_visits[0]?.attending_clerk || '',
      hospital_service: patientProfile?.inpatient_visits[0]?.hospital_service || '',
      employer_address: patientProfile?.inpatient_visits[0]?.employer_address || '',
      parent_contact_no: patientProfile?.inpatient_visits[0]?.parent_contact_no || '',
      spouse_contact_no: patientProfile?.inpatient_visits[0]?.spouse_contact_no || '',
      date_furnished_by: patientProfile?.inpatient_visits[0]?.date_furnished_by || '',
      mother_maiden_name: patientProfile?.inpatient_visits[0]?.mother_maiden_name || '',
      employer_contact_no: patientProfile?.inpatient_visits[0]?.employer_contact_no || '',
      philhealth_category: patientProfile?.inpatient_visits[0]?.philhealth_category || '',
      relation_to_patient: patientProfile?.inpatient_visits[0]?.relation_to_patient || '',
      informant_signature: patientProfile?.inpatient_visits[0]?.informant_signature || '',
      furnished_by_address: patientProfile?.inpatient_visits[0]?.furnished_by_address || '',
      attending_physician_id: patientProfile?.inpatient_visits[0]?.attending_physician_id || '',
      attending_physician_name: patientProfile?.inpatient_visits[0]?.attending_physician_name || '',
      canEdit: false,
    };
  };

  useEffect(() => {
    console.log('patientProfile', patientProfile);

    setVisitInformation((prevState) => {
      return {
        ...prevState,
        ...patientRecentProfile(patientProfile),
      };
    });
  }, [patientProfile]);

  return (
    <div className='visitInformationTab'>
      <div style={{ display: 'none' }}>
        <PrintContent ref={printRef} visitData={visitInformation} />
      </div>
      <div className='patientProfile__header--visitInformation'>
        <h3 style={{ margin: '0px 0px 60px 0px' }}>
          <b>REVIEW INPATIENT VISIT INFORMATION</b>
        </h3>

        <AccordionUI>
          <Accordion defaultActiveKey='0' className='mb-5'>
            <Accordion.Item eventKey='0'>
              <Accordion.Header>Visit Details</Accordion.Header>
              <Accordion.Body>
                <div className='d-flex justify-content-between align-items-center'>
                  <h4 style={{ margin: '0px 0px 30px 0px' }}></h4>
                  <div className='ms-auto'>
                    <Button
                      onClick={() => {
                        if (visitInformation.canEdit) {
                          setVisitInformation((prevState) => {
                            return {
                              ...prevState,
                              ...patientRecentProfile(patientProfile),
                            };
                          });
                        } else {
                          setVisitInformation((prevState) => {
                            return {
                              ...prevState,
                              canEdit: true,
                            };
                          });
                        }
                      }}
                      className='me-3'
                      variant='addField'
                    >
                      {visitInformation.canEdit ? 'Cancel' : 'Edit Visit Information'}
                    </Button>

                    <Button className='me-3' variant='light-blue' onClick={handlePrint}>
                      Print Data Sheet
                    </Button>

                    {/* <Button className='me-3' variant='light-blue'>
              Print Stickers
            </Button>
            <Button variant='light-blue'>Print ID Band</Button> */}
                  </div>
                </div>

                {/* Visit Information Details */}
                <Row className='mb-4'>
                  <Col>
                    <div className='patientProfile__content'>
                      <Row>
                        <Col lg='3'>
                          <div className='wrapper visit-information'>
                            <span>Hospital Service</span>
                            <span>:</span>
                          </div>
                        </Col>
                        <Col lg='4'>
                          <span className='value'>
                            <Form.Select
                              disabled={!visitInformation?.canEdit}
                              value={visitInformation?.hospital_service}
                              className={`${visitInformation?.canEdit}`}
                              onChange={handleChange('hospital_service', 'visitInformation')}
                              placeholder={`${visitInformation?.canEdit ? 'Enter Here' : ''}`}
                            >
                              <option value='all'>-Select-</option>
                              <option value='ent'>ENT</option>
                              <option value='medicine'>Medicine</option>
                              <option value='ob_gyne'>OB-GYNE</option>
                              <option value='urology'>Urology</option>
                              <option value='cardiology'>Cardiology</option>
                              <option value='pulmonary Medicine'>Pulmonary Medicine</option>
                              <option value='opthalmology'>Opthalmology</option>
                              <option value='surgery'>Surgery</option>
                              <option value='orthopedic Surgery'>Orthopedic Surgery</option>
                              <option value='endocrinology'>Endocrinology</option>
                              <option value='pediatrics'>Pediatrics</option>
                              <option value='dermatology'>Dermatology</option>
                              <option value='oncology'>Oncology</option>
                              <option value='neurology'>Neurology</option>
                              <option value='gastroenterology'>Gastroenterology</option>
                              <option value='internal_medicine'>Internal Medicine</option>
                              {/* <option value='medico_legal'>Medico Legal</option> */}
                              <option value='nephrology'>Nephrology</option>
                            </Form.Select>
                          </span>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg='3'>
                          <div className='wrapper visit-information'>
                            <span>Employer</span>
                            <span>:</span>
                          </div>
                        </Col>
                        <Col lg='4'>
                          <span className='value'>
                            <FormControl
                              disabled={!visitInformation?.canEdit}
                              value={visitInformation?.employer}
                              className={`${visitInformation?.canEdit}`}
                              onChange={handleChange('employer', 'visitInformation')}
                              placeholder={`${visitInformation?.canEdit ? 'Enter Here' : ''}`}
                            />
                          </span>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg='3'>
                          <div className='wrapper visit-information'>
                            <span>Employer Address</span>
                            <span>:</span>
                          </div>
                        </Col>
                        <Col lg='4'>
                          <span className='value'>
                            <FormControl
                              disabled={!visitInformation?.canEdit}
                              value={visitInformation?.employer_address}
                              className={`${visitInformation?.canEdit}`}
                              onChange={handleChange('employer_address', 'visitInformation')}
                              placeholder={`${visitInformation?.canEdit ? 'Enter Here' : ''}`}
                            />
                          </span>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg='3'>
                          <div className='wrapper visit-information'>
                            <span>Employer Contact No</span>
                            <span>:</span>
                          </div>
                        </Col>
                        <Col lg='4'>
                          <span className='value'>
                            <FormControl
                              type='number'
                              disabled={!visitInformation?.canEdit}
                              className={`${visitInformation?.canEdit}`}
                              value={visitInformation?.employer_contact_no}
                              placeholder={`${visitInformation?.canEdit ? 'Enter Here' : ''}`}
                              onChange={handleChange('employer_contact_no', 'visitInformation')}
                              onKeyDown={(evt) =>
                                (evt.key === 'e' ||
                                  evt.key === 'E' ||
                                  evt.key === '-' ||
                                  evt.key === '.') &&
                                evt.preventDefault()
                              }
                            />
                          </span>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg='3'>
                          <div className='wrapper visit-information'>
                            <span>Father Name</span>
                            <span>:</span>
                          </div>
                        </Col>
                        <Col lg='4'>
                          <span className='value'>
                            <FormControl
                              disabled={!visitInformation?.canEdit}
                              value={visitInformation?.father_name}
                              className={`${visitInformation?.canEdit}`}
                              onChange={handleChange('father_name', 'visitInformation')}
                              placeholder={`${visitInformation?.canEdit ? 'Enter Here' : ''}`}
                            />
                          </span>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg='3'>
                          <div className='wrapper visit-information'>
                            <span>Mother Mainden Name</span>
                            <span>:</span>
                          </div>
                        </Col>
                        <Col lg='4'>
                          <span className='value'>
                            <FormControl
                              disabled={!visitInformation?.canEdit}
                              value={visitInformation?.mother_maiden_name}
                              className={`${visitInformation?.canEdit}`}
                              onChange={handleChange('mother_maiden_name', 'visitInformation')}
                              placeholder={`${visitInformation?.canEdit ? 'Enter Here' : ''}`}
                            />
                          </span>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg='3'>
                          <div className='wrapper visit-information'>
                            <span>Parent Contact No</span>
                            <span>:</span>
                          </div>
                        </Col>
                        <Col lg='4'>
                          <span className='value'>
                            <FormControl
                              type='number'
                              disabled={!visitInformation?.canEdit}
                              className={`${visitInformation?.canEdit}`}
                              value={visitInformation?.parent_contact_no}
                              onChange={handleChange('parent_contact_no', 'visitInformation')}
                              placeholder={`${visitInformation?.canEdit ? 'Enter Here' : ''}`}
                              onKeyDown={(evt) =>
                                (evt.key === 'e' ||
                                  evt.key === 'E' ||
                                  evt.key === '-' ||
                                  evt.key === '.') &&
                                evt.preventDefault()
                              }
                            />
                          </span>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg='3'>
                          <div className='wrapper visit-information'>
                            <span>Spouse name</span>
                            <span>:</span>
                          </div>
                        </Col>
                        <Col lg='4'>
                          <span className='value'>
                            <FormControl
                              disabled={!visitInformation?.canEdit}
                              value={visitInformation?.spouse_name}
                              className={`${visitInformation?.canEdit}`}
                              onChange={handleChange('spouse_name', 'visitInformation')}
                              placeholder={`${visitInformation?.canEdit ? 'Enter Here' : ''}`}
                            />
                          </span>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg='3'>
                          <div className='wrapper visit-information'>
                            <span>Spouse Address</span>
                            <span>:</span>
                          </div>
                        </Col>
                        <Col lg='4'>
                          <span className='value'>
                            <FormControl
                              disabled={!visitInformation?.canEdit}
                              value={visitInformation?.spouse_address}
                              className={`${visitInformation?.canEdit}`}
                              onChange={handleChange('spouse_address', 'visitInformation')}
                              placeholder={`${visitInformation?.canEdit ? 'Enter Here' : ''}`}
                            />
                          </span>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg='3'>
                          <div className='wrapper visit-information'>
                            <span>Spouse Contact No</span>
                            <span>:</span>
                          </div>
                        </Col>
                        <Col lg='4'>
                          <span className='value'>
                            <FormControl
                              type='number'
                              disabled={!visitInformation?.canEdit}
                              className={`${visitInformation?.canEdit}`}
                              value={visitInformation?.spouse_contact_no}
                              onChange={handleChange('spouse_contact_no', 'visitInformation')}
                              placeholder={`${
                                visitInformation?.canEdit ? 'Enter Contact Number' : ''
                              }`}
                              onKeyDown={(evt) =>
                                (evt.key === 'e' ||
                                  evt.key === 'E' ||
                                  evt.key === '-' ||
                                  evt.key === '.') &&
                                evt.preventDefault()
                              }
                            />
                          </span>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg='3'>
                          <div className='wrapper visit-information'>
                            <span>Admission Date</span>
                            <span>:</span>
                          </div>
                        </Col>
                        <Col lg='4'>
                          <span className='value'>
                            <FormControl
                              type='date'
                              disabled={!visitInformation?.canEdit}
                              value={visitInformation?.admission_date}
                              className={`${visitInformation?.canEdit}`}
                              onChange={handleChange('admission_date', 'visitInformation')}
                              placeholder={`${visitInformation?.canEdit ? 'Enter Here' : ''}`}
                            />
                          </span>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg='3'>
                          <div className='wrapper visit-information'>
                            <span>Admission Type</span>
                            <span>:</span>
                          </div>
                        </Col>
                        <Col lg='4'>
                          <span className='value'>
                            <Form.Select
                              disabled={!visitInformation?.canEdit}
                              value={visitInformation?.admission_type}
                              className={`${visitInformation?.canEdit}`}
                              onChange={handleChange('admission_type', 'visitInformation')}
                              placeholder={`${visitInformation?.canEdit ? 'Enter Here' : ''}`}
                            >
                              <option>-Select-</option>
                              <option value='ambulatory'>Ambulatory</option>
                              <option value='walk-in'>Walk-in</option>
                              <option value='public-services-unknown'>
                                Public Services/Unknown
                              </option>
                              <option value='transferred'>Transferred</option>
                            </Form.Select>
                          </span>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg='3'>
                          <div className='wrapper visit-information'>
                            <span>Attending Clerk</span>
                            <span>:</span>
                          </div>
                        </Col>
                        <Col lg='4'>
                          <span className='value'>
                            <FormControl
                              disabled={!visitInformation?.canEdit}
                              value={visitInformation?.attending_clerk}
                              className={`${visitInformation?.canEdit}`}
                              onChange={handleChange('attending_clerk', 'visitInformation')}
                              placeholder={`${visitInformation?.canEdit ? 'Enter Here' : ''}`}
                            />
                          </span>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg='3'>
                          <div className='wrapper visit-information'>
                            <span>Data Furnished By</span>
                            <span>:</span>
                          </div>
                        </Col>
                        <Col lg='4'>
                          <span className='value'>
                            <FormControl
                              disabled={!visitInformation?.canEdit}
                              value={visitInformation?.date_furnished_by}
                              className={`${visitInformation?.canEdit}`}
                              onChange={handleChange('date_furnished_by', 'visitInformation')}
                              placeholder={`${visitInformation?.canEdit ? 'Enter Here' : ''}`}
                            />
                          </span>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg='3'>
                          <div className='wrapper visit-information'>
                            <span>Furnished By Address</span>
                            <span>:</span>
                          </div>
                        </Col>
                        <Col lg='4'>
                          <span className='value'>
                            <FormControl
                              disabled={!visitInformation?.canEdit}
                              value={visitInformation?.furnished_by_address}
                              className={`${visitInformation?.canEdit}`}
                              onChange={handleChange('furnished_by_address', 'visitInformation')}
                              placeholder={`${visitInformation?.canEdit ? 'Enter Here' : ''}`}
                            />
                          </span>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg='3'>
                          <div className='wrapper visit-information'>
                            <span>Relation to Patient</span>
                            <span>:</span>
                          </div>
                        </Col>
                        <Col lg='4'>
                          <span className='value'>
                            <FormControl
                              disabled={!visitInformation?.canEdit}
                              value={visitInformation?.relation_to_patient}
                              className={`${visitInformation?.canEdit}`}
                              onChange={handleChange('relation_to_patient', 'visitInformation')}
                              placeholder={`${visitInformation?.canEdit ? 'Enter Here' : ''}`}
                            />
                          </span>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg='3'>
                          <div className='wrapper visit-information'>
                            <span>Informant Signature</span>
                            <span>:</span>
                          </div>
                        </Col>
                        <Col lg='4'>
                          <span className='value'>
                            <FormControl
                              disabled={!visitInformation?.canEdit}
                              value={visitInformation?.informant_signature}
                              className={`${visitInformation?.canEdit}`}
                              onChange={handleChange('informant_signature', 'visitInformation')}
                              placeholder={`${visitInformation?.canEdit ? 'Enter Here' : ''}`}
                            />
                          </span>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>

                <ConditionalRender
                  condition={visitInformation.canEdit === true}
                  renderIf={
                    <Row>
                      <Col className='save-cont'>
                        <Button onClick={(e) => handleSave()}>Save Changes</Button>
                      </Col>
                    </Row>
                  }
                />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </AccordionUI>
      </div>

      <CurrentMedicalInformation setShowModal={setShowModal} patientProfile={patientProfile} />

      <div className='patientProfile__header'>
        <h4>Notes</h4>
        <b>
          <h6>Patient History</h6>
        </b>
        <Row>
          <Col>
            <TableUI>
              <Table className='healthCareTeamTable'>
                <thead>
                  <tr>
                    <th>Created At</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  {patientProfile?.history_notes &&
                    patientProfile?.history_notes.map((item, index) => {
                      console.log(';ywasdasdasdasdsadasd', item);
                      return (
                        <tr key={index}>
                          <td>{moment(item?.createdAt).format('MMM-DD-YYYY hh:mm A')}</td>
                          <td>{item.description}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </TableUI>
          </Col>
        </Row>
        <b>
          <h6>Progress Notes</h6>
        </b>
        <Row>
          <Col>
            <TableUI>
              <Table className='healthCareTeamTable'>
                <thead>
                  <tr>
                    <th>Date/Time</th>
                    <th>Progress Notes</th>
                    <th>Doctor's Orders</th>
                  </tr>
                </thead>
                <tbody>
                  {patientProfile?.notes &&
                    patientProfile?.notes.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{moment(item?.createdAt).format('MMM-DD-YYYY hh:mm A')}</td>
                          <td>{item.note_description}</td>
                          <td>{item.additional_notes}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </TableUI>
          </Col>
        </Row>
        <b>
          <h6>Vital Signs</h6>
        </b>
        <Row>
          <Col>
            <TableUI>
              <Table className='healthCareTeamTable'>
                <thead>
                  <tr>
                    <th>Date/Time</th>
                    <th>BP</th>
                    <th>PR</th>
                    <th>RR</th>
                    <th>Action</th>
                    {/* <th>Temperature</th>
                    <th>Blood Pressure</th>
                    <th>Respiration</th>
                    <th>Pain</th>
                    <th>Pulse Rate</th>
                    <th>Oxygen Saturation</th> */}
                  </tr>
                </thead>
                <tbody>
                  {patientProfile?.notes &&
                    patientProfile?.vital_signs.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{moment(item?.createdAt).format('MMM-DD-YYYY hh:mm A')}</td>
                          <td>{item?.bp}</td>
                          <td>{item?.pr}</td>
                          <td>{item?.rr}</td>
                          <td>
                            <Button
                              className='btn btn-light-blue'
                              onClick={() => {
                                setOpenVitalSignViewModal({
                                  isOpen: true,
                                  modalValue: item,
                                });
                              }}
                            >
                              View
                            </Button>
                          </td>
                          {/* <td>{item?.temperature}</td>
                          <td>{item?.blood_pressure}</td>
                          <td>{item?.respiration}</td>
                          <td>{item?.pain}</td>
                          <td>{item?.pulse_rate}</td>
                          <td>{item?.oxygen_saturation}</td> */}
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </TableUI>
          </Col>
        </Row>
        <b>
          <h6>SOAP Note</h6>
        </b>
        <Row>
          <Col>
            <TableUI>
              <Table className='healthCareTeamTable'>
                <thead>
                  <tr>
                    <th>Date/Time</th>
                    <th>Description</th>
                    <th>Additional Notes</th>
                  </tr>
                </thead>
                <tbody>
                  {patientProfile?.notes &&
                    patientProfile?.treatment_records.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{moment(item?.createdAt).format('MMM-DD-YYYY hh:mm A')}</td>
                          <td>{item?.description}</td>
                          <td>{item?.additional_notes}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </TableUI>
          </Col>
        </Row>
        <b>
          <h6>Discharge Notes</h6>
        </b>
        <Row>
          <Col>
            <TableUI>
              <Table className='healthCareTeamTable'>
                <thead>
                  <tr>
                    <th>Date/Time</th>
                    <th>Doctor</th>
                    <th>Follow up Date</th>
                    <th>Follow up Time</th>
                    <th>Name of Medicine</th>
                    <th>Purpose</th>
                    <th>Amour / Dose</th>
                    <th>Frequency / Route / Duration</th>
                    <th>Precautions</th>
                    <th>Other Instructions</th>
                    <th>Other Details</th>
                  </tr>
                </thead>
                <tbody>
                  {patientProfile?.notes &&
                    patientProfile?.discharge_notes.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{moment(item?.createdAt).format('MMM-DD-YYYY hh:mm A')}</td>
                          <td>{item?.user?.full_name ? item?.user?.full_name : 'N/A'}</td>
                          <td>{item?.follow_up_date ? item?.follow_up_date : 'N/A'}</td>
                          <td>{item?.follow_up_time ? item?.follow_up_time : 'N/A'}</td>
                          <td>{item?.name_of_medicine}</td>
                          <td>{item?.purpose}</td>
                          <td>{item?.amour_doze}</td>
                          <td>{item?.frequency_route_duration}</td>
                          <td>{item?.precautions}</td>
                          <td>{item?.other_instructions}</td>
                          <td>{item?.other_details}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </TableUI>
          </Col>
        </Row>
        <b>
          <h6>Physical Examination on Admission</h6>
        </b>
        <Row>
          <Col>
            <TableUI>
              <Table className='healthCareTeamTable'>
                <thead>
                  <tr>
                    <th>Date/Time</th>
                    <th>BP</th>
                    <th>HR</th>
                    <th>RR</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {patientProfile?.physical_exam_on_admissions &&
                    patientProfile?.physical_exam_on_admissions.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{moment(item?.createdAt).format('MMM-DD-YYYY hh:mm A')}</td>
                          <td>{item?.bp}</td>
                          <td>{item?.hr}</td>
                          <td>{item?.rr}</td>
                          <td>
                            <Button
                              className='btn btn-light-blue'
                              onClick={() => {
                                setOpenPhysicalExamination({
                                  isOpen: true,
                                  modalValue: item,
                                });
                              }}
                            >
                              View
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </TableUI>
          </Col>
        </Row>
      </div>

      <div className='patientProfile__header'>
        <h4>Health Care Team</h4>
        <Row>
          <Col>
            <TableUI>
              <Table className='healthCareTeamTable'>
                <thead>
                  <tr>
                    <th>Role</th>
                    <th>Physician&#39;s Name</th>
                    <th>Department</th>
                    <th>Specialty/Subspeciality</th>
                  </tr>
                </thead>
                <tbody>
                  {patientProfile?.inpatient_visits[0]?.health_care_teams.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item.role}</td>
                        <td>{item.physician_name}</td>
                        <td>{item.department}</td>
                        <td>{item.specialty_subspecialty}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </TableUI>
          </Col>
        </Row>
      </div>

      <div className='patientProfile__header'>
        <h4>Payor Information</h4>
        <Row>
          <Col>
            <TableUI>
              <Table className='payorTable'>
                <thead>
                  <tr>
                    <th>Rank</th>
                    <th>Primary Payor</th>
                    <th>Guarantor Type</th>
                    <th>HMO or Guarantor Name</th>
                  </tr>
                </thead>
                <tbody>
                  {newPayorInformation.map((item, index) => {
                    return (
                      <>
                        <tr key={index}>
                          <td>
                            <Button
                              variant='transparent'
                              onClick={() => {
                                alert(index);
                              }}
                            >
                              {item.show && <ArrowUpSVG />}
                              {!item.show && <ArrowDownSVG />}
                              {item.rank}
                            </Button>
                          </td>
                          <td>{index === 0 ? 'YES' : 'NO'}</td>
                          <td>{item.payor_type}</td>
                          <td>{item.HMO_or_guarantor_name}</td>
                        </tr>
                        <tr style={{ display: !item.show && 'none' }}>
                          <td colSpan='4'>
                            <div className='payorData__collapse'>
                              <Row>
                                <Col>
                                  <div className='patientProfile__content'>
                                    <Row>
                                      <Col lg='5'>
                                        <div className='wrapper'>
                                          <span>HMO Account Number</span>
                                          <span>:</span>
                                        </div>
                                      </Col>
                                      <Col lg='auto'>
                                        <span className='value'>{item.HMO_account_number}</span>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col lg='5'>
                                        <div className='wrapper'>
                                          <span>Coverage</span>
                                          <span>:</span>
                                        </div>
                                      </Col>
                                      <Col lg='auto'>
                                        <span className='value'>{item.coverage}</span>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col lg='5'>
                                        <div className='wrapper'>
                                          <span>Coverage Type</span>
                                          <span>:</span>
                                        </div>
                                      </Col>
                                      <Col lg='auto'>
                                        <span className='value'>
                                          {item.is_percentage === 0 ? 'AMOUNT' : 'PERCENTAGE'}
                                        </span>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col>
                                        <div className='fileUpload'>
                                          <span className='fileUpload__title'>File Upload</span>
                                          <span className='fileUpload__fileName'></span>
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                </Col>
                                <Col>
                                  <div className='patientProfile__content'>
                                    <Row>
                                      <Col lg='5'>
                                        <div className='wrapper'>
                                          <span>LOA Number</span>
                                          <span>:</span>
                                        </div>
                                      </Col>
                                      <Col lg='auto'>
                                        <span className='value'>{item.LOA_number}</span>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col lg='5'>
                                        <div className='wrapper'>
                                          <span>LOA Date</span>
                                          <span>:</span>
                                        </div>
                                      </Col>
                                      <Col lg='auto'>
                                        <span className='value'>{item.LOA_date}</span>
                                      </Col>
                                    </Row>
                                  </div>
                                </Col>
                                <Col>
                                  <div className='patientProfile__content'>
                                    <Row>
                                      <Col lg='5'>
                                        <div className='wrapper'>
                                          <span>Approval Code</span>
                                          <span>:</span>
                                        </div>
                                      </Col>
                                      <Col lg='auto'>
                                        <span className='value'>{item.approval_code}</span>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col lg='5'>
                                        <div className='wrapper'>
                                          <span>Approval Date</span>
                                          <span>:</span>
                                        </div>
                                      </Col>
                                      <Col lg='auto'>
                                        <span className='value'>{item.approval_date}</span>
                                      </Col>
                                    </Row>
                                  </div>
                                </Col>
                              </Row>
                            </div>

                            {item.guarantorType === 'SELF PAY' && (
                              <div className='payorData__collapse'>
                                <Row>
                                  <Col>
                                    <div className='patientProfile__content'>
                                      <Row>
                                        <Col lg='4'>
                                          <div className='wrapper'>
                                            <span>HMO Account Number</span>
                                            <span>:</span>
                                          </div>
                                        </Col>
                                        <Col lg='auto'>
                                          <span className='value'>INSERT DATA</span>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col>
                                          <div className='fileUpload'>
                                            <span className='fileUpload__title'>File Upload</span>
                                            <span className='fileUpload__fileName'></span>
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className='patientProfile__content'>
                                      <Row>
                                        <Col lg='4'>
                                          <div className='wrapper'>
                                            <span>RELATIONSHIP</span>
                                            <span>:</span>
                                          </div>
                                        </Col>
                                        <Col lg='auto'>
                                          <span className='value'>INSERT DATA</span>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            )}
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </Table>
            </TableUI>
          </Col>
        </Row>
      </div>

      <div className='patientProfile__header'>
        <h4>Bed Assignment</h4>
        <Row>
          <Col lg='6'>
            <div className='patientProfile__content'>
              <Row>
                <Col lg='5'>
                  <div className='wrapper'>
                    <span>Selected Room/Bed</span>
                    <span>:</span>
                  </div>
                </Col>
                <Col lg='auto'>
                  <span className='value'>{patientProfile?.assigned_room?.room?.room}</span>
                </Col>
              </Row>
              <Row>
                <Col lg='5'>
                  <div className='wrapper'>
                    <span>Floor/Unit</span>
                    <span>:</span>
                  </div>
                </Col>
                <Col lg='auto'>
                  <span className='value'> {patientProfile?.assigned_room?.room?.floor}</span>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>

      <div className='d-flex justify-content-end mt-5'>
        {patientProfile?.patient_status !== 'admitted' && (
          <Button className='me-3' variant='light-blue' onClick={displayModal}>
            ADMIT PATIENT
          </Button>
        )}
      </div>

      {openModal === true && (
        <ConfirmationModal
          setOpenModal={setOpenModal}
          showProfile={showProfile}
          setShowProfile={setShowProfile}
        />
      )}

      <ModalAssessmentEdit
        showModal={showModal}
        setShowModal={setShowModal}
        showProfile={showProfile}
        setShowProfile={setShowProfile}
      />

      <ViewVitalSignModal
        showProfile={showProfile}
        openVitalSignViewModal={openVitalSignViewModal}
        setOpenVitalSignViewModal={setOpenVitalSignViewModal}
      />

      <PhysicalExaminationModal
        showProfile={showProfile}
        openPhysicalExamination={openPhysicalExamination}
        setOpenPhysicalExamination={setOpenPhysicalExamination}
      />
    </div>
  );
};

export default PatientChart;
