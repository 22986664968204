/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';

import Filter from './Filter';
import WorklistTable from './Table';
import OrderPatientProfile from './OrderPatientProfile';
import PatientAddOrder from './PatientAddOrder';

import './index.scss';

const OrderWorklist = () => {
  const [state, setState] = useState([]);
  const [showProfile, setShowProfile] = useState({
    showWorkList: true,
    showAddOrder: false,
    showProfile: false,
    profileData: null,
    profileId: null,
  });

  return (
    <div className='order-work-list'>
      {showProfile.showWorkList && (
        <>
          <Filter state={state} setState={setState} />

          <div className='work-list-tabs mt-2'>
            <Tabs defaultActiveKey='New Patient'>
              <Tab eventKey='New Patient' title='New Patient'>
                <WorklistTable state={state} setState={setState} setShowProfile={setShowProfile} />
              </Tab>
              <Tab eventKey='Patient with Orders' title='Patient with Orders'>
                Patient with Orders
              </Tab>
            </Tabs>
          </div>
        </>
      )}

      {showProfile.showProfile && (
        <OrderPatientProfile showProfile={showProfile} setShowProfile={setShowProfile} />
      )}

      {showProfile.showAddOrder && (
        <PatientAddOrder showProfile={showProfile} setShowProfile={setShowProfile} />
      )}
    </div>
  );
};

export default OrderWorklist;
