/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { Row, Col, Button, Modal, Form, FormControl, InputGroup } from 'react-bootstrap';
// import { useDispatch } from 'react-redux';

// import assets below
import './style.scss';
import FormUI from 'components/UI/FormUI';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';
import { ReactComponent as MagnifyingGlassSVG } from 'assets/svg/magnifying_glass.svg';
// import { addOrder } from 'reducers/nurseReducer'; 

// main component
const ModalAddRecord = ({ showModal, setShowModal }) => {
  // const dispatch = useDispatch();
  const [userData, setUserData] = useState({});

  const handleChange = (key) => (e) => {
    if (e.target.type === 'radio') {
      setUserData({
        ...userData,
        [key]: e.target.value,
      });

      return;
    }

    setUserData({
      ...userData,
      [key]: e.target.value,
    });
  };

  // Submit data here
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(userData);
    // dispatch(addOrder(userData));
  };

  return (
    <Modal
      size='lg'
      show={showModal.addModal}
      onHide={() =>
        setShowModal((prevState) => {
          return {
            ...prevState,
            addModal: false,
          };
        })
      }
      aria-labelledby='Add Allergies'
      className='allergies-modal'
    >
      <Modal.Body>
        <div className='allergies-request'>
          <div className='d-flex header'>
            <p>ALLERGIES</p>

            <CircleXMark
              style={{ width: '2em', cursor: 'pointer' }}
              onClick={() =>
                setShowModal((prevState) => {
                  return { ...prevState, addModal: false };
                })
              }
            />
          </div>
        </div>

        <FormUI>
          <Form className='procedure-form'>
            <Row>
              <Col>
                <Form.Group className='mb-4'>
                  <Form.Label>Donation Date</Form.Label>
                  <FormControl
                    type='date'
                    className='bg-white'
                    placeholder='Enter Here'
                    value={userData.donation_date}
                    onChange={handleChange('donation_date')}
                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className='mb-4'>
                  <Form.Label>Patient Name</Form.Label>
                  <FormControl
                    type='text'
                    className='bg-white'
                    placeholder='Enter Here'
                    value={userData.patient_name}
                    onChange={handleChange('patient_name')}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className='mb-4'>
                  <Form.Label>Conditions</Form.Label>
                  <FormControl
                    type='text'
                    className='bg-white'
                    placeholder='Enter Here'
                    value={userData.conditions}
                    onChange={handleChange('conditions')}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Row>
                  <Col>
                    <Form.Group className='mb-4'>
                      <Form.Label>Status</Form.Label>
                      <FormControl
                        type='text'
                        rows={5}
                        className='bg-white'
                        placeholder='Enter Here'
                        value={userData.status}
                        onChange={handleChange('status')}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className='mb-4'>
                      <Form.Label>Medication</Form.Label>
                      <FormControl
                        type='text'
                        rows={5}
                        className='bg-white'
                        placeholder='Enter Here'
                        value={userData.medication}
                        onChange={handleChange('medication')}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col sm={6}>
                    <Form.Group className='mb-4'>
                      <Form.Label>Follow Up</Form.Label>
                      <FormControl
                        type='date'
                        className='bg-white'
                        placeholder='Enter Here'
                        value={userData.follow_up}
                        onChange={handleChange('follow_up')}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Form.Group className='mb-4'>
                  <Form.Label>Diagnosis</Form.Label>
                  <FormControl
                    as={'textarea'}
                    rows={5}
                    className='bg-white'
                    placeholder='Enter Here'
                    value={userData.diagnosis}
                    onChange={handleChange('diagnosis')}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className='mt-4 buttons-cont'>
                  <Button
                    className='add-button'
                    variant='primary'
                    type='submit'
                    onClick={(e) => {
                      handleSubmit(e);
                      setShowModal((prevState) => {
                        return {
                          ...prevState,
                          addModal: false,
                          showView: true,
                        };
                      });
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    className='cancel-button'
                    vvariant='secondary'
                    onClick={() =>
                      setShowModal((prevState) => {
                        return {
                          ...prevState,
                          addModal: false,
                        };
                      })
                    }
                  >
                    Cancel
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </FormUI>
      </Modal.Body>
    </Modal>
  );
};

ModalAddRecord.defaultProps = {
  showModal: {},
  setShowModal: () => {},
};

ModalAddRecord.propTypes = {
  showModal: PropTypes.object,
  setShowModal: PropTypes.func,
};

export default ModalAddRecord;
