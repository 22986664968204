/* eslint-disable */
/* eslint-disable camelcase */

// import core & vendor packages below
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Alert,
  Table,
  Button,
  Accordion,
  ListGroup,
  InputGroup,
  FormControl,
} from 'react-bootstrap';

// import components below
import FormModal from './Modal';
import TableUI from 'components/UI/TableUI';

// import assets below
import './Table.scss';
import { getMedicalForms } from 'reducers/thunks/medicalRecordThunks';
import { useDispatch } from 'react-redux';

// main component
const FormTabTable = ({ setViewForm, state, setShowScreen }) => {
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [dateFilter, setDateFilter] = useState('');
  const [activeTab, setActiveTab] = useState(null);

  useEffect(() => {
    dispatch(getMedicalForms());
  }, [dispatch]);

  const getMonthAndYear = (date) => {
    return moment(moment(date, 'YYYY-DD-MM').toDate()).format('YYYY-MM');
  };

  const showCurrentRecords = (records) => {
    const currentMonthAndYear = moment(new Date()).format('YYYY-MM');

    const filteredRecords = _.map(records, (record) => {
      const currentData = _.filter(
        record?.data,
        (recordByDate) => getMonthAndYear(recordByDate?.date) === currentMonthAndYear,
      );
      return { ...record, data: currentData };
    });

    return filteredRecords;
  };

  const filterRecordsByDate = (records) => {
    const recordsBySelectedDate = _.map(records, (record) => {
      const currentData = _.filter(
        record?.data,
        (recordByDate) =>
          getMonthAndYear(recordByDate?.date) === dateFilter && record.id === activeTab,
      );

      return { ...record, data: currentData };
    });

    return recordsBySelectedDate;
  };

  const displayedRecords = !dateFilter ? showCurrentRecords(state) : filterRecordsByDate(state);

  return (
    <div className='form-tab-table'>
      <TableUI>
        <Table responsive>
          <thead>
            <tr>
              <th>Forms Type</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan={2}>
                <Accordion className='form-columns' defaultActiveKey='1'>
                  {displayedRecords.map((data) => {
                    return (
                      <Accordion.Item
                        key={data.id}
                        eventKey={data.id}
                        onClick={() => {
                          setActiveTab(data.id);
                          data.id !== activeTab && setDateFilter('');
                        }}
                      >
                        <Accordion.Header>{data.tabLabel}</Accordion.Header>
                        <Accordion.Body>
                          <Row className='mb-4'>
                            <Col className='record-type-controls'>
                              <Button
                                onClick={() => {
                                  setShow(false);
                                  setShowScreen(1);
                                  setViewForm({ data: {}, type: data.tabLabel });
                                }}
                                className='add_record_btn'
                                variant='outline-success'
                              >
                                {/* <PlusSVG /> */}
                                Add Record
                              </Button>

                              <div className='filters'>
                                <p className='filter-period-label'>
                                  Showing records for{' '}
                                  {!dateFilter
                                    ? moment(new Date()).format('YYYY MMMM')
                                    : moment(moment(dateFilter, 'YYYY-MM').toDate()).format(
                                      'YYYY MMMM',
                                    )}
                                </p>
                                <InputGroup className='mb-3'>
                                  <InputGroup.Text>Date Filter</InputGroup.Text>
                                  <FormControl
                                    type='month'
                                    value={dateFilter}
                                    onChange={(e) => setDateFilter(e.target.value)}
                                  />
                                </InputGroup>
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col>
                              {data?.data.length ? (
                                <ListGroup>
                                  {data?.data?.map((listData) => {
                                    return (
                                      <ListGroup.Item key={listData.id}>
                                        <div className='record-dates'>
                                          <strong>{listData.date}</strong>
                                          <p>{listData.type}</p>
                                        </div>
                                        <Button
                                          onClick={() => {
                                            setShow(true);
                                            setTableData(listData);
                                          }}
                                          variant='outline-primary'
                                          className='view-records'
                                        >
                                          View Records
                                        </Button>
                                      </ListGroup.Item>
                                    );
                                  })}
                                </ListGroup>
                              ) : (
                                <Alert variant='info' className='no-record-found'>
                                  No records found on this period
                                </Alert>
                              )}
                            </Col>
                          </Row>
                        </Accordion.Body>
                      </Accordion.Item>
                    );
                  })}
                </Accordion>
              </td>
            </tr>
          </tbody>
        </Table>
      </TableUI>

      <FormModal
        show={show}
        setShow={setShow}
        tableData={tableData}
        setViewForm={setViewForm}
        setShowScreen={setShowScreen}
        setTableData={setTableData}
      />
    </div>
  );
};

FormTabTable.defaultProps = {
  state: [],
  setState: () => { },
  setViewForm: () => { },
  setShowScreen: () => { },
};

FormTabTable.propTypes = {
  state: PropTypes.array,
  setState: PropTypes.func,
  setViewForm: PropTypes.func,
  setShowScreen: PropTypes.func,
};

export default FormTabTable;
