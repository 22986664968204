import React, { useState, useEffect } from 'react';
import './billingHistory.scss';

const PrintContent = React.forwardRef(({ data }, ref) => {
    const [printData, setPrintData] = useState();

    useEffect(() => {
        if (data) {
          setPrintData(data);
        }
      }, [data]);

      return (
        <div className='gridContainer' ref={ref}>
            <div>Patient ID:</div>
            <div>{printData?.patientId}</div>
            <div>Billing Number:</div>
            <div>{printData?.billing_number}</div>
            <div>Status:</div>
            <div>{printData?.status}</div>
            <div>Amount:</div>
            <div>{printData?.amount}</div>
            <div>Date and Time:</div>
            <div>{printData?.date_time}</div>
        </div>
      );
});

PrintContent.displayName = 'PrintContent';

export default PrintContent;